import { React, useState, useEffect } from "react";
// import Dropdown from "./Dropdown";
import {MdEdit} from 'react-icons/md'
import { useParams } from "react-router-dom";
import { useTranslation } from "../contexts/TranslationContext";



export default function ViewProcedure(props) {
  const procedure = props.procedure;
  const [disabled, setDisabled] = useState(true);
  const [editHover, setEditHover] = useState(false);
  const { translate } = useTranslation() 
  const treatment_plan_id = procedure.id
  const {id} = useParams()

  //const [message,setMessage] = useState(null)

  const {openProcedureForm, setOpenProcedureForm, setstate,openNotes, setOpenNotes, consent, setConsent,  getTreatmentPlans} = props
  // const [button, setButton] = useState(null)

  useEffect(() => {
    if (!props.procedure.id) {
      setDisabled(false);
    }
  }, [props]);

  const updateStatus = (value) => {
    // setReady(false)
    setstate()
    let body = {
      status: value
    }
    fetch(`${process.env.REACT_APP_API}/patient/${id}/treatment_plan/${treatment_plan_id}/change-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setstate()
          console.log(procedure.status)
          getTreatmentPlans()
        } else {
          //setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        //setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        // setReady(true);
      });
  }

  return (
    <div className="mt-4 flex items-center">
      <div className="grid grid-cols-12 text-center text-text-3 font-font-2 font-normal">
        <div className="my-auto flex justify-end">
          <div className="flex flex-col leading-none text-text-1">
            <button
              className="mt-1 -m-1 focus:outline-none"
              onClick={() => {
                props.changePriority("procedures", props.index, -1);
              }}
            >
              ⬆
            </button>
            <button
              className="focus:outline-none"
              onClick={() => {
                props.changePriority("procedures", props.index, 1);
              }}
            >
              ⬇
            </button>
          </div>
          <div className="mx-2 flex w-8 bg-background-10 text-white font-bold bg-background-15">
            <p className="m-auto">{props.index + 1}</p>
          </div>
        </div>

        <select
          name="procedures"
          id="procedures"
          style={{
            border: 0,
            WebkitAppearance: "none",
            appearance: "none",
            outline:'none',
          }}
          disabled={disabled}
          value={procedure.Tid}
          className="px-2 py-1 bg-background-6 font-bold"
          onChange={(event) => {
            var fee = 0;
            let name = ''
            let brand = ''
            let bloodV = ''
            let plasmaV = ''
            let plateletC = ''
            let injectionDepth = ''
            let time = ''
            let type = ''
            for(var i = 0; i < props.templateProcedures.length; i++){
              if(props.templateProcedures[i].id === parseInt(event.target.value)){
                fee = props.templateProcedures[i].fee;
                name = props.templateProcedures[i].name
                brand = props.templateProcedures[i].brand
                bloodV = props.templateProcedures[i].bloodV
                plasmaV = props.templateProcedures[i].plasmaV
                plateletC = props.templateProcedures[i].plateletC
                injectionDepth = props.templateProcedures[i].injectionDepth
                time = props.templateProcedures[i].time
                type = props.templateProcedures[i].type
                break;
              }
            }
            props.setProcedure({
              ...procedure,
              Tid: event.target.value,
              name,
              brand,
              fee,
              bloodV,
              plasmaV,
              plateletC,
              injectionDepth,
              time,
              type
            });
          }}
        >
          <option value=""></option>
          {props.templateProcedures &&
            props.templateProcedures.map((proc, i) => {
              return (
                <option
                  key={i}
                  value={proc.id}
                >{`${proc.type} : ${proc.name}`}</option>
              );
            })}
        </select>


        {/* <Dropdown
          addValue={(item) => {
            props.setProcedure({ ...procedure, brand: item });
          }}
          value={procedure.brand}
          option1="Eclipse"
          option2="selyphyl"
          disabled={disabled}
        /> */}
        {/* <Dropdown
          addValue={(item) => {
            props.setProcedure({ ...procedure, tx: item });
          }}
          option1="1"
          option2="2"
          option3="3"
          value={props.procedure.tx}
          disabled={disabled}
        /> */}

        {/* <Dropdown
          addValue={(item) => {
            props.setProcedure({ ...procedure, interval: item });
          }}
          value={(props.procedure).interval}
          disabled={disabled}
          onChange
          option1="1 W"
          option2="2 W"
        /> */}
        {/* <input
          type="text"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={procedure.area}
          disabled={disabled}
          onChange={(event) => {
            // console.log(event.target.value);
            props.setProcedure({ ...procedure, area: event.target.value });
          }}
        /> */}
        <input
        style={{color:'rgb(190, 196, 202)'}}
        placeholder='Brand'
        value={procedure.brand}
        onChange={e => {
          props.setProcedure({ ...procedure, brand: e.target.value });
        }}
         className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none" />
       


        <button onClick={()=>{
          if(openNotes.open){
            setOpenNotes({open:false, id:null})
          }
          if(consent.open) {
            setConsent({open:false, id:null})
          }
          if(openProcedureForm.open){
            if(procedure.id!==openProcedureForm.id){
            setOpenProcedureForm({open:true, id:procedure.id})}
            else{
            setOpenProcedureForm({open:false, id:null})}
          }else{
            setOpenProcedureForm({open:true, id:procedure.id})
          }
          // setButton('Protocol')
        }}
        style={openProcedureForm.id===procedure.id ? {color:'#13C4B3'} : {color:'rgb(190, 196, 202)'}}
         className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none">
          {translate('protocol')}
        </button>



        <button onClick={()=>{
          if(openProcedureForm.open){
            setOpenProcedureForm({open:false, id:null})
          }
          if(consent.open){
            setConsent({open:false, id:null})
          }
          if(openNotes.open){
            if(procedure.id!==openNotes.id)
            setOpenNotes({open:true, id:procedure.id})
            else
            setOpenNotes({open:false, id:null})
          }else{
            setOpenNotes({open:true, id:procedure.id})
          }
          // setButton('open notes')
        }} 
        style={openNotes.id===procedure.id ? {color:'#13C4B3'} : {color:'rgb(190, 196, 202)'}}
        className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
        >
          {translate('notes')}
        </button>



        <input
          type="number"
          className="px-2 py-1 w-full border-r border-background-6 bg-background-7 focus:outline-none"
          value={procedure.fee}
          disabled={disabled}
          placeholder="Fee"
          onChange={(event) => {
            props.setProcedure({ ...procedure, fee: event.target.value });
          }}
        />

        
        {(!props?.procedure?.has_completed && (
          <>
            {disabled ? (
              <div
                className="bg-background-7 flex cursor-pointer"
                onMouseOver={() => {
                  setEditHover(true);
                }}
                onMouseOut={() => {
                  setEditHover(false);
                }}
              >
                <svg
                  className="m-auto h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill={editHover ? "#13c3b2" : "#bec4ca"}
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                  />
                </svg>
                <div
                  className={`absolute mt-8 flex flex-col text-sm bg-background-10 text-black ${
                    !editHover && "hidden"
                  }`}
                >
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      setDisabled(false);
                    }}
                  >
                    {translate('edit')}
                  </button>
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      props.duplicateTreatment(
                        props.procedure.id,
                        "procedures"
                      );
                    }}
                  >
                    {translate('duplicate')}
                  </button>
                  <button
                    className="p-1 focus:outline-none"
                    onClick={() => {
                      props.deleteTreatment(props.procedure.id, "procedures", props.index);
                    }}
                  >
                    {translate('delete')}
                  </button>
                </div>
              </div>
            ) : (
              <div className="bg-background-7 flex">
                <button
                  className="m-auto focus:outline-none"
                  onClick={() => {
                    // props.saveTreatment(procedure);
                    setDisabled(true);
                  }}
                >
                  {/* <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#13c3b2"
                      d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
                    />
                  </svg> */}
                  <MdEdit size={20} color="rgb(190, 196, 202)"/>
                </button>
              </div>
            )}
          </>
        )) || <div className="bg-background-7 flex"></div>}


        <div className="bg-background-7 flex">
          <button
            className="mx-auto focus:outline-none"
            // onClick={() => {
            //   if (!procedure.id)
            //    return alert("Please save treatment first");
            //   props.setModalConsent({tid: procedure.id, ...procedure.consent});
            //   props.setShowConsent(true)
            // }}
            
            onClick={()=>{
              if(openProcedureForm.open){
                setOpenProcedureForm({open:false, id:null})
              }
              if(openNotes.open){
                setOpenNotes({open:false, id:null})
              }
              if(consent.open){
                if(consent.id!==procedure.id)
                setConsent({open:true, id:procedure.id})
                else
                setConsent({open:false, id:null})
              }else{
                setConsent({open:true, id:procedure.id})
              }
            }}
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill={procedure.consent ? "#13c3b2" : "#bec4ca"}
                d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"
              />
            </svg>
          </button>
        </div>


        <div className="bg-background-7 flex">
          <button className="mx-auto focus:outline-none">
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill={props.procedure.paymentCompleted ? "#13c3b2" : "#bec4ca"}
                d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
              />
            </svg>
          </button>
        </div>


        <div className="bg-background-7 flex">
          <button className="mx-auto focus:outline-none">
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill={props.procedure.schedules ? "#13c3b2" : "#bec4ca"}
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
              />
            </svg>
          </button>
        </div>

        <div className="bg-background-7 flex">
          <select
            style={{
              border: 0,
              WebkitAppearance: "none",
              appearance: "none",
              outline:'none',
            }}
            className="px-2 py-1 bg-background-7 font-bold"
            value={procedure && procedure.status}
            // onChange={(event) => {
            //   var fee = 0;
            //   for(var i = 0; i < props.templateProcedures.length; i++){
            //     if(props.templateProcedures[i].id === parseInt(event.target.value)){
            //       fee = props.templateProcedures[i].fee;
            //       break;
            //     }
            //   }
            //   props.setProcedure({
            //     ...procedure,
            //     Tid: event.target.value,
            //     fee: fee,
            //   });
            // }}
            onChange={(e)=>updateStatus(e.target.value)}
          >
            <option disabled value="">{translate('status')}</option>
            <option value="Pending">{translate('pending')}</option>
            <option value="Scheduled">{translate('scheduled')}</option>
            <option value="Completed">{translate('completed')}</option>
          </select>
        </div>
      </div>
    </div>
  );
}
