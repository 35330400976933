import React, { createContext, useContext, useEffect, useState } from 'react';
import { setLCLanguage } from '../utlis/translationUtils';
import translations from '../data/translations.json';

const TranslationContext = createContext();

export function TranslationProvider({ children }) {
  const [language, setLanguage] = useState(() => {
    // Load language from localStorage, defaulting to 'en' if not set
    return localStorage.getItem('language') || 'en';
  });

  useEffect(() => {
    // Save selected language to localStorage
    localStorage.setItem('language', language);
    setLCLanguage(language);
  }, [language]);
  const translate = (keyword) => {
    if (translations[keyword] && translations[keyword][language]) {
      return translations[keyword]?.[language];
    } else {
      if(!translations[keyword]) {
        return keyword;
      }
      return translations[keyword]['en']; // Fallback to English
    }
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <TranslationContext.Provider value={{ translate, changeLanguage, language }}>
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  return useContext(TranslationContext);
}
