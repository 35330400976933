import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Loading from './Loading'
import Logo from "../assets/images/GROTrackTurquoise.png";
import { useUserContext } from '../contexts/userContext';
import { getLogo } from '../utlis/themeHelpers';
import { useTranslation } from "../contexts/TranslationContext";


const Welcome = () => {
    const [ready, setReady] = useState(false);
    const [username, setUsername] = useState(null);
    const [message, setMessage] = useState("");
    const { translate } = useTranslation();
const {isSalonApp} = useUserContext();

    useEffect(() => {
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/admin/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.success) {
              setUsername(response.adminProfile.clinic.name)
            } else {
              setMessage(response.message);
            }
            setReady(true);
          })
          .catch((error) => {
            console.error(error);
            setMessage(translate('error_occurred'));
            setReady(true);
          });
      }, []);

    return (
        <>
            <main
                className={`container mx-auto flex flex-col py-5 px-10 text-text-2 ${
                ready ? "" : "hidden"
                }`}
            >
                <nav className="relative flex items-center pb-10 pt-5 border-b-2 border-gray-700">
                    <NavLink to="/" className="absolute left-0">
                        <img className="h-12" src={getLogo(isSalonApp)} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto uppercase mt-4 text-4xl text-text-3 font-font-2 font-bold">
                        {username && (username) }
                    </h1>
                </nav>
                
                <div className='flex flex-col items-center justify-center pt-10' style={{ minHeight: 'calc(100vh - 138px)' }}>
                    <h1 className='text-3xl text-gray-300 my-12'>{translate('welcome_to_grotrack')}</h1>
                    <Link to={`admin/training`} className='text-3xl text-gray-300 my-12 pt-6'>{translate('start_grotrack_online_training_course')}</Link>
                </div>
            </main>
            <Loading message={translate('loading')} ready={ready} />
        </>
    )
}

export default Welcome