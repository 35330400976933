import { useEffect, useMemo, useRef, useState } from "react";
import { AVATAR_IMAGE_URL } from "../constants";
import aiAgentIdle from "../../../assets/video/ai_agent_idle.mp4";

const ONE_WORD_READ_TIME = 60 / 170;

const HIGHLIGHT_WORDS = 2;

const SubtitleMessage = ({ sentences, activeIndex }) => {
  const newSentences = useMemo(() => {
    return sentences.map((s, i) => {
      const isActive = activeIndex === i;
      const isActivePrev = activeIndex === i - 1;
      const isActiveNext = activeIndex === i + 1;
      let message = s.text;
      if (isActive) {
        message =
          (sentences[activeIndex - 1]?.text ?? "") +
          s.text +
          (sentences[activeIndex + 1]?.text ?? "");
      }
      if (isActivePrev) {
        message = "";
      }
      if (isActiveNext) {
        message = "";
      }
      return message;
    });
  }, [sentences, activeIndex]);

  return (
    <div
      className="p-2 absolute rounded-tl-lg"
      style={{
        overflowY: "auto",
        height: "100px",
        boxSizing: "content-box",
      }}
    >
      {newSentences.map((s, i) => {
        const isActive = activeIndex === i;

        return (
          <span
            key={i}
            className={`text-xs text-accent-8 ${
              isActive ? "font-bold text-accent-8" : "font-normal text-accent-3"
            }`}
            ref={(r) => {
              if (r && isActive) {
                r.scrollIntoView({ behavior: "smooth", block: "start" });
              }
            }}
          >
            {s}
          </span>
        );
      })}
    </div>
  );
};
export const ExpandedVideo = ({ stream, message, onFinish }) => {
  const videoRef = useRef(null);
  //   const [absoluteTime, setAbsoluteTime] = useState(0);
  const absoluteTime = useRef(null);
  const [videoTime, setVideoTime] = useState(0);
  const text = message?.isFinal ? message.text : "";

  const sentences = useMemo(() => {
    if (text) {
      let startTime = 0;
      const words = text.split(/\s+/);
      const sentences = [];
      const breakWords = HIGHLIGHT_WORDS;
      words.forEach((word, i) => {
        if (i % breakWords === 0) {
          sentences.push(
            words
              .slice(i, i + breakWords)
              .filter((w) => w)
              .join(" ")
          );
        }
      });
      return sentences.map((l, i) => {
        const words = l.split(" ");
        const time = words.length * ONE_WORD_READ_TIME;
        let currentStartTime = startTime;
        startTime = startTime + time;
        return {
          text: l + " ",
          startTime: currentStartTime,
          endTime: startTime,
        };
      });
    }
    return [];
  }, [text]);

  const currentSentenceIndex = useMemo(() => {
    if (!message?.isFinal) return 0;
    if (absoluteTime.current === null) return 0;
    const currentTime = videoTime - absoluteTime.current;
    const sentenceIndex = sentences.findIndex((s) => {
      return currentTime >= s.startTime && currentTime <= s.endTime;
    });

    return sentenceIndex === -1 ? sentences.length - 1 : sentenceIndex;
  }, [videoTime, sentences, message]);

  useEffect(() => {
    const handleVideoStream = () => {
      if (stream) {
        videoRef.current.src = "";
        videoRef.current.srcObject = stream;
        absoluteTime.current = null;
      } else {
        if (videoRef.current.srcObject) {
          videoRef.current.srcObject = null;
          videoRef.current.src = aiAgentIdle;
          onFinish();
        }
      }
    };

    if (videoRef.current) {
      handleVideoStream();
    }
  }, [stream]);

  return (
    <div
      className=" rounded w-full relative"
      style={{
        height: "100%",
      }}
    >
      <video
        ref={videoRef}
        playsInline
        src={aiAgentIdle}
        loop={false}
        autoPlay
        onTimeUpdate={(e) => {
          if(!stream) return;
          const time = e.currentTarget.currentTime;
          absoluteTime.current = absoluteTime.current || time;

          setVideoTime(time);
        }}
        className="mb-2"
        style={{
          height: "100%",
          objectFit: "cover",
        }}
      />

      {message?.isFinal ? (
        <SubtitleMessage
          sentences={sentences}
          activeIndex={currentSentenceIndex}
        />
      ) : (
        <div className="absolute bottom-2 text-center w-full right-0 p-2  rounded-tl-lg">
          <p className="text-xs text-accent-8">{message?.text??""} </p>
        </div>
      )}
      {/*  */}
    </div>
  );
};
