import React from "react";

const OverviewProgress = ({
  title = "",
  subTitle = "",
  progress = 0.0, // valid range: [0.0, 1.0]
  points = [0, 1, 2, 3, 4, 5],
}) => {
  const level = Math.floor(progress * 5);

  return (
    <div
      className={styles["container"]}
      style={{
        border: "1px solid #F6F7FA26",
        borderRadius: "21px",
        width: "315px",
        height: "143px",
      }}
    >
      <div className={styles["title"]}>{title}</div>
      <div className={styles["sub-title"]}>{subTitle}</div>
      <div className={styles["progress"]}>
        <div className={styles["progress-track"]}>
          <div
            className={styles["progress-done"]}
            style={{ width: `${level * 20}%` }}
          />
          {points.map((val, index) => (
            <div
              className={`${
                index > 0 &&
                index < points.length - 1 &&
                styles["progress-bulb"]
              } ${val < level ? "bg-background-23" : "bg-button-4"}`}
              style={{ left: `${val * 20}%`, top: "50%" }}
              key={val}
            />
          ))}
          <div
            className={styles["progress-level"]}
            style={{ left: `${level * 20}%`, top: "50%" }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container:
    "rounded-3xl w-3/4 border border-white border-opacity-25 py-4 px-8 flex flex-col justify-evenly",
  title: "text-center mb-2 text-text-7",
  "sub-title": "text-center font-thin mb-2 text-xs text-text-8",
  progress: "h-6 flex items-center relative",
  "progress-track":
    "relative w-full h-1 rounded-sm bg-gradient-to-r bg-button-4",
  "progress-done": "absolute w-full h-full rounded-full bg-background-23",
  "progress-bulb":
    "absolute h-3 w-3 rounded-full transform -translate-x-1/2 -translate-y-1/2",
  "progress-level":
    "absolute h-5 w-5 rounded-full transform -translate-x-1/2 -translate-y-1/2 border-4 border-accent-1 bg-white",
};

export default OverviewProgress;
