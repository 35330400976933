import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import Loading from "../../views/Loading";
import DotGrey from "../../assets/images/DotGrey.svg";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import SettingsIcon from "../../assets/images/GearIcon.png";
import { AiOutlinePlus } from "react-icons/ai";
import { useUserContext } from "../../contexts/userContext";
import { getDot, getLogo } from "../../utlis/themeHelpers";
import AdminHeader from "../AdminHeader";
import { useCartContext } from "../../views/AdminMarkerPlaceWrapper";
import CategoryBar from "./CategoryBar";
import Products from "./Products";
import { MarketPlaceDetails } from "./MarketPlaceDetails";

export const AdminMyGroSolutions = () => {
  const { isSalonApp } = useUserContext();
  const { cartItems } = useCartContext();


  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);
  const [showSection, setShowSection] = useState(isSalonApp ? "PRODUCTS" : "TREATMENTS");
  const [productsList, setProductsList] = useState([]);
  const [productStartIndex, setProductStartIndex] = useState(0);
  const [productEndIndex, setProductEndIndex] = useState(2);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setProductsList(cartItems?.filter(item => item?.category === showSection));
  }, [cartItems, showSection])

  const handleSlide = (rightSlide) => {
    if (rightSlide) {
      if (productEndIndex < productsList.length - 1) {
        setProductEndIndex(productEndIndex + 3);
        setProductStartIndex(productStartIndex + 3);
      }
    } else {
      if (productStartIndex > 0) {
        setProductEndIndex(productEndIndex - 3);
        setProductStartIndex(productStartIndex - 3);
      }
    }
  };

  const handleChangeSection = (value) => {
    setShowSection(value)
  }

  const resetIndex = () => {
    setProductEndIndex(2);
    setProductStartIndex(0);
  };

  const showDetails = (item) => {
    setSelectedItem(item);
  };



  return (
    !selectedItem ? (
      <>
        <CategoryBar showSection={showSection} handleChangeSection={handleChangeSection} resetIndex={resetIndex} />
        <Products handleSlide={handleSlide} showBuyButton={true} showSection={showSection} productsList={productsList} showDetails={showDetails} productStartIndex={productStartIndex} productEndIndex={productEndIndex} />
      </>
    ) : (
      <MarketPlaceDetails
        item={selectedItem}
        setSelectedItem={setSelectedItem}
        parent={showSection}
      />
    )
  );
};
