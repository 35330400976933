import { React, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Subscription from '../pages/subscription/Subscription';
import Loading from '../views/Loading';
import { useUserContext } from '../contexts/userContext';
import { getLogo } from '../utlis/themeHelpers';
import PaymentInit from '../components/SignupComponents/PaymentInit';
import PaymentSuccess from '../components/SignupComponents/PaymentSuccess';
import { useLocation, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import AdminHeader from '../components/AdminHeader';
import useQueryParam from '../hooks/useQueryParam';
import Register from '../components/SignupComponents/Register';
import StripeCheckout from '../components/SignupComponents/StripeCheckout';
import PasswordSetup from '../components/SignupComponents/PasswordSetup';
import { useTranslation } from "../contexts/TranslationContext";

export default function Signup(props) {
  const { translate } = useTranslation();

  // hooks and states -------------------------------------------
  
  const history = useHistory();
  const { email_url, type, status } = useParams();
  const { isSalonApp, fetchUserData } = useUserContext();
  const pageType = useQueryParam("onboarding_step");

  const [clinicName, setClinicName] = useState('');
  const [email, setEmail] = useState(email_url ? email_url : "");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(true);
  const [error, setError] = useState([]);
  const [activeScreen, setActiveScreen] = useState('signup');
  const onboardingStepsCompleted = useRef(1)

  const isOnboardingFlowOne = useRouteMatch("/onboarding/:email_url?/:type?/:status?");
  const onboardingBaseUrl = isOnboardingFlowOne ? "/onboarding" : "/onboarding-2"
  


  // Request handlers --------------------------------------------


  const signUp = () => {
    // if (isSignUpValid()) {
    setMessage("");
    setError([]);
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/clinic/onboarding`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: clinicName.trim(),
        email: email.trim().toLowerCase(),
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          localStorage.setItem('email', response.data.email);
          setEmail(response.data.email);
          localStorage.setItem('name', response.data.name);
          localStorage.setItem('temp_user_id', response.data.id);
          checkUserPaymentHistory()
          // setActiveScreen("payment_init");
          // setReady(true);
          // initCheckout('setup');
        } else {
          setMessage(response.message);
          setError(response.error);
          setReady(true);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
    // }
  };

  const initCheckout = async (type_temp) => {
    // if (isSignUpValid()) {
    setMessage("");
    setError([]);
    setReady(false);
    if(!type_temp){
      return alert("Please choose subscription type!")
    }
    await fetch(`${process.env.REACT_APP_API}/clinic/onboarding/init_checkout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: type_temp,
        email: email.trim().toLowerCase(),
        setup_plus_service: isOnboardingFlowOne ? true : false
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          window.location.href = response.data.url;
        } else {
          if (response.payment_status) {
            if (type_temp === 'setup') {
              onboardingStepsCompleted.current = 3;
              history.push(`${onboardingBaseUrl}?onboarding_step=subscription`)
              // setActiveScreen("subscription");
              // setActiveScreen("payment_success");
              setReady(true);
            }
            else if (type_temp === "monthly" || type_temp === "annually") {
              onboardingStepsCompleted.current = 4;
              history.push(`${onboardingBaseUrl}?onboarding_step=payment_success`)

              // setActiveScreen("payment_success");
              setReady(true);
            }
          } else {
            onboardingStepsCompleted.current = 3;
            history.push(`${onboardingBaseUrl}?onboarding_step=stripeCheckout`)

            // setActiveScreen('stripeCheckout');
            setMessage(response.message);
            setError(response.error);
            setReady(true);
          }
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
    // }
  };

  const checkPayment = () => {
    setMessage("");
    setError([]);
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/clinic/onboarding/payment_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: type,
        email: email.trim().toLowerCase(),
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          if (response.payment_status) {
            if (type === "setup") {
            onboardingStepsCompleted.current = 3;
            history.push(`${onboardingBaseUrl}?onboarding_step=subscription`)
            }
            else {
            onboardingStepsCompleted.current = 4;
            history.push(`${onboardingBaseUrl}?onboarding_step=payment_success`)
            }
          } else {
            onboardingStepsCompleted.current = 3;
            history.push(`${onboardingBaseUrl}?onboarding_step=stripeCheckout`)
            setMessage("Your payment is not successful. Please try again later.");
          }
        } else {
          onboardingStepsCompleted.current = 3;
          history.push(`${onboardingBaseUrl}?onboarding_step=stripeCheckout`)
          setMessage(response.message);
          setError(response.error);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }

  const isSignUpValid = () => {
    if (password === confirmPassword) {
      return true;
    }
    setError({ confirmPassword: "Password does not match confirm password" });
    return false;
  };

  const setupPassword = () => {
    if (isSignUpValid()) {
      setMessage("");
      setError([]);
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/clinic/onboarding/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: password,
          email: email.trim().toLowerCase(),
          // password: password,
        }),
      })
        .then(response => response.json())
        .then(response => {
          console.log(response, "response")
          if (response.success) {
            localStorage.setItem('access_token', response.access_token);
            localStorage.setItem('expires_at', (response.expires_at * 1000));
            fetchUserData()
            setTimeout(() => {
              props.setLoggedIn(true);
            }, 500)
            if(isSalonApp) history.push("/onboarding/md_details");
            else history.push("/onboarding/user-agreement");
          } else {
            setMessage(response.message);
            setError(response.error);
            setReady(true);
          }
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  const checkPaymentStatus = async (type_temp) => {
    const response = await fetch(`${process.env.REACT_APP_API}/clinic/onboarding/payment_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: type_temp,
        email: email.trim().toLowerCase(),
      }),
    });
    const data = await response.json()
    if(data.payment_status) return true;
    return false;
  }

  const checkUserPaymentHistory = async () => {
      // Check if user has completed  subcription payment.
      const isSubscriptionFeePaid = await checkPaymentStatus("subscription");
      console.log()
      if(isSubscriptionFeePaid){
        onboardingStepsCompleted.current = 4;
        history.push(`${onboardingBaseUrl}?onboarding_step=payment_success`)
        setReady(true);
        return;
      }

      // Check if user has completed setup payment.
      if(!isSubscriptionFeePaid){
        const isSetupFeePaid = await checkPaymentStatus("setup");
        if(isSetupFeePaid){
          onboardingStepsCompleted.current = 3;
          history.push(`${onboardingBaseUrl}?onboarding_step=subscription`)
          setReady(true);
          return;
        }
      }

      onboardingStepsCompleted.current = 2;
      history.push(`${onboardingBaseUrl}?onboarding_step=payment_init`)
      setReady(true)
  }


  // Use effects --------------------------------------------


  useEffect(() => {
    if (email_url && type && status) {
      checkPayment();
    } else {
      setMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveScreen(pageType || "signup");
  }, [pageType])

  // JSX ------------------------------------------------------


  const getSecuredMainSection = () => {
      if(activeScreen === "payment_init" && onboardingStepsCompleted.current >= 2){
        return <PaymentInit initCheckout={initCheckout} setError={setError} setMessage={setMessage} />
      }
      if(activeScreen === "stripeCheckout" && onboardingStepsCompleted.current >= 3){
        return <StripeCheckout message={message} setError={setError} setMessage={setMessage} onboardingBaseUrl={onboardingBaseUrl}/>;
      }
      if( activeScreen === "subscription" && onboardingStepsCompleted.current >= 3){
        return <Subscription initCheckout={initCheckout} />
      }
      if(activeScreen === "payment_success" && onboardingStepsCompleted.current >= 4){
        return <PaymentSuccess setActiveScreen={setActiveScreen} history={history} onboardingStepsCompleted={onboardingStepsCompleted} onboardingBaseUrl={onboardingBaseUrl} setError={setError} setMessage={setMessage} message={message} />;
      }
      if( activeScreen === "password_setup" && onboardingStepsCompleted.current >= 5 ){
        return <PasswordSetup setupPassword={setupPassword} message={message} error={error} email={email} setEmail={setEmail} password={password} setPassword={setPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword}/>;
      }
      return <Register message={message} signUp={signUp} clinicName={clinicName} setClinicName={setClinicName} email={email} setEmail={setEmail} error={error}/>
  }

  return (
    <>
      <main className={`flex flex-col p-4 ${ready ? '' : 'hidden'}`}>
        {getSecuredMainSection()}
      </main>
      <Loading
        message={`${isSalonApp ? translate('registering_salon') : translate('registering_clinic')}`}
        ready={ready}
      />
      {activeScreen === "signup" && <footer className="px-4 py-10 border-t-2 border-gray-500 text-text-2 tracking-widest text-xl font-normal mx-20 text-center uppercase">
        {translate('gro_technologies')}
      </footer>}
    </>
  );
}