import React from "react";
import { useUserContext } from "../../contexts/userContext";
import { getLogo } from "../../utlis/themeHelpers";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "../../contexts/TranslationContext";

const PaymentInit = ({ initCheckout, setError, setMessage }) => {
  const { isSalonApp } = useUserContext();
const { translate } = useTranslation();
  const isOnboardingFlowOne = useRouteMatch("/onboarding/:email_url?/:type?/:status?");


  return (
    <div className="flex flex-col mb-20" style={{flexGrow: "1"}}>
      <nav className="flex items-center border-b-2 border-gray-700 py-10 mx-10 px-auto">
        <img
          className="h-20 mx-auto"
          src={getLogo(isSalonApp)}
          alt="GRO Track"
        />
      </nav>

      <div className="text-center mt-14 tracking-wider flex flex-col justify-evenly" style={{flexGrow: "1"}}>
        <PaymentInfo isSalonApp={isSalonApp} isOnboardingFlowOne={isOnboardingFlowOne}/>
        <button
          className="w-max mx-auto bg-button-5 font-bold uppercase tracking-widest text-white hover:underline focus:outline-none focus:underline px-12 py-2 rounded-md"
          onClick={() => {
            if (initCheckout && setError && setMessage) {
              initCheckout("setup");
              setError("");
              setMessage("");
            }
          }}
        >
          {translate('confirm')}
        </button>
      </div>
    </div>
  );
};

const PaymentInfo = ({ isSalonApp, isOnboardingFlowOne }) => {
  const { translate } = useTranslation();

  if (isSalonApp) return (
    <>
      <h1 className="text-3xl text-text-2  mb-6 tracking-wider">GroTrack system for Stylists</h1>
      <h1 className="text-3xl text-text-2 my-6">$1,500</h1>

      <div className="flex justify-center">
        <ul className="text-left text-text-2 list-disc list-inside">
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_camera')}
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_software')}
          </li>
        </ul>
      </div>

      <h1 className="text-3xl text-text-2  mb-6">$99 monthly service fee</h1>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          * A Monthly Service Fee Of $99 Per Month Will Be Charged On Your Payment Method On File.
          <br /> You Can Cancel Grotrack Monthly Services Anytime 30 Days Prior To The Next Billing Cycle
        </p>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          * Monthly Service Fee Include Software Upgrades, Unlimited Image Processing, Unlimited Could Storage, Camera Warranty, Tech Support, Virtual Training.
        </p>
      </div>
    </>
  )

  if (!isOnboardingFlowOne) return (
    <>
<h1 className="text-3xl text-text-2  mb-20 tracking-wider">GroTrack system</h1>
      {/* <h1 className="text-3xl text-text-2 my-6">$4,500</h1> */}

      <div className="flex justify-center">
        <ul className="text-left text-text-2 list-disc list-inside">
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_camera')}
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            Grotrack Software License
          </li>
        </ul>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
        * A Monthly Service Fee Of $199 Per Month. 
        <br />You Can Cancel Grotrack Monthly Services Anytime 
        <br/>30 Days Prior To The Next Billing Cycle
        </p>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          * monthly service fee include, software upgrades, unlimited image
          processing, unlimited could storage, camera warranty, tech support,
          virtual training.
        </p>
      </div>
    </>
  )

  return (
    <>
      <h1 className="text-3xl text-text-2  mb-6 tracking-wider">GroTrack system</h1>
      <h1 className="text-3xl text-text-2 my-6">$4,500</h1>

      <div className="flex justify-center">
        <ul className="text-left text-text-2 list-disc list-inside">
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            {translate('grotrack_camera')}
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            Grotrack Software License
          </li>
          <li className="my-6 flex items-center text-lg">
            <div className="w-3 h-3 bg-white rounded-full shadow mr-2 mr-8"></div>
            12 Months Service Included
          </li>
        </ul>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          * A monthly service fee of $199 per month will be charged after the
          first 12 months. you can cancel grotrack monthly services anytime
          after the initial 12 months, 30 days prior to the next billing cycle
        </p>
      </div>

      <div className="w-7/12 mx-auto">
        <p className="my-12 text-text-2 text-lg capitalize">
          * monthly service fee include, software upgrades, unlimited image
          processing, unlimited could storage, camera warranty, tech support,
          virtual training.
        </p>
      </div>
    </>
  )


}

export default PaymentInit;
