import React, { useEffect, useState } from "react";
import AdminSidebar from "../components/AdminSidebar";
import Pagination from '../components/Pagination';
import moment from "moment";
import Logo from "../assets/images/logo.png";
import Loading from "./Loading";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
// import Pagination from "../components/Pagination";
import { AiOutlineMenu } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { getLogo } from "../utlis/themeHelpers";
import { useUserContext } from "../contexts/userContext";
import { useTranslation } from "../contexts/TranslationContext";

const SupportTicketsList = () => {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState("");
    const [ready, setReady] = useState(false);
    //const [physician, setPhysician] = useState(null);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState(null);
    const [clinic, setClinic] = useState("");
    const [patient, setPatient] = useState("");
    const [date, setDate] = useState("");
    const [pageInput, setPageInput] = useState(1);
    const location = useLocation();
    const {isSalonApp} = useUserContext()

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_API}/admin/profile`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((response) => {
    //             if (response.success) {
    //                 setPhysician(response.adminProfile);
    //             } else {
    //                 setMessage(response.message);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             setMessage("Some Error Occured. Please Try Again Later");
    //         });
    // }, []);

    const getInvalidImages = (page, clinic, patient, date, statusFilter) => {
        setReady(false);

        const searchParams = new URLSearchParams(location.search);
        searchParams.set('clinic', clinic);
        searchParams.set('patient', patient);
        searchParams.set('date', date);
        searchParams.set('status', statusFilter);
        searchParams.set('page', page);

        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        fetch(`${process.env.REACT_APP_API}/tickets?page=${page}${clinic ? `&clinic=${clinic}` : ''}${patient ? `&patient=${patient}` : ''}${date ? `&date=${moment(date).format("MM-DD-YYYY")}` : ''}${statusFilter ? `&status=${statusFilter}` : ''}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setData(response.tickets.data);
                    setTotalPages(response.tickets.last_page);
                    setCurrentPage(response.tickets.current_page);
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage(translate('error_occurred'));
            })
            .finally(() => {
                setReady(true);
            });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getInvalidImages(page, clinic, patient, date, statusFilter);
    };

    const handleStatusFilterChange = (value) => {
        setCurrentPage(1);
        setStatusFilter(value);
        getInvalidImages(1, clinic, patient, date, value);
    };

    // useEffect(() => {
    //     if (statusFilter !== null) {
    //         getInvalidImages(1, clinic, patient, date, statusFilter);
    //     }
    // }, [statusFilter]);
    const { translate } = useTranslation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const cli = searchParams.get('clinic') || '';
        setClinic(cli);
        const pat = searchParams.get('patient') || '';
        setPatient(pat);
        const dat = searchParams.get('date') || '';
        setDate(dat);
        const pag = parseInt(searchParams.get('page')) || 1;
        setCurrentPage();
        const sta = searchParams.get('status') || '';
        setStatusFilter(searchParams.get('status') || '');

        getInvalidImages(pag, cli, pat, dat, sta);
    }, [location]);

    return (
        <>
            <main
                className={`mx-auto container flex flex-col p-4 font-font-2 tracking-wider ${ready ? "" : "hidden"
                    }`}
            >
                <nav className="flex items-center border-b-2 border-background-2 pb-4 mx-10">
                    <NavLink to="/">
                        <img className="h-12" src={getLogo(isSalonApp)} alt="GRO Track" />
                    </NavLink>
                    <h1 className="mx-auto mt-4 text-3xl text-text-2 font-bold" style={{ transform: `translate(-40px)` }}>
                        {translate('support_tickets')}
                    </h1>
                </nav>
                {message && <p className="mt-8 text-center text-red-600">{message}</p>}
                {success && (
                    <p className="mt-8 text-center text-green-600">{success}</p>
                )}
                <section className="flex">
                    <div className="div px-10 w-full my-10">
                        <h2 className="font-font-2 text-2xl mb-4 text-center text-white">Support Tickets List</h2>

                        <form onSubmit={(e) => { e.preventDefault(); getInvalidImages(1, clinic, patient, date, statusFilter) }} >
                            <div className="w-full flex space-x-2 bg-transparent gap-2 my-4 items-end">
                                <div className="w-1/4 items-center">
                                    <label htmlFor="clinicInput" className="text-gray-500 flex-none pr-2">{translate('clinic')}:</label>
                                    <div className="flex-1">
                                        <input type="text" id="clinicInput" className="bg-gray-500 text-white py-1 px-3 border-b border-gray-500 focus:outline-none w-full" value={clinic} onChange={(e) => { setClinic(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="w-1/4 items-center">
                                    <label htmlFor="patientInput" className="text-gray-500 flex-none pr-2">{translate("patient")}:</label>
                                    <div className="flex-1">
                                        <input type="text" id="patientInput" className="bg-gray-500 text-white py-1 px-3 border-b border-gray-500 focus:outline-none w-full" value={patient} onChange={(e) => { setPatient(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="w-1/4 items-center">
                                    <label htmlFor="dateInput" className="text-gray-500 flex-none pr-2">{translate('date')}:</label>
                                    <div className="flex-1">
                                        {/* <input type="text" id="dateInput" className="bg-gray-500 text-white py-1 px-3 border-b border-gray-500 focus:outline-none w-full" value={date} onChange={(e) => {setDate(e.target.value)}} /> */}
                                        <DatePicker
                                            dateFormat="MM/dd/yyyy"
                                            className="bg-gray-500 text-white py-1 px-3 border-b border-gray-500 focus:outline-none w-full"
                                            selected={date}
                                            onChange={(date) => setDate(date)}
                                        />
                                    </div>
                                </div>
                                {/* <div className="w-1/3 flex items-center">
                                <label htmlFor="statusFilterSelect" className="text-gray-500 flex-none">Status:</label>
                                <div className="flex-1">
                                    <select id="statusFilterSelect" className="bg-transparent border-b border-gray-500 focus:outline-none w-full" value={statusFilter} onChange={handleStatusFilterChange}>
                                        <option value="">All</option>
                                        <option value="invalid">Review</option>
                                        <option value="invalidated">Invalidated</option>
                                        <option value="approved">Approved</option>
                                        <option value="pending manual validation">Pending Manual Validation</option>
                                        <option value="manual correction done">Manual Correction Done</option>
                                    </select>
                                </div>
                            </div> */}
                                <div className="w-1/4">
                                    <button type="submit" className="bg-accent-1 hover:bg-accent-700 text-white font-bold border-b border-accent-1 py-1 px-10 rounded focus:outline-none focus:shadow-outline">{translate('search')}</button>
                                </div>
                            </div>
                        </form>

                        <div>
                            <table className="min-w-full">
                                <thead className="">
                                    <tr>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-4">
                                            #
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-24">
                                            {translate('date')}
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300">
                                            {translate('clinic')}
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-64">
                                            {translate('patient')}
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-80">
                                            {translate('image_type')}
                                        </th>
                                        <th scope="col" className="px-6 py-2 text-center border bg-gray-500 text-gray-300 w-32">
                                            <div className="flex items-center justify-center gap-8">{translate('status')} <StatusFilter statusFilter={statusFilter} handleStatusFilterChange={handleStatusFilterChange} /></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((value, index) => {
                                        return (
                                            <tr className="border-b" key={index}>
                                                <td className="px-3 py-2 whitespace-nowrap border text-center text-gray-500">{index + 1}</td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-500 font-normal">
                                                    {dayjs(value.created_at).format("MM-DD-YY")}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-500 font-normal">
                                                    {value.clinic.name}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-500 font-normal">
                                                    {value.patient_name}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-500 font-normal">
                                                    {value.image.mainType}
                                                </td>
                                                <td className="px-3 py-2 whitespace-nowrap border text-gray-500 font-normal">
                                                    <NavLink to={`/admin/support-tickets/${value.id}`} className={`hover:text-white capitalize ${value.status === 'invalid' ? 'text-red-400' : (value.status === 'approved' ? 'text-green-500' : 'text-red-700')}`}>{value.status === 'invalid' ? translate('review') : value.status} </NavLink>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Pagination pageInput={pageInput} currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} setPageInput={setPageInput} />
                    </div>
                </section>
            </main>
            <Loading message={translate('loading')} ready={ready} />
        </>
    );
};

const StatusFilter = ({ statusFilter, handleStatusFilterChange }) => {
    const [show, setShow] = useState(false);
    const { translate } = useTranslation();
    return (
        <div className="relative">
            <AiOutlineMenu className="cursor-pointer text-white" onClick={(e) => { setShow((prev) => { return !prev }) }} />
            {
                show &&
                <div className="flex flex-col gap-3 bg-white w-64 p-4 rounded-lg z-10 absolute top-10 right-0 text-left text-gray-900" onClick={(e) => { e.stopPropagation(); }}>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === '' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange(''); setShow(false); }}>{translate('all')}</p>
                    </div>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === 'open' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange('open'); setShow(false); }}>Open</p>
                    </div>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === 'closed' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange('closed'); setShow(false); }}>Closed</p>
                    </div>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === 'invalid' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange('invalid'); setShow(false); }}>{translate('review')}</p>
                    </div>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === 'approved' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange('approved'); setShow(false); }}>Approved</p>
                    </div>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === 'invalidated' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange('invalidated'); setShow(false); }}>Invalidated</p>
                    </div>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === 'pending manual validation' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange('pending manual validation'); setShow(false); }}>Waiting for annotation</p>
                    </div>
                    <div className="flex gap-2">
                        <p className={`text-sm font-thin cursor-pointer ${statusFilter === 'manual correction done' && 'text-accent-1'}`} onClick={(e) => { handleStatusFilterChange('manual correction done'); setShow(false); }}>Annotation completed</p>
                    </div>
                    {/* <option value="">All</option>
                                    <option value="invalid">Review</option>
                                    <option value="invalidated">Invalidated</option>
                                    <option value="approved">Approved</option>
                                    <option value="pending manual validation">Pending Manual Validation</option>
                                    <option value="manual correction done">Manual Correction Done</option> */}
                </div>
            }
        </div>
    );
}

export default SupportTicketsList;