import React, { useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import CrossGrey from "../../assets/images/CrossGrey.svg";
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import dayjs from 'dayjs';
import ImageLoader from '../ImageLoader';
import { ChangeAnalysisContext } from '../../views/StudyMode';
import { getHairComparisonPercentage } from '../../utlis/calculations';


const ThreexCloseuplmageCompare = ({ allAnalysis }) => {
	const history = useHistory();

    const { patient_id, analysis_id, subType } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	const {changeAnalysis} = useContext(ChangeAnalysisContext);

	return (
		<div>
			<div className='absolute top-5 right-5'>
				<Link to={`/patient/${patient_id}/study/${analysis_id}/${subType}`}>
					<img src={CrossGrey} className='h-5 w-5' />
				</Link>
			</div>
			<div className="mt-16 mb-16 flex justify-center gap-5 text-center font-normal text-white text-base leading-5 tracking-wider capitalize" style={{ fontFamily: 'Roboto', letterSpacing: '0.8px', opacity: '0.8' }}>
				{['front', 'crown', 'vertex', 'occipital', 'left', 'right'].map((type) => {
					return <Link className={type === subType ? 'opacity-90' : 'opacity-40 '} to={`/patient/${patient_id}/study/${analysis_id_int}/compare/${type}`}>{type}</Link>
				})}
			</div>
			<div className='flex items-center justify-center'>
				<button
					className="text-2xl focus:outline-none"
					onClick={function () {
						changeAnalysis(history, 'prev', analysis_id_int, allAnalysis)
					}}
				>
					<img src={lessThanIcon} className='w-10 h-10'/>
				</button>
				<div className='grid justify-center flex-grow grid-cols-3'>
					{allAnalysis.map((analysis, index) => {
						if(index === analysis_id_int - 1 || index === analysis_id_int || index === analysis_id_int + 1){
							return (
								<div
									className={`flex flex-col items-center justify-center px-4 py-4 ${analysis_id_int === 0 && index === 0 ? 'col-start-2' : ''}`}
								>
									<div className="text-center">
										<div className="mb-6 text-center font-bold text-white opacity-70 text-base leading-5 tracking-widest" style={{ fontFamily: 'Roboto', letterSpacing: '0.64px' }}>
											{index === 0 ? 'Baseline: ' : ''}

											{
												dayjs
												.unix(analysis.created_at)
												.format("MM-DD-YY")
											}
										</div>
										{analysis?.images?.['3x_closeup'].raw && <div className='mb-8'><ImageLoader img={analysis?.images?.['3x_closeup']?.raw?.[subType]} secondaryImg={analysis?.images?.['3x_closeup']?.raw?.[subType]?.status !== 'invalidated' ? analysis?.images?.['3x_closeup']?.ml?.[subType] : undefined} onDoubleClick={() => {history.push(`/patient/${patient_id}/study/${analysis_id_int}/compare/${subType}`)}} showBlackTint={true} /></div>}
										<div class="text-center font-normal text-sm leading-5 tracking-wider text-white opacity-70 capitalize " style={{ fontFamily: 'Roboto', letterSpacing: '0.8px'}}>
											HD: {analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all']}/cm2 ({getHairComparisonPercentage(allAnalysis[0]?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all'], analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['all'])}%)
											HT: {analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'] * 1000}µm ({getHairComparisonPercentage(allAnalysis[0]?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'], analysis?.images?.['3x_closeup']?.groMeasure?.[subType]?.['hairDiameter'])}%)
										</div>
									</div>
								</div>
							)
						}
						return <></>;
					})}
				</div>
				<button
					className="text-2xl focus:outline-none"
					onClick={function () {
						changeAnalysis(history, 'next', analysis_id_int, allAnalysis)
					}}
				>
					<img src={greaterThanIcon} className='w-10 h-10'/>
				</button>
			</div>
		</div>
	)
}

export default ThreexCloseuplmageCompare