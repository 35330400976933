import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PatientSidebar from '../components/PatientSidebar';
import { useUserContext } from '../contexts/userContext';
import { useTranslation } from "../contexts/TranslationContext";

export default function TreatmentHealthHistory() {
  const [ready, setReady] = useState(false);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(true);
  const [examDrop, setExamDrop] = useState(false);
  const [healthDropdown, setHealthDropdown] = useState(true);
  const [treatmentInfo, setTreatmentInfo] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editTreatmentInfo, setEditTreatmentInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState('');
  const { id } = useParams();
  const [patientReferrals, setPatientReferrals] = useState(null);
  const {isSalonApp} = useUserContext()
  const { translate } = useTranslation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
          setFirstName(response.firstName);
          setLastName(response.lastName);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const handleClickChange = event => {
    const name = event.target.name;
    if (editTreatmentInfo[name] === 1) {
      setEditTreatmentInfo({ ...editTreatmentInfo, [name]: 0 });
    }
    if (editTreatmentInfo[name] === 0) {
      setEditTreatmentInfo({ ...editTreatmentInfo, [name]: 1 });
    }
  };

  const handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setEditTreatmentInfo({
      ...editTreatmentInfo,
      [name]: value,
    });
  };

  const isTreatmentInfoValid = () => {
    return true;
  };
  const isHealthHistoryOpen = () => {
    return true;
  };

  const saveTreatmentInfo = () => {
    if (isTreatmentInfoValid()) {
      setReady(false);
      fetch(
        `${process.env.REACT_APP_API}/patient/${id}/health_history_treatment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          body: JSON.stringify(editTreatmentInfo),
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            setTreatmentInfo(response.data);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/patient/${id}/health_history_treatment`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    )
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setTreatmentInfo(response.data);
          setEditTreatmentInfo({
            ...response.data,
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={1} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <PatientSidebar
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            isHealthHistoryOpen={isHealthHistoryOpen}
            isHealthOpen={isHealthHistoryOpen}
            id={id}
          />
          {treatmentInfo && (
            <div className="mx-auto max-w-5xl">
              {patientReferrals === null ? (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${firstName} ${lastName}`}
                </h1>
              ) : (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                </h1>
              )}
              <form
                className="lg:mx-16 flex flex-col"
                onSubmit={event => {
                  event.preventDefault();
                  saveTreatmentInfo();
                }}
              >
                <div className="mt-4 flex justify-between items-center">
                  <button
                    type="button"
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {editMode ? translate('cancel') : translate('edit')}
                  </button>
                  <p className="mx-4">{translate('treatment')}</p>
                  <button
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                    disabled={!editMode}
                  >
                    {translate('save')}
                  </button>
                </div>
                <section className="mt-10">
                  <p className="text-xl text-text-2">{translate('hair_loss_treatment')}</p>
                  <div className="mt-6 border-t-2 border-background-2 pt-4">
                    <div className="grid mt-8 grid-cols-5 items-center gap-10">
                      <p>{translate('transplant')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                  style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          readOnly
                          name="transplant_replacement_selected"
                          value=""
                          checked={
                            editMode
                              ? editTreatmentInfo.transplant_replacement_selected ===
                                1
                              : treatmentInfo.transplant_replacement_selected ===
                                1
                          }
                          onClick={e => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="transplant_replacement_when"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.transplant_replacement_selected ===
                            0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.transplant_replacement_selected ===
                            0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.transplant_replacement_when
                            : treatmentInfo.transplant_replacement_when
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="transplant_replacement_how_long"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.transplant_replacement_selected ===
                            0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.transplant_replacement_selected ===
                            0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.transplant_replacement_how_long
                            : treatmentInfo.transplant_replacement_how_long
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="transplant_replacement_response"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.transplant_replacement_selected ===
                            0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.transplant_replacement_selected ===
                            0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.transplant_replacement_response
                            : treatmentInfo.transplant_replacement_response
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <p>{translate('fat_grafting')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                  style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                        
                          readOnly
                          name="fat_grafting_replacement_selected"
                          value=""
                          checked={
                            editMode
                              ? editTreatmentInfo.fat_grafting_replacement_selected ===
                                1
                              : treatmentInfo.fat_grafting_replacement_selected ===
                                1
                          }
                          onClick={e => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        name="fat_grafting_replacement_when"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        disabled={
                          !editMode ||
                          editTreatmentInfo.fat_grafting_replacement_selected ===
                            0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.fat_grafting_replacement_selected ===
                            0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.fat_grafting_replacement_when
                            : treatmentInfo.fat_grafting_replacement_when
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="fat_grafting_replacement_how_long"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.fat_grafting_replacement_selected ===
                            0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.fat_grafting_replacement_selected ===
                            0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.fat_grafting_replacement_how_long
                            : treatmentInfo.fat_grafting_replacement_how_long
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="fat_grafting_replacement_response"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.fat_grafting_replacement_selected ===
                            0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.fat_grafting_replacement_selected ===
                            0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.fat_grafting_replacement_response
                            : treatmentInfo.fat_grafting_replacement_response
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <p>{translate('svf')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                  style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          readOnly
                          name="svf_replacement_selected"
                          value=""
                          checked={
                            editMode
                              ? editTreatmentInfo.svf_replacement_selected === 1
                              : treatmentInfo.svf_replacement_selected === 1
                          }
                          onClick={e => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="svf_replacement_when"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.svf_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.svf_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.svf_replacement_when
                            : treatmentInfo.svf_replacement_when
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="svf_replacement_how_long"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.svf_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.svf_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.svf_replacement_how_long
                            : treatmentInfo.svf_replacement_how_long
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="svf_replacement_response"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.svf_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.svf_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.svf_replacement_response
                            : treatmentInfo.svf_replacement_response
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <p>{translate('prp')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                          style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          readOnly
                          name="prp_replacement_selected"
                          value=""
                          checked={
                            editMode
                              ? editTreatmentInfo.prp_replacement_selected === 1
                              : treatmentInfo.prp_replacement_selected === 1
                          }
                          onClick={e => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="prp_replacement_when"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.prp_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.prp_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.prp_replacement_when
                            : treatmentInfo.prp_replacement_when
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="prp_replacement_how_long"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.prp_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.prp_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.prp_replacement_how_long
                            : treatmentInfo.prp_replacement_how_long
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="prp_replacement_response"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.prp_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.prp_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.prp_replacement_response
                            : treatmentInfo.prp_replacement_response
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <p>{translate('exosome')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                  style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          readOnly
                          name="exosome_replacement_selected"
                          value=""
                          checked={
                            editMode
                              ? editTreatmentInfo.exosome_replacement_selected ===
                                1
                              : treatmentInfo.exosome_replacement_selected === 1
                          }
                          onClick={e => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="exosome_replacement_when"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.exosome_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.exosome_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.exosome_replacement_when
                            : treatmentInfo.exosome_replacement_when
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="exosome_replacement_how_long"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.exosome_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.exosome_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.exosome_replacement_how_long
                            : treatmentInfo.exosome_replacement_how_long
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="exosome_replacement_response"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.exosome_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.exosome_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.exosome_replacement_response
                            : treatmentInfo.exosome_replacement_response
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <p>{translate('laser')}</p>
                      <label className="flex justify-end">
                        <input
                          type="radio"
                          style={{accentColor: isSalonApp ? '#F4831F' : '1e9ab9'}}
                          readOnly
                          name="laser_replacement_selected"
                          value=""
                          checked={
                            editMode
                              ? editTreatmentInfo.laser_replacement_selected ===
                                1
                              : treatmentInfo.laser_replacement_selected === 1
                          }
                          onClick={e => {
                            if (editMode) {
                              handleClickChange(e);
                            }
                          }}
                        />
                      </label>
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("when")}
                        name="laser_replacement_when"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.laser_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.laser_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.laser_replacement_when
                            : treatmentInfo.laser_replacement_when
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("how_long")}
                        name="laser_replacement_how_long"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.laser_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.laser_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.laser_replacement_how_long
                            : treatmentInfo.laser_replacement_how_long
                        }
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate("response")}
                        name="laser_replacement_response"
                        disabled={
                          !editMode ||
                          editTreatmentInfo.laser_replacement_selected === 0
                        }
                        value={
                          editTreatmentInfo &&
                          editTreatmentInfo.laser_replacement_selected === 0
                            ? ''
                            : editMode
                            ? editTreatmentInfo.laser_replacement_response
                            : treatmentInfo.laser_replacement_response
                        }
                        onChange={e => handleInputChange(e)}
                      />
                    </div>
                  </div>
                </section>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading message={`${translate("loading")} ${isSalonApp ? translate('client'):  translate('patient')} ${translate('treatment_history')}`} ready={ready} />
    </>
  );
}
