import React from "react";
import { useUserContext } from "../../contexts/userContext";
import { getDot } from "../../utlis/themeHelpers";
import { getTranslation } from "../../utlis/translationUtils";
import DotGrey from "../../assets/images/DotGrey.svg";

const marketplaceCategories = {
    groTrackCategories : [
        getTranslation('treatments'),
        getTranslation('products'),
        getTranslation('home_devices'),
        getTranslation('medication'),
    ],
    groSalonCategories : [
      getTranslation('products'),
      getTranslation('home_devices'),
      getTranslation('services'),
      "GRO " + getTranslation('clinics'),
    ]
}

const CategoryBar = ({ handleChangeSection, resetIndex, showSection }) => {

    const {isSalonApp} = useUserContext()
    const requiredCategories = marketplaceCategories?.[isSalonApp ? "groSalonCategories" : "groTrackCategories"]

  return (
    <div className="flex justify-between items-center mx-40">
        {
            requiredCategories?.map((category, index) => {
                return (
                    <div className="flex mr-8 items-center">
                    <img
                      src={showSection === category ? getDot(isSalonApp) : DotGrey}
                      onClick={() => {
                        handleChangeSection(category);
                        resetIndex();
                      }}
                      className="h-3 w-3 mr-4 cursor-pointer"
                      alt="dot"
                    />
                    <span className="text-lg text-text-2 uppercase tracking-widest">
                      {category}
                    </span>
                  </div>
                )
            })
        }
    </div>
  );
};

export default CategoryBar;
