import React, { createContext, useContext, useEffect, useState } from 'react'
import PatientNavbar from '../components/PatientNavbar';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Loading from './Loading';
import lessThanIcon from "../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../assets/images/greaterThanIcon.svg";
import dayjs from 'dayjs';
import ThreexCloseupImageView from '../components/StudyModeComponents/ThreexCloseupImageView';
import ThreexCloseuplmageCompare from '../components/StudyModeComponents/ThreexCloseuplmageCompare';
import { useTranslation } from "../contexts/TranslationContext";

const changeAnalysis = (history, direction, currentIndex, analyses) => {
	const url = window.location.pathname;
	const patientId = url.match(/\/patient\/(\d+)\//)[1];
	const analysisId = url.match(/\/study\/(\d+)\//)[1];
	const subTypeMatch = url.match(/\/(\w+)$/);
	const subType = subTypeMatch ? subTypeMatch[1] : null;
	let newIndex;

	if (direction === 'prev' && currentIndex > 0) {
		newIndex = currentIndex - 1;
	} else if (direction === 'next' && currentIndex < analyses.length - 1) {
		newIndex = currentIndex + 1;
	} else {
		return;
	}

	if (url.includes('/compare/')) {
		history.push(`/patient/${patientId}/study/${newIndex}/compare/${subType}`);
	} else {
		history.push(`/patient/${patientId}/study/${newIndex}/${subType}`);
	}
};

export const ChangeAnalysisContext = createContext({
	changeAnalysis: changeAnalysis
});

export const GenderContext = createContext();

const StudyMode = () => {
	const history = useHistory();
	const { translate } = useTranslation();

	const { patient_id, analysis_id } = useParams();
	const analysis_id_int = parseInt(analysis_id);

	const [message, setMessage] = useState("");
	const [ready, setReady] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [patientReferrals, setPatientReferrals] = useState(null);
	const [hairAnalysis, setHairAnalysis] = useState([]);
	const [gender, setGender] = useState("M");

	const { changeAnalysis } = useContext(ChangeAnalysisContext);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API}/patient/${patient_id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.patient.gender) {
					setGender(response.patient.gender);
				}
			})
			.catch((error) => {
				console.error(error);
				setMessage(translate('error_occurred'));
			})
			.finally(() => {
				setReady(true);
			});
		fetch(`${process.env.REACT_APP_API}/hair_analysis/all/v2?patient_id=${patient_id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.success) {
					setFirstName(response.firstName);
					setLastName(response.lastName);
					setHairAnalysis(response.hair_analysis);
					//   setCurrentId(0);
					setPatientReferrals(response.referral);

				} else {
					setMessage(response.message);
				}
				setReady(true);
			})
			.catch((error) => {
				console.error(error);
				setMessage(translate('error_occurred'));
			});
	}, [patient_id]);

	return (
		<>
			<main
				className={`mx-auto container flex flex-col p-4 ${ready ? "" : "hidden"
					}`}
			>
				<PatientNavbar id={patient_id} tab={7} />
				{message && <p className="mt-8 text-center text-red-600">{message}</p>}
				<section className="mt-6">
					<div>
						{/* {patientReferrals ? (
						<h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
							{`${firstName} ${lastName} referred by ${patientReferrals.referred_by}`}
						</h1>
						) : ( */}
						<h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
							{`${firstName} ${lastName}`}
						</h1>
						{/* )} */}
						<div className="flex items-center md:justify-evenly flex-col md:flex-row">
							<div className=" flex items-center">
								<button
									className="font-bold text-2xl focus:outline-none"
									onClick={function () {
										changeAnalysis(history, 'prev', analysis_id_int, hairAnalysis)
									}}
								>

									<img src={lessThanIcon} className="h-6 w-6" />
								</button>
								<p className="mx-4 text-text-8">
									{hairAnalysis[analysis_id_int]
										? dayjs
											.unix(hairAnalysis[analysis_id_int].created_at)
											.format("MM-DD-YY")
										: ""}
								</p>
								<button
									className="font-bold text-2xl focus:outline-none"
									onClick={function () {
										changeAnalysis(history, 'next', analysis_id_int, hairAnalysis)
									}}
								>
									<img src={greaterThanIcon} className="h-6 w-6" />
								</button>
							</div>
						</div>
					</div>
					<div
						className="p-8 pt-0 mt-4 bg-background-5 overflow-hidden relative"
						style={{ minHeight: "var(--height-2)" }}
					>
						<div>
							<ChangeAnalysisContext.Provider value={{ changeAnalysis: changeAnalysis }}>
								<GenderContext.Provider value={{ gender: gender }}>
									<Switch>
										<Route path="/patient/:patient_id/study/:analysis_id/compare/:subType" exact>
											<ThreexCloseuplmageCompare analysis={hairAnalysis[analysis_id_int]} allAnalysis={hairAnalysis} />
										</Route>
										<Route path="/patient/:patient_id/study/:analysis_id/:subType" exact>
											<ThreexCloseupImageView analysis={hairAnalysis[analysis_id_int]} allAnalysis={hairAnalysis} />
										</Route>
									</Switch>
								</GenderContext.Provider>
							</ChangeAnalysisContext.Provider>
						</div>
					</div>
				</section>
			</main>
			<Loading message={translate('loading')} ready={ready} />
		</>
	)
}

export default StudyMode