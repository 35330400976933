import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../views/Loading";
import PatientNavbar from "../components/PatientNavbar";
import PatientSidebar from "../components/PatientSidebar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUserContext } from "../contexts/userContext";
import { useTranslation } from "../contexts/TranslationContext";

export default function PatientProfile() {
  const { id } = useParams();
  const [examDrop, setExamDrop] = useState(false);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
  const [healthDropdown, setHealthDropdown] = useState(false);
  const [patient, setPatient] = useState(null);
  const [editPatient, setEditPatient] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);
  const {isSalonApp} = useUserContext()
  const { translate } = useTranslation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  const isPatientValid = () => {
    return true;
  };

  const savePatient = () => {
    if (isPatientValid()) {
      setReady(false);
      let patientTemp = editPatient;
      patientTemp.dob = moment(editPatient.dob).format("YYYY-MM-DD");
      fetch(`${process.env.REACT_APP_API}/patient/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(editPatient),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setPatient(response.patient);
            setEditPatient({
              ...response.patient,
              address: { ...response.patient.address },
            });
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPatient(response.patient);
          setEditPatient({
            ...response.patient,
            address: { ...response.patient.address },
            age: moment().diff(moment(response.patient.dob, "MM-DD-YYYY"), "years")
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    if (editPatient && editPatient.dob) {
      let age = moment().diff(moment(editPatient.dob, "MM-DD-YYYY"), "years");
      setEditPatient({
        ...editPatient,
        age: age,
      });
    }
  }, [editPatient && editPatient.dob]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-5 font-font-2 ${ready ? "" : "hidden"
          }`}
      >
        <div className="mx-10 border-b-2 border-gray-700 pb-5">
          <PatientNavbar id={id} tab={1} />
        </div>
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex px-5">
          <PatientSidebar
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            id={id}
          />
          {patient && (
            <div className="mx-auto max-w-5xl mt-16">
              {patientReferrals ? (
                <h1 className="ml-auto w-40 md:w-96 flex justify-center text-lg text-center text-text-3 font-bold">
                  {`${patient.firstName} ${patient.lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                </h1>
              ) : (
                <h1 className="ml-auto w-40 md:w-96 flex justify-center text-lg text-center text-text-3 font-bold">
                  {`${patient.firstName} ${patient.lastName}`}
                </h1>
              )}
              {/* <div className="mt-5 flex justify-between items-center">
                <button
                  type="button"
                  className="px-8 py-1 bg-accent-1 rounded-lg text-text-3
              cursor-pointer focus:outline-none"
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  {editMode ? "Cancel" : "Edit"}
                </button>
                <p className="mx-32 lg:mx-48">PATIENT INFORMATION</p>
                <button
                  className="px-8 py-1 bg-accent-1 rounded-lg text-text-3
              cursor-pointer focus:outline-none"
                  disabled={!editMode}
                  onClick={() => {
                    setEditMode(!editMode);
                    if (editMode) {
                      savePatient();
                    }
                  }}
                >
                  SAVE
                </button>
              </div> */}
              <form
                className="flex flex-col text-text-2 font-font-2"
                onSubmit={(event) => {
                  event.preventDefault();
                  savePatient();
                }}
              >
                <div className="mx-auto mt-12 flex flex-col items-end">
                  <div className="mt-5 self-end flex items-center md:flex-row">
                    <p>{translate('name')}</p>
                    <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                      <label className="flex items-center">
                        <input
                          type="text"
                          className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                          value={
                            editMode ? editPatient.firstName : patient.firstName
                          }
                          disabled={!editMode}
                          required
                          onChange={(event) => {
                            setEditPatient({
                              ...editPatient,
                              firstName: event.target.value,
                            });
                          }}
                        />
                      </label>
                      <label className="flex items-center">
                        <input
                          type="text"
                          className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                          value={editMode ? editPatient.lastName : patient.lastName}
                          disabled={!editMode}
                          required
                          onChange={(event) => {
                            setEditPatient({
                              ...editPatient,
                              lastName: event.target.value,
                            });
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="mt-5 self-end flex items-center md:flex-row">
                    <p>{translate('dob')}</p>
                    <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2">

                      {/* <input
                        type="date"
                        className="ml-6 w-40 px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={editMode ? editPatient.dob : patient.dob}
                        disabled={!editMode}
                        onChange={(event) => {
                          setEditPatient({
                            ...editPatient,
                            dob: event.target.value,
                          });
                        }}
                      /> */}
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        disabled={!editMode}
                        className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 text-center rounded-sm focus:outline-none"
                        selected={
                          editMode
                            ? Date.parse(editPatient.dob)
                            : Date.parse(patient.dob)
                        }
                        onChange={(date) => {
                          setEditPatient({
                            ...editPatient,
                            dob: date,
                          });
                        }}
                      />
                      <label className="mt-5 md:mt-0 flex items-center">
                        <p className="ml-5">{translate('age')}</p>
                        <p
                          className="text-left ml-6 w-28 px-2 py-1 font-bold text-text-2 rounded-sm focus:outline-none"
                        >
                          {editPatient?.age || ''}
                        </p>
                      </label>
                    </div>
                  </div>
                  <div className="mt-5 self-end flex items-center md:flex-row">
                    <p>{translate('gender')}</p>
                    <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                      <select
                        value={editMode ? editPatient.gender : patient.gender}
                        onChange={(e) =>
                          setEditPatient({
                            ...editPatient,
                            gender: e.target.value,
                          })
                        }
                        className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                      >
                        <option value="M">{translate('male')}</option>
                        <option value="F">{translate('female')}</option>
                        <option value="O">{translate('others')}</option>
                      </select>
                      <label className="mt-5 md:mt-0 flex items-center justify-end">
                        <p>{translate('race')}</p>
                        <select
                          value={editMode ? editPatient.ethnicity : patient.ethnicity}
                          onChange={(e) => {
                            setEditPatient({
                              ...editPatient,
                              ethnicity: e.target.value,
                            });
                          }
                          }
                          className="ml-6 patients-form w-28 px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                        >
                          <option value="White">{translate('white')}</option>
                          <option value="Black">{translate('black')}</option>
                          <option value="Hispanic">{translate('hispanic')}</option>
                          <option value="Asian">{translate('asian')}</option>
                          <option value="Other">{translate('others')}</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <label className="mt-5 flex items-center">
                    <p className="ml-2">{translate('address')}</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                      value={
                        editMode
                          ? editPatient.address.street
                          : patient.address.street
                      }
                      disabled={!editMode}
                      placeholder={translate('street')}
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          address: {
                            ...editPatient.address,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                    value={
                      editMode
                        ? editPatient.address.suite
                        : patient.address.suite
                    }
                    disabled={!editMode}
                    placeholder={translate('suite')}
                    onChange={(event) => {
                      setEditPatient({
                        ...editPatient,
                        address: {
                          ...editPatient.address,
                          suite: event.target.value,
                        },
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                    value={
                      editMode ? editPatient.address.city : patient.address.city
                    }
                    disabled={!editMode}
                    placeholder={translate('city')}
                    onChange={(event) => {
                      setEditPatient({
                        ...editPatient,
                        address: {
                          ...editPatient.address,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-5 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editPatient.address.state
                            : patient.address.state
                        }
                        disabled={!editMode}
                        placeholder={translate('state')}
                        onChange={(event) => {
                          setEditPatient({
                            ...editPatient,
                            address: {
                              ...editPatient.address,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                        value={
                          editMode
                            ? editPatient.address.zip
                            : patient.address.zip
                        }
                        disabled={!editMode}
                        placeholder={translate('zip')}
                        onChange={(event) => {
                          setEditPatient({
                            ...editPatient,
                            address: {
                              ...editPatient.address,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-5 flex items-center">
                    <p className="ml-2">{translate('phone')}</p>
                    <input
                      type="text"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                      value={editMode ? editPatient.phone : patient.phone}
                      disabled={!editMode}
                      required
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  <label className="mt-5 flex items-center">
                    <p className="ml-2">{translate('email')}</p>
                    <input
                      type="email"
                      className="ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 font-bold text-text-2 rounded-sm focus:outline-none"
                      value={editMode ? editPatient.email : patient.email}
                      disabled={!editMode}
                      onChange={(event) => {
                        setEditPatient({
                          ...editPatient,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                </div>
              </form>
              <div className="flex w-40 md:w-96 justify-center mt-8 ml-auto">
                <button
                  type="button"
                  className="px-12 py-1 bg-background-23 font-bold tracking-wider rounded-lg text-text-3
              cursor-pointer focus:outline-none"
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  {editMode ? translate('cancel') : translate('edit')}
                </button>
                {editMode && <button
                  className="px-12 ml-6 py-1 bg-background-23 font-bold tracking-wider rounded-lg text-text-3
                  cursor-pointer focus:outline-none"
                  disabled={!editMode}
                  onClick={() => {
                    setEditMode(!editMode);
                    if (editMode) {
                      savePatient();
                    }
                  }}
                >
                  {translate('save')}
                </button>}
              </div>
            </div>
          )}
        </section>
      </main>
      <Loading message={`${translate('loading')} ${isSalonApp ? translate('client'):  translate('patient')} ${translate('health_info')}`} ready={ready} />
    </>
  );
}
