import React, { useEffect, useState } from 'react'
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import greaterThanIcon from "../../assets/images/greaterThanIcon.svg";
import { AiOutlinePlus, AiOutlineCloseCircle } from "react-icons/ai";
import { useCartContext } from '../../views/AdminMarkerPlaceWrapper';
import GroRating from "../../assets/images/GRORatingsLogo.svg";
import { useTranslation } from '../../contexts/TranslationContext';


const Products = ({ handleSlide, showSection, productsList, showDetails, productStartIndex, productEndIndex, GroRating, showBuyButton }) => {

    return (
        <div className="flex items-center mt-20">
            <div className="mr-10 w-14 pb-12 cursor-pointer">
                <img
                    className="w-14 h-14"
                    src={lessThanIcon}
                    onClick={() => handleSlide(false)}
                    alt="left-arrow"
                />
            </div>
            <div className="grid grid-cols-3 gap-20 flex-grow">
                {productsList?.map((item, index) => {
                    return index >= productStartIndex &&
                        index <= productEndIndex &&
                        index < productsList.length ? (
                        <ProductItem item={item} showDetails={showDetails} showSection={showSection}/>
                    ) : (
                        <></>
                    );
                })}
            </div>
            <div className="ml-10 w-14 pb-12 cursor-pointer">
                <img
                    className="w-14 h-14"
                    src={greaterThanIcon}
                    onClick={() => handleSlide(true)}
                    alt="left-arrow"
                />
            </div>
        </div >
    )
}

export default Products

const ProductItem = ({item, showSection, showDetails}) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const { cartItems, handleAddItemToCart, handleRemoveItemFromCart } = useCartContext();
    const { translate } = useTranslation();
    const handleConfirm = (item) => {
        setShowTooltip(false)
        const cartItemPresent = cartItems?.some(p => p?.id === item?.id);
        if (cartItemPresent) {
            handleRemoveItemFromCart(item?.id)
        } else {
            handleAddItemToCart({ ...item, category: showSection })
        }
    }

    const getTooltipText = (item) => {
        const cartItemPresent = cartItems?.some(p => p?.id === item?.id);
        return cartItemPresent ?
            `Remove ${item?.title} from your cart?`
            : `${translate('add')} ${item?.title} to your cart?`
    }


    return (
        <div className="flex justify-center h-full">
            <div className="flex flex-col h-full">
                <div
                    className="bg-text-3 w-64 text-center text-black cursor-pointer overflow-hidden"
                    onClick={() => showDetails(item)}
                    style={{ opacity: "90%" }}
                >
                    <div className="py-3 font-font-1">
                        <h3 className="text-lg">{item?.title}</h3>
                        <h4 className="font-normal">{item?.sub_title}</h4>
                    </div>
                    <img
                        src={item?.banner_image}
                        className="h-36 w-auto object-cover"
                        alt="banner"
                    />
                    <div className="h-12 overflow-hidden px-3 mt-8 mb-2 tracking-wide font-normal text-text-6">
                        {item?.description}
                    </div>
                    <img
                        src={GroRating}
                        className="w-20 h-14 m-auto"
                        alt="gro-rating"
                    />
                    <hr className="border-b-2 border-b-accent-7 mb-3 mx-6" />
                    <div
                        className="w-28 m-auto text-lg border-4 border-gray-400 mb-2"
                        style={{ borderRadius: "20px" }}
                    >
                        {item?.price ? item?.price?.split(".")[0] : "N/A"}
                    </div>
                    <h5 className="text-xs tracking-wide text-text-1 font-normal mb-4">
                        {translate('gro_verified_cases')}
                    </h5>
                </div>
                <div className="bg-background-1 p-3 flex justify-center relative">
                    {cartItems?.some(c => c?.id === item?.id) ? (
                        <AiOutlineCloseCircle onClick={() => { setShowTooltip(prev => !prev) }} className="w-6 h-6 text-text-3 m-auto cursor-pointer" />
                    ) : (
                        <AiOutlinePlus onClick={() => { setShowTooltip(prev => !prev) }} className="w-6 h-6 text-text-3 m-auto cursor-pointer" />
                    )}
                    {showTooltip && (
                        <div className="absolute top-12 flex flex-col items-center bg-white text-center rounded-lg p-5 px-10">
                            <p className='text-xs xs:text-base mb-4 font-bold leading-md text-black whitespace-nowrap'>{getTooltipText(item)}</p>
                            <button className='rounded-lg uppercase text-text-3 text-xs tracking-widest font-bold bg-button-5 px-6 py-2' onClick={() => { handleConfirm(item) }}>{translate('confirm_sm')}</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
