import React,{useState} from 'react'
//import {MdOutlinePictureAsPdf} from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { useTranslation } from '../contexts/TranslationContext'

function InfoForm({ product, getTreatmentPlans }) {
  const { translate } = useTranslation();
  const {id} = useParams()
  
  const obj = {
    volume: product.volume,
    supply: product.supply,
    fee: product.fee,
    treatment_plan_id: product.id
  }

  const [formData, setFormData] = useState(obj)
  const[ edit,setEdit] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const updateTreatment = () => {
    //setReady(false);
    fetch(
      `${process.env.REACT_APP_API}/patient/${id}/treatment_plan/product`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          getTreatmentPlans();
        }
      })
      .catch((error) => {
        console.error(error);
        //setMessage('Some Error Occured. Please Try Again Later');
      })
      .finally(() => {
        //setReady(true);
      });
  };

  return (
    <div
      style={{
        background: "#1a2d3ea9",
        width: "80%",
        margin: "1rem auto",
        padding: "2rem 0 3rem 0",
      }}
    >
      {/* <div style={{margin:'0 auto',marginBottom:'5rem',width:'65%'}}>
        <div style={{width:'100%',margin:'1rem auto',display:'flex',alignItems:'center'}}>
          <div style={{width:'20%',textAlign:'right'}}>Product Type</div>
          <input style={{width:'75%',marginLeft:'2rem',background:'rgba(246, 247, 250, 0.5)',outline:'none',boxSizing:'border-box',padding:'0.2rem 0.5rem',color:'#bfc5ca'}} />
        </div>
        <div style={{width:'100%',margin:'1rem auto',display:'flex',alignItems:'center'}}>
          <div style={{width:'20%',textAlign:'right'}}>Brand Name</div>
          <input style={{width:'75%',marginLeft:'2rem',background:'rgba(246, 247, 250, 0.5)',outline:'none',boxSizing:'border-box',padding:'0.2rem 0.5rem',color:'#bfc5ca'}} />
        </div>
        <div style={{width:'100%',margin:'1rem auto',display:'flex',alignItems:'center'}}>
          <div style={{width:'20%',textAlign:'right'}}>Volum</div>
          <input style={{width:'40%',marginLeft:'2rem',background:'rgba(246, 247, 250, 0.5)',outline:'none',boxSizing:'border-box',padding:'0.2rem 0.5rem',color:'#bfc5ca'}} />
        </div>
        <div style={{width:'100%',margin:'1rem auto',display:'flex',alignItems:'center'}}>
          <div style={{width:'20%',textAlign:'right'}}>Fee</div>
          <input style={{width:'40%',marginLeft:'2rem',background:'rgba(246, 247, 250, 0.5)',outline:'none',boxSizing:'border-box',padding:'0.2rem 0.5rem',color:'#bfc5ca'}} />
        </div>
      </div>
  
      <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginRight:'5rem'}}>
          <MdOutlinePictureAsPdf style={{fontSize:'25px',color:'white'}}/>
          <div>Ingredient List</div>
        </div>
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
          <MdOutlinePictureAsPdf style={{fontSize:'25px',color:'white'}}/>
          <div>Use Direction</div>
        </div>
      </div> */}

      <div style={{ textAlign: "right" }}>
        {edit ? (
          <button
            onClick={updateTreatment}
            style={{
              background: "#3BACA1",
              borderRadius: "5px",
              color: "white",
              padding: "0.2rem 1rem",
              fontSize: "0.8rem",
            }}
          >
            {translate('save')}
          </button>
        ) : (
          <button
            onClick={() => setEdit(!edit)}
            style={{
              background: "#3BACA1",
              borderRadius: "5px",
              color: "white",
              padding: "0.2rem 1rem",
              fontSize: "0.8rem",
            }}
          >
            {translate('edit')}
          </button>
        )}
      </div>

      <div style={{ color: "#637381", textAlign: "center" }}>
        <div style={{ fontSize: "1.5rem" }}>{translate('keralase')}</div>
        <div>{translate('laser_treatment')}</div>
      </div>

      <div style={{ margin: "5rem 0" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "0.5rem auto",
            width: "35%",
          }}
        >
          <div style={{ textAlign: "right" }}>{translate('volume')}</div>
          <input
            type="text"
            name="volume"
            disabled={!edit}
            value={formData.volume}
            onChange={handleChange}
            style={{
              width: "15rem",
              marginLeft: "1.5rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "0.5rem auto",
            width: "35%",
          }}
        >
          <div style={{ textAlign: "right" }}>{translate('supply')}</div>
          <input
            type="text"
            name="supply"
            value={formData.supply}
            disabled={!edit}
            onChange={handleChange}
            style={{
              width: "15rem",
              marginLeft: "1.5rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "0.5rem auto",
            width: "35%",
          }}
        >
          <div style={{ textAlign: "right", width: "20%" }}>{translate('fee')}</div>
          <input
            type="text"
            name="fee"
            value={formData.fee}
            disabled={!edit}
            onChange={handleChange}
            style={{
              width: "15rem",
              marginLeft: "1.5rem",
              outline: "none",
              border: "none",
              padding: "0.2rem 0.5rem",
              background: "rgba(246, 247, 250, 0.5)",
              textAlign: "center",
              color: "black",
              fontWeight: "bold",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default InfoForm