import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayjs from 'dayjs';
import PatientNavbar from '../components/PatientNavbar';
import Loading from '../views/Loading';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from "../contexts/TranslationContext";

export default function PatientViewSchedule() {
  const { id } = useParams();
  const calendar = useRef();
  const { translate } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [view, setView] = useState("dayGridMonth");
  const [procedures, setProcedures] = useState(null);
  const [procedureId, setProcedureId] = useState(null);
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  const [patientReferrals, setPatientReferrals] = useState(null);
  // const changeView = (view) => {
  //   const calendarApi = calendar.current.getApi();
  //   calendarApi.changeView(view);
  //   setView(view);
  // };

  const goToDate = date => {
    const calendarApi = calendar.current.getApi();
    calendarApi.changeView('timeGridWeek', date.date);
    // setView("timeGridWeek");
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setPatientReferrals(response.referral);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/schedule-appointment`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setProcedures(response.procedures);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={4} />
        <section className="mt-6">
          {message && <p className="text-center text-red-600">{message}</p>}
          {patientReferrals ? (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}
          <div className="mt-2 flex items-center md:justify-center flex-col md:flex-row">
            <NavLink
              exact
              to={`/patient/schedule/schedule-appointment/${id}`}
              activeClassName="text-text-2"
            >
              {translate('schedule_appointment')}
            </NavLink>
            <div className="mx-8 my-2 w-2 h-2 bg-background-23 rounded-full"></div>
            <NavLink
              exact
              to={`/patient/schedule/view-schedule/${id}`}
              activeClassName="text-text-2"
            >
              {translate('calendar_view')}
            </NavLink>
          </div>
          {procedures && ready && (
            <div className="mt-4 grid gap-4 xl:grid-cols-2">
              <div
                className="bg-background-5 p-4 flex flex-col"
                style={{ height: 'var(--height-1)', overflowY: 'auto' }}
              >
                <h1 className="text-center text-text-2">{translate('appointment_list')}</h1>
                {procedures.length > 0 ? (
                  <>
                    {procedures.map(procedure => {
                      return (
                        <div key={procedure.id} className="mt-6">
                          <div className="flex items-center">
                            <div className="w-2 h-2 bg-background-23 rounded-full"></div>
                            <p className="ml-4 w-1/2">
                              {dayjs(procedure.startTime).format(
                                'MMM D, dddd, h:mma'
                              )}
                            </p>
                            <p className="ml-8">{procedure.physician}</p>
                          </div>
                          <div className="mt-2 grid grid-cols-7 text-center text-text-3 font-normal font-font-2">
                            <h2 className="px-2 py-1 bg-background-6 font-bold">
                              {procedure.type}
                            </h2>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.brand}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.tx}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.interval}
                            </p>
                            <p className="px-2 py-1 w-full border-r border-background-6 bg-background-7">
                              {procedure.area}
                            </p>
                            <p className="px-2 py-1 w-full bg-background-7">
                              {procedure.time}
                            </p>
                            <button
                              className="mx-auto focus:outline-none"
                              onClick={() => {
                                setProcedureId(procedure.id);
                                goToDate({ date: procedure.startTime });
                              }}
                            >
                              <svg
                                className={`h-6 w-6 ${
                                  procedure.id === procedureId
                                    ? 'text-accent-1'
                                    : 'text-background-7'
                                }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <h2 className="mt-8 text-center">
                    {translate('no_scheduled_treatments_currently_available')}
                  </h2>
                )}
              </div>
              <div
                className="bg-background-5 p-4 flex flex-col"
                style={{ height: 'var(--height-1)', overflowY: 'auto' }}
              >
                <h1 className="text-center text-text-2">{translate('calendar_view')}</h1>
                {/* <div className="my-4 ml-auto">
                  <button
                    className={`px-2 py-1 rounded-lg focus:outline-none ${
                      view === "dayGridMonth" && "bg-background-23 text-text-3"
                    }`}
                    onClick={() => changeView("dayGridMonth")}
                  >
                    Month
                  </button>
                  <button
                    className={`px-2 py-1 rounded-lg focus:outline-none ${
                      view === "timeGridWeek" && "bg-background-23 text-text-3"
                    }`}
                    onClick={() => changeView("timeGridWeek")}
                  >
                    Week
                  </button>
                </div> */}
                <FullCalendar
                  ref={calendar}
                  initialView="timeGridWeek"
                  plugins={[timeGridPlugin, interactionPlugin]}
                  events={procedures.map(procedure => {
                    return {
                      title: `${procedure.tx ? procedure.tx : ''} ${
                        procedure.type
                      }`,
                      start: procedure.startTime,
                      end: procedure.endTime,
                      physician: procedure.physician,
                      area: procedure.area,
                    };
                  })}
                  dateClick={goToDate}
                  allDaySlot={false}
                  slotDuration="00:15:00"
                  slotLabelInterval="01:00"
                  dayHeaderContent={function (day) {
                    return dayjs(day.date).format('ddd D');
                  }}
                  eventBackgroundColor="#13C4B3"
                  headerToolbar={{
                    start: 'prev', // will normally be on the left. if RTL, will be on the right
                    center: 'title',
                    end: 'next', // will normally be on the right. if RTL, will be on the left
                  }}
                  height="100%"
                  eventDidMount={info => {
                    const duration = dayjs(info.event.end).diff(
                      dayjs(info.event.start),
                      'minute'
                    );
                    let content = `
                      <div>
                        <p>${info.event.title}</p>
                        <p>${info.event.extendedProps.physician}</p>
                        <p>${duration} mins.</p>
                        <p>${
                          info.event.extendedProps.area
                            ? info.event.extendedProps.area
                            : ''
                        }</p>
                      </div>
                    `;
                    tippy(info.el, {
                      content: content,
                      allowHTML: true,
                      arrow: false,
                      placement: 'bottom',
                    });
                  }}
                />
              </div>
            </div>
          )}
        </section>
      </main>
      <Loading message={translate('loading_appointment_schedule')} ready={ready} />
    </>
  );
}
