import { useEffect, useRef, useState } from "react";
import {
  AVATAR_IMAGE_URL,
  DID_API_KEY,
  DID_API_URL,
  DID_TALK_SERVICE,
  OPENAI_ASSITANT_ID,
  OPENAI_SECRET_KEY,
  INTERCOM_API_KEY,
  DID_AGENT_ID,
} from "../constants";

const MAX_RETRY_COUNT = 3;
const MAX_DELAY_SEC = 4;

export const CHATBOT_STATE = {
  NOT_INITIATED: "NOT_INITIATED",
  IS_INITIATING: "IS_INITIATING",
  READY: "READY",
  PENDING: "PENDING",
};

const { OpenAI } = require("openai");

const openai = new OpenAI({
  apiKey: OPENAI_SECRET_KEY,
  dangerouslyAllowBrowser: true,
});

const AI_DEFAULT_MSG = {
  id: new Date().getTime(),
  sender: "AI",
  text: "Hii, how can I help you?",
};
const useChatBot = () => {
  const [stream, setStream] = useState(null);
  const [messages, setMessages] = useState([AI_DEFAULT_MSG]);
  const [activeMessage, setActiveMessage] = useState(null);
  const [chatbotState, setChatbotState] = useState(CHATBOT_STATE.NOT_INITIATED);
  const [customerSatisfaction, setCustomerSatisfaction] = useState(true);
  const [lastCheckedCustomerSatisfaction, setLastCheckedCustomerSatisfaction] =
    useState(0);
  // const [currentChatThreadId, setCurrentChatThreadId] = useState("");
  const currentChatThreadId = useRef(null);
  const currentAIRunInfo = useRef(null);
  const currentChatId = useRef(null);

  const peerConnecion = useRef(null);
  const statsIntervalId = useRef(null);
  const sessionId = useRef(null);
  const streamId = useRef(null);
  const lastBytesReceived = useRef(0);
  const chatId = useRef(null);
  const timeoutStream = useRef(null);

  const RTCPeerConnection = (
    window.RTCPeerConnection ||
    window.webkitRTCPeerConnection ||
    window.mozRTCPeerConnection
  ).bind(window);

  let videoIsPlaying;

  const presenterInputByService = {
    talks: {
      source_url: AVATAR_IMAGE_URL,
    },
    clips: {
      presenter_id: "rian-lZC6MmWfC1",
      driver_id: "mXra4jY38i",
    },
  };

  const connectChatbot = async () => {
    if (
      peerConnecion.current &&
      peerConnecion.current.connectionState === "connected"
    ) {
      return;
    }
    _closePeerConnection();
    try {
      setChatbotState(CHATBOT_STATE.IS_INITIATING);
    

      

      const sessionResponse = await _fetchWithRetries(
        `${DID_API_URL}/${DID_TALK_SERVICE}/streams`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${DID_API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(presenterInputByService[DID_TALK_SERVICE]),
        }
      );

      const {
        id: newStreamId,
        offer,
        ice_servers: iceServers,
        session_id: newSessionId,
      } = await sessionResponse.json();

      streamId.current = newStreamId;
      sessionId.current = newSessionId;

      const clientAnswer = await _createPeerConnection(offer, iceServers);

      if (!streamId.current) {
        return null;
      }

      const sdpResponse = await fetch(
        `${DID_API_URL}/${DID_TALK_SERVICE}/streams/${streamId.current}/sdp`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${DID_API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            answer: clientAnswer,
            session_id: sessionId.current,
          }),
        }
      );

      setChatbotState(CHATBOT_STATE.READY);

      const chatResponse = await fetch
      (`${DID_API_URL}/agents/${DID_AGENT_ID}/chat`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${DID_API_KEY}`,
          "Content-Type": "application/json",
        },

      }
      )

      const chatResponseJson = await chatResponse.json();
      chatId.current = chatResponseJson.id;


    } catch (e) {
      console.log("error during streaming setup", e);
      _closePeerConnection();
      return;
    }
  };

  const findOutIfCustomerIsUnhappy = async (latestQuery) => {
    await openai.chat.completions
      .create({
        messages: [
          {
            role: "system",
            content:
              "You will be given list of messages. From the list you need to guess if the user is satisfied or not. If you are not sure, you can assume user is satisfied. Give `satisfied` or `unsatisfied` as the answer. Don't give any other answer.",
          },
          {
            role: "user",
            content: `
            ${messages
              .reverse()
              .slice(0, 3)
              .filter(({ sender }) => {
                return sender === "USER";
              })
              .map(({ text }) => {
                return "USER:" + text;
              })
              .join("\n")}
            USER:${latestQuery}
          `,
          },
        ],
        model: "gpt-3.5-turbo",
      })
      .then((response) => {
        console.log(
          "Response Customer",
          response.choices[0]?.message?.content,
          response
        );

        if (response.choices[0]?.message?.content.includes("unsatisfied")) {
          console.log("Customer is unhappy");
          setCustomerSatisfaction(false);
          setLastCheckedCustomerSatisfaction(0);
        } else {
          setCustomerSatisfaction(true);
        }
      })
      .catch(() => {
        setCustomerSatisfaction(true);
      })
      .finally(() => {});
  };

  const resetCustomerSatisfaction = () => {
    setLastCheckedCustomerSatisfaction(0);
    setCustomerSatisfaction(true);
  };

  const talkToChatbot = async (customerQuery) => {
    // connectionState not supported in firefox
    setChatbotState(CHATBOT_STATE.PENDING);
    const newMessage = { id: new Date().getTime(), sender: "USER", text: customerQuery };
    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setLastCheckedCustomerSatisfaction((s) => s + 1);
    setCustomerSatisfaction(true);
    if (lastCheckedCustomerSatisfaction >= 2) {
      findOutIfCustomerIsUnhappy(customerQuery);
    }
    try {
      if (
        peerConnecion.current?.signalingState !== "stable" ||
        peerConnecion.current?.iceConnectionState !== "connected" ||
        !streamId.current
      ) {
        return null;
      }
      setActiveMessage({
        isFinal: false,
        text: "Please wait, I am thinking",
      });
      // await _getAnswerFromAI(customerQuery);
      // const scriptToSendToChatbot = await _retrieveAIRun();

      // setMessages((p) => [
      //   ...p,
      //   { id: new Date().getTime(), sender: "AI", text: scriptToSendToChatbot },
      // ]);
      // const playResponse = await _fetchWithRetries(
      //   `${DID_API_URL}/${DID_TALK_SERVICE}/streams/${streamId.current}`,
      //   {
      //     method: "POST",
      //     headers: {
      //       Authorization: `Basic ${DID_API_KEY}`,
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       script: {
      //         type: "text",
      //         input: scriptToSendToChatbot,
      //         subtitles: "false",
      //         provider: {
      //           type: "microsoft",
      //           voice_id: "en-US-JennyNeural",
      //           voice_config: {
      //             style: "Cheerful",
      //           },
      //         },
      //         ssml: "false",
      //       },
      //       config: { fluent: "false", pad_audio: "0.0" },
      //       audio_optimization: "2",
      //       session_id: sessionId.current,
      //     }),
      //   }
      // );

      const playResponse = await _fetchWithRetries(
        `${DID_API_URL}/agents/${DID_AGENT_ID}/chat/${chatId.current}`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${DID_API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            streamId: streamId.current,
            sessionId: sessionId.current,
            messages: newMessages.map(({text,sender, id})=>{
              return {
                content: text,
                role: "user",
                created_at: new Date(id).toISOString(),
              }
            })
          }),
        }
      )
        setActiveMessage(null);
        setChatbotState(CHATBOT_STATE.READY);
      return playResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  const destroyChatbot = async () => {
    if (!streamId.current) return null;
    await fetch(
      `${DID_API_URL}/${DID_TALK_SERVICE}/streams/${streamId.current}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Basic ${DID_API_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: sessionId.current }),
      }
    );

    // await _deleteThread();

    _closePeerConnection();
  };

  function _onIceGatheringStateChange() {
    // iceGatheringStatusLabel.innerText = peerConnection.iceGatheringState;
    // iceGatheringStatusLabel.className =
    //   "iceGatheringState-" + peerConnection.iceGatheringState;
  }

  function _onIceCandidate(event) {
    if (event.candidate && streamId.current) {
      try {
        const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

        fetch(
          `${DID_API_URL}/${DID_TALK_SERVICE}/streams/${streamId.current}/ice`,
          {
            method: "POST",
            headers: {
              Authorization: `Basic ${DID_API_KEY}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              candidate,
              sdpMid,
              sdpMLineIndex,
              session_id: sessionId.current,
            }),
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  }

  function _onIceConnectionStateChange() {
    // iceStatusLabel.innerText = peerConnection.iceConnectionState;
    // iceStatusLabel.className =
    //   "iceConnectionState-" + peerConnection.iceConnectionState;
    if (
      peerConnecion.current?.iceConnectionState === "failed" ||
      peerConnecion.current?.iceConnectionState === "closed"
    ) {
      _closePeerConnection();
    }
  }

  function _onConnectionStateChange() {
    // not supported in firefox
    // peerStatusLabel.innerText = peerConnection.connectionState;
    // peerStatusLabel.className =
    //   "peerConnectionState-" + peerConnection.connectionState;
  }

  function _onSignalingStateChange() {
    // signalingStatusLabel.innerText = peerConnection.signalingState;
    // signalingStatusLabel.className =
    //   "signalingState-" + peerConnection.signalingState;
  }

  function _onVideoStatusChange(videoIsPlaying, stream) {
    let status;
    clearTimeout(timeoutStream.current);
    if (videoIsPlaying) {
      status = "streaming";
      setStream(stream);
    }else{
      timeoutStream.current = setTimeout(() => {
        setStream(null);
      }, 1000);
      // setStream(null);
    }
    // streamingStatusLabel.innerText = status;
    // streamingStatusLabel.className = "streamingState-" + status;
  }

  function _onTrack(event) {
    /**
     * The following code is designed to provide information about wether currently there is data
     * that's being streamed - It does so by periodically looking for changes in total stream data size
     *
     * This information in our case is used in order to show idle video while no video is streaming.
     * To create this idle video use the POST https://api.d-id.com/talks (or clips) endpoint with a silent audio file or a text script with only ssml breaks
     * https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag
     * for seamless results use `config.fluent: true` and provide the same configuration as the streaming video
     */
    console.log("track event", event);
    if (!event.track) return;

    statsIntervalId.current = setInterval(async () => {
      try{
        const stats = await peerConnecion.current?.getStats(event.track);
        stats?.forEach((report) => {
          if (report.type === "inbound-rtp" && report.mediaType === "video") {
            const videoStatusChanged =
              videoIsPlaying !== report.bytesReceived > lastBytesReceived.current;
            if (videoStatusChanged) {
              videoIsPlaying = report.bytesReceived > lastBytesReceived.current;
              _onVideoStatusChange(videoIsPlaying, event.streams[0]);
            }
            lastBytesReceived.current = report.bytesReceived;
          }
        });
      }catch(e){
        console.log("error getting stats", e);
      }
    
    }, 500);
  }

  async function _createPeerConnection(offer, iceServers) {
    let tempPeerConnection = peerConnecion.current;
    if (!peerConnecion.current) {
      tempPeerConnection = new RTCPeerConnection({ iceServers });
      tempPeerConnection.addEventListener(
        "icegatheringstatechange",
        _onIceGatheringStateChange,
        true
      );
      tempPeerConnection.addEventListener(
        "icecandidate",
        _onIceCandidate,
        true
      );
      tempPeerConnection.addEventListener(
        "iceconnectionstatechange",
        _onIceConnectionStateChange,
        true
      );
      tempPeerConnection.addEventListener(
        "connectionstatechange",
        _onConnectionStateChange,
        true
      );
      tempPeerConnection.addEventListener(
        "signalingstatechange",
        _onSignalingStateChange,
        true
      );
      tempPeerConnection.addEventListener("track", _onTrack, true);
    }

    await tempPeerConnection.setRemoteDescription(offer);
    console.log("set remote sdp OK");

    const clientAnswer = await tempPeerConnection.createAnswer();
    console.log("create local sdp OK");

    await tempPeerConnection.setLocalDescription(clientAnswer);
    console.log("set local sdp OK");

    peerConnecion.current = tempPeerConnection;

    return clientAnswer;
  }

  function _closePeerConnection(pc = peerConnecion.current) {
    if (!pc) return;
    console.log("stopping peer connection");
    pc.close();
    pc.removeEventListener(
      "icegatheringstatechange",
      _onIceGatheringStateChange,
      true
    );
    pc.removeEventListener("icecandidate", _onIceCandidate, true);
    pc.removeEventListener(
      "iceconnectionstatechange",
      _onIceConnectionStateChange,
      true
    );
    pc.removeEventListener(
      "connectionstatechange",
      _onConnectionStateChange,
      true
    );
    pc.removeEventListener(
      "signalingstatechange",
      _onSignalingStateChange,
      true
    );
    pc.removeEventListener("track", _onTrack, true);
    clearInterval(statsIntervalId.current);
    // iceGatheringStatusLabel.innerText = "";
    // signalingStatusLabel.innerText = "";
    // iceStatusLabel.innerText = "";
    // peerStatusLabel.innerText = "";
    console.log("stopped peer connection");
    if (pc === peerConnecion.current) {
      peerConnecion.current = null;
    }

    setChatbotState(CHATBOT_STATE.NOT_INITIATED);
  }

  async function _fetchWithRetries(url, options, retries = 1) {
    try {
      return await fetch(url, options);
    } catch (err) {
      if (retries <= MAX_RETRY_COUNT) {
        const delay =
          Math.min(Math.pow(2, retries) / 4 + Math.random(), MAX_DELAY_SEC) *
          1000;

        await new Promise((resolve) => setTimeout(resolve, delay));

        console.log(
          `Request failed, retrying ${retries}/${MAX_RETRY_COUNT}. Error ${err}`
        );
        return _fetchWithRetries(url, options, retries + 1);
      } else {
        throw new Error(`Max retries exceeded. error: ${err}`);
      }
    }
  }

  const _initThread = async () => {
    try {
      const myThread = await openai.beta.threads.create();
      // setCurrentChatThreadId(myThread.id);
      currentChatThreadId.current = myThread.id;
      console.log("Thread created successfully.", myThread.id);
    } catch (error) {
      console.error(error);
    }
  };

  const _deleteThread = async () => {
    try {
      const myThread = await openai.beta.threads.del(
        currentChatThreadId.current
      );
      // setCurrentChatThreadId(myThread.id);
      currentChatThreadId.current = null;
      console.log("Thread deleted successfully.", myThread.id);
    } catch (error) {
      console.error(error);
    }
  };

  const _getAnswerFromAI = async (customerQuery) => {
    try {
      const chatThreadMessage = await openai.beta.threads.messages.create(
        currentChatThreadId.current,
        {
          role: "user",
          content: customerQuery,
        }
      );

      // console.log("Message added to the Thread", chatThreadMessage.);
      currentChatId.current = chatThreadMessage.id;

      const aiRunInfo = await openai.beta.threads.runs.create(
        currentChatThreadId.current,
        {
          assistant_id: OPENAI_ASSITANT_ID,
          
        }
      );

      currentAIRunInfo.current = aiRunInfo;

      console.log("AI Run creatd successfully.", aiRunInfo);
    } catch (error) {
      console.error(error);
    }
  };

  const _retrieveAIRun = async () => {
    console.log("Retrieving AI Run", currentAIRunInfo);
    if (!currentAIRunInfo.current) return "";
    let keepRetrievingRun;
    let generatedChatBotScript = "";

    while (
      currentAIRunInfo.current.status === "queued" ||
      currentAIRunInfo.current.status === "in_progress"
    ) {
      keepRetrievingRun = await openai.beta.threads.runs.retrieve(
        currentChatThreadId.current,
        currentAIRunInfo.current.id
      );

      console.log(`Run status: ${keepRetrievingRun.status}`);

      if (keepRetrievingRun.status === "completed") {
        console.log("\n");

        // Step 7: Retrieve the Messages added by the Assistant to the Thread
        const allMessages = await openai.beta.threads.messages.list(
          currentChatThreadId.current,
          {
            limit: 1
          }
        );

        console.log(
          "------------------------------------------------------------ \n"
        );

        console.log(
          "User: ",
          // myThreadMessage.content[0].text.value,
          allMessages
        );
        console.log("Assistant: ", allMessages.data[0].content[0].text.value);
        generatedChatBotScript = allMessages.data[0].content[0].text.value;

        setActiveMessage({
          isFinal: true,
          text: generatedChatBotScript,
        });

        break;
      } else if (
        keepRetrievingRun.status === "queued" ||
        keepRetrievingRun.status === "in_progress"
      ) {
        // get current message from the thread
        // get stream message from the thread

        setActiveMessage((m) => {
          let a = m?.text || "";
          if (a === "") {
            return {
              isFinal: false,
              text: "Please wait, I am thinking",
            };
          } else {
            const last3 = a.slice(-3);
            if (last3 === "...") {
              return {
                isFinal: false,
                text: a.slice(0, -3),
              };
            }
            return {
              isFinal: false,
              text: a + ".",
            };
          }
          
        });
        // pass
      } else {
        console.log(`Run status: ${keepRetrievingRun.status}`);
        break;
      }
    }

    return generatedChatBotScript;
  };

  // function setVideoElement(stream) {
  //   if (!stream) return;
  //   videoElement.srcObject = stream;
  //   videoElement.loop = false;

  //   // safari hotfix
  //   if (videoElement.paused) {
  //     videoElement
  //       .play()
  //       .then((_) => {})
  //       .catch((e) => {});
  //   }
  // }

  // function playIdleVideo() {
  //   videoElement.srcObject = undefined;
  //   videoElement.src = "or_idle.mp4";
  //   videoElement.loop = true;
  // }

  // function stopAllStreams() {
  //   if (videoElement.srcObject) {
  //     console.log("stopping video streams");
  //     videoElement.srcObject.getTracks().forEach((track) => track.stop());
  //     videoElement.srcObject = null;
  //   }
  // }

  useEffect(() => {
    connectChatbot();

    return () => {
      destroyChatbot();
    };
  }, []);

  // const closeChatbot = () => {
  //   // destroyChatbot();
  //   // setStream(null);
  //   destroyChatbot();
  // };

  return {
    chatbotState,
    stream,
    messages,
    activeMessage,
    talkToChatbot,
    customerSatisfaction,
    resetCustomerSatisfaction,
  };
};

export default useChatBot;
