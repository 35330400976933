import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/GROTrackTurquoise.png";
import LessThanIcon from "../assets/images/lessThanIcon.svg";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";
import Iframe from "react-iframe";
import { useUserContext } from "../contexts/userContext";
import { getLogo } from "../utlis/themeHelpers";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

export default function AdminMedication() {
  // const [message, setMessage] = useState("");
  // const [success, setSuccess] = useState("");
  const { isSalonApp } = useUserContext();

  const message = "";
  const success = "";
  const [ready, setReady] = useState(false);
  const [documents, setDocuments] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showSignForm, setShowSignForm] = useState(false);
  const { translate }  = useTranslation();
  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/clinic/documents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setDocuments(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setReady(true);
      });
  };

  const download = (doc) => {
    window.open(doc, "_blank");
  };

  const view = (name, link) => {
    setSelectedDocument([name, link]);
  };

  const hide = () => {
    setSelectedDocument(null);
  };

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-5 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}
      >
        <AdminHeader />

        {message && (
          <h1 className="mt-8 text-center text-red-600">{message}</h1>
        )}
        {success && (
          <h1 className="mt-8 text-center text-green-600">{success}</h1>
        )}
        <section className="flex px-5">
          <AdminSidebar />
          {!selectedDocument ? (
            <div className="mt-20 mx-auto">
              <div className="flex justify-center">
                <h1 className="text-2xl text-text-2 font-font-2 font-bold text-center tracking-widest">
                  {translate('practice_agreements')}
                </h1>
                <svg
                  class="ml-4 w-6 h-6 text-text-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5"
                    d="M8 12V1m0 0L4 5m4-4 4 4m3 5v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                  />
                </svg>
              </div>
              <div className="grid grid-cols-2 mt-8 gap-32">
                <div className="w-full text-center mb-4 bg-background-4 opacity-90">
                  <h1 className="font-bold border-b-2 text-lg border-text-1 text-black py-2">
                    GRO {isSalonApp ? translate('salon') : ""} {translate('user_agreement')}{" "}
                  </h1>
                  <div className="flex justify-between px-12 py-4">
                    <div className="flex items-center">
                      <svg
                        onClick={() =>
                          view(
                            `GRO ${isSalonApp ? "salon" : ""} user agreement`,
                            documents?.user_agreement_pdf
                          )
                        }
                        className="w-7 h-7 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />{" "}
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />{" "}
                      </svg>
                      <svg
                        onClick={() => download(documents?.user_agreement_pdf)}
                        class="w-10 h-6 ml-6 text-text-1 cursor-pointer"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 18"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                        />
                      </svg>
                    </div>
                    <div
                      className="w-fit py-2 px-8 ml-6 text-xs font-normal text-text-3 rounded-md"
                      style={{ background: "#EF5656" }}
                    >
                      {translate('sign')}
                    </div>
                  </div>
                </div>
                <div className="w-full text-center mb-4 bg-background-4 opacity-90">
                  <h1 className="font-bold border-b-2 text-lg border-text-1 text-black py-2">
                    HIPAA BAA
                  </h1>
                  <div className="flex justify-between px-12 py-4">
                    <div className="flex items-center">
                      <svg
                        onClick={() =>
                          view(
                            "HIPAA agreement",
                            documents?.hipaa_agreement_pdf
                          )
                        }
                        className="w-7 h-7 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />{" "}
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />{" "}
                      </svg>
                      <svg
                        onClick={() => download(documents?.hipaa_agreement_pdf)}
                        class="w-10 h-6 cursor-pointer ml-6 text-text-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 18"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                        />
                      </svg>
                    </div>
                    <div className="w-fit py-2 px-8 ml-6 text-xs font-normal text-text-3 rounded-md bg-accent-6">
                      {translate('signed')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-20">
                <h1 className="text-2xl text-text-2 font-font-2 font-bold text-center tracking-widest">
                  {isSalonApp ? "CLIENT FORM TEMPLATES" : "PATIENT AGREEMENTS"}
                </h1>
                <svg
                  class="ml-4 w-6 h-6 text-text-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2.5"
                    d="M8 12V1m0 0L4 5m4-4 4 4m3 5v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                  />
                </svg>
              </div>
              <div className="grid grid-cols-2 mt-8 gap-32">
                <div className="w-full text-center mb-4 bg-background-4  opacity-90">
                  <h1 className="font-bold text-lg border-b-2 border-text-1 text-black py-2">
                    {isSalonApp
                      ? "Hydrafacial consent form"
                      : "Alma TED consent form"}
                  </h1>
                  <div className="flex justify-between px-12 py-4">
                    <div className="flex items-center">
                      <svg
                       onClick={() =>
                        view(
                          isSalonApp
                            ? "Hydrafacial consent form"
                            : "Alma TED consent form",
                          documents?.user_agreement_pdf
                        )
                      }
                        className="w-6 h-6 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />{" "}
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />{" "}
                      </svg>
                      <svg
                        onClick={() => download(documents?.user_agreement_pdf)}
                        class="w-10 h-6 ml-6 text-text-1 cursor-pointer"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 18"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                        />
                      </svg>
                    </div>
                    <div
                      className="w-fit py-2 px-4 ml-6 text-xs font-normal text-text-3 rounded-md"
                      style={{ background: "#EF5656" }}
                    >
                      {translate('activate')}
                    </div>
                  </div>
                </div>
                <div className="w-full text-center mb-4 bg-background-4  opacity-90">
                  <h1 className="font-bold border-b-2 text-lg border-text-1 text-black py-2">
                    {isSalonApp
                      ? "Scalp therapy consent form"
                      : translate('rpr_title')}
                  </h1>
                  <div className="flex justify-between px-12 py-4">
                    <div className="flex items-center">
                      <svg
                      onClick={() =>
                        view(
                          isSalonApp
                      ? "Scalp therapy consent form"
                      : translate('rpr_title'),
                          documents?.hipaa_agreement_pdf
                        )
                      }
                        className="w-6 h-6 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        {" "}
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />{" "}
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />{" "}
                      </svg>
                      <svg
                        onClick={() => download(documents?.hipaa_agreement_pdf)}
                        class="w-10 h-6 ml-6 text-text-1 cursor-pointer"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 18"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.5"
                          d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                        />
                      </svg>
                    </div>
                    <div className="w-fit py-2 px-8 ml-6 text-xs font-normal text-text-3 rounded-md bg-accent-6">
                      {translate('active')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full mt-8 p-4">
              <div
                className="flex px-12 py-4 m-auto mb-2 items-center justify-end"
                style={{ width: "80%" }}
              >
                <div
                  className="flex items-center justify-between"
                  style={{ width: "85%" }}
                >
                  <img
                    onClick={hide}
                    className="w-8 h-8 cursor-pointer"
                    src={LessThanIcon}
                    alt="icon"
                  />
                  <div className="text-2xl text-text-3 mx-6 tracking-widest">
                    {selectedDocument[0]}
                  </div>
                  <svg
                    class="w-10 h-7 text-text-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 18"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2.5"
                      d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"
                    />
                  </svg>
                  <svg
                    class="w-10 h-8 text-text-2"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      fill="currentColor"
                      d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"
                    />
                  </svg>
                  <div
                    onClick={() => setShowSignForm(!showSignForm)}
                    className="w-fit py-2 px-10 ml-6 text-xs font-normal text-text-3 rounded-md cursor-pointer font-bold tracking-wider"
                    style={{ background: "#EF5656", textTransform: "uppercase" }}
                  >
                    {showSignForm ? translate('cancel') : translate('sign')}
                  </div>
                </div>
              </div>
              <div>
                {!showSignForm ? (
                  <Iframe
                    className="m-auto"
                    url={selectedDocument[1]}
                    width="80%"
                    height="570px"
                    display="block"
                  />
                ) : (
                  <div
                    className="m-auto bg-text-2 flex flex-col justify-center items-center"
                    style={{ width: "80%", height: "570px" }}
                  >
                    <textarea className="w-8/12 h-40 px-3 bg-text-3 focus:outline-none input-shadow" />
                    <div className="w-8/12 flex mt-4 h-8">
                      <input className="bg-text-3 w-5/12 px-3 text-lg text-text-1 font-bold tracking-widest focus:outline-none input-shadow" />
                      <input className="bg-text-3 w-1/4 ml-6 px-3 text-lg text-text-1 font-bold tracking-widest focus:outline-none input-shadow" />
                      {/* <input className="bg-text-3 w-1/4 focus:outline-none input-shadow" /> */}
                    </div>
                    <div className="bg-background-23 mt-6 w-fit py-2 px-14 text-xs font-normal text-text-3 rounded-md tracking-wider">
                      {translate('confirm')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
      </main>
      <Loading message={translate('loading_document')} ready={ready} />
    </>
  );
}
