import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

function EmbeddedYoutubePlayer({ videoId, start, end }) {

  return (
    <div className="flex flex-row sm:flex-col">
        <div className="w-full h-full">
            <iframe
                className="w-full h-full"
                src={`https://www.youtube.com/embed/${videoId}?start=${start}&end=${end}&autoplay=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3`}
                frameBorder="0"
                allowFullScreen
                title="YouTube video player"
                style={{ height: '640px' }}
            ></iframe>
        </div>
    </div>
  );
}

export default EmbeddedYoutubePlayer;
