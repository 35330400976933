import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PresentationNavbar from '../components/PresentationNavbar';
import HairDensityTypes from '../components/HairDensityTypes';
import dayjs from 'dayjs';
import { useTranslation } from "../contexts/TranslationContext";

const areas = ['Front', 'Crown', 'Vertex', 'Left', 'Right'];

// const createHairDensityAnalysis = () => {
//     const entries = 3;
//     let id = 1;
//     const areas = ["Front", "Crown", "Vertex", "Back", "Right", "Left"];
//     const imgs = [
//         "https://ik.imagekit.io/visceailxwt/img1_2-PHDAXWRL.PNG",
//         "https://ik.imagekit.io/visceailxwt/img2_N7ht64SknF.PNG",
//         "https://ik.imagekit.io/visceailxwt/img3_LLCvMnG8Xe.PNG",
//     ];
//     const hairAnalysis = [];
//     for (let i = 0; i < entries; i++) {
//         for (let j = 0; j < areas.length; j++) {
//             hairAnalysis.push({
//                 id: id++,
//                 area: areas[j],
//                 date: `${17 + i}-11-2020`,
//                 // img: imgs[(Math.random() * imgs.length).toFixed()],
//                 // All: (Math.random() * 80 + 100).toFixed(0),
//                 // T: (Math.random() * 80 + 100).toFixed(0),
//                 // V: (Math.random() * 80 + 100).toFixed(0),
//                 // TV: (Math.random() * 80 + 100).toFixed(0),
//                 // FU: (Math.random() * 80 + 100).toFixed(0),
//             });
//         }
//     }
//     return hairAnalysis;
// };

export default function HairDensityTracking() {
  const { id } = useParams();
  const { translate } = useTranslation();
  const [message, setMessage] = useState('');
  const [ready, setReady] = useState(false);
  // const [report_process, setReportProcess] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [hairAnalysis, setHairAnalysis] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [patientReferrals, setPatientReferrals] = useState(null);
  //   const [hairDensityAnalysis, setHairDensityAnalysis] = useState(null);
  const [area, setArea] = useState('Front');
  const [refImg, setRefImg] = useState('');

  //   const currentAnalysis = () => {
  //     return hairDensityAnalysis.filter((analysis) => {
  //       return analysis.area === area;
  //     });
  //   };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/hair_analysis/all?patient_id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setFirstName(response.firstName);
          setLastName(response.lastName);
          setPatientReferrals(response.referral);
          //   setHairDensityAnalysis(createHairDensityAnalysis());
          setHairAnalysis(response.hair_analysis);
          setRefImg(response.groscale_baseimg.image_path);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);

  // const downloadReport = () => {
  //   setReportProcess(false);
  //   fetch(`${process.env.REACT_APP_API}/hair_analysis/${hairAnalysis[currentId].id}/report`, {
  //     method: 'GET',
  //     headers: {
  //        'Content-Type': 'application/pdf',
  //        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  //     },
  //  })
  //  .then((response) => response.blob())
  //  .then((blob) => {
  //     // Create blob link to download
  //     const url = window.URL.createObjectURL(
  //        new Blob([blob]),
  //     );
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute(
  //        'download',
  //        `${firstName} ${lastName} ${dayjs
  //         .unix(hairAnalysis[currentId].created_at)
  //         .format('MM-DD-YY')}.pdf`
  //     );

  //     // Append to html link element page
  //     document.body.appendChild(link);

  //     // Start download
  //     link.click();

  //     // Clean up and remove the link
  //     link.parentNode.removeChild(link);
  //  }).finally(() => {
  //   setReportProcess(true);
  // });
  // };

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        } `}
      >
        <PatientNavbar id={id} tab={6} />
        {message && <p className="my-8 text-center text-red-600">{message}</p>}
        <section>
          

          <div
            className="p-6 mt-4 bg-background-5 overflow-hidden"
            // style={{ height: 'var(--height-2)' }}
          >
            {patientReferrals ? (
            <h1 className="px-12 flex  justify-center my-3 text-lg text-center text-text-4">
              {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
            </h1>
          ) : (
            <h1 className="px-12 flex  justify-center my-3 text-lg text-center text-text-4">
              {`${firstName} ${lastName}`}
            </h1>
          )}

          <div className="flex items-center md:justify-evenly mb-4 flex-col md:flex-row">
            <div className=" flex items-center">
              <button
                className="font-bold text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId > 0) {
                    setCurrentId(currentId - 1);
                  }
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="#bfc5ca"
                  strokeWidth="1.25"
                >
                  <path
                    fill="#bfc5ca"
                    d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                  />
                </svg>
              </button>
              <p className="mx-4 text-text-8">
                {hairAnalysis[currentId]
                  ? dayjs
                      .unix(hairAnalysis[currentId].created_at)
                      .format('MM-DD-YY')
                  : ''}
              </p>
              <button
                className="font-bold text-2xl focus:outline-none"
                onClick={function () {
                  if (currentId + 1 < hairAnalysis.length) {
                    setCurrentId(currentId + 1);
                  }
                }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="#bfc5ca"
                  strokeWidth="1.25"
                >
                  <path
                    fill=" #bfc5ca"
                    d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                  />
                </svg>
              </button>
            </div>
          </div>
            <div className="flex" style={{ position: 'relative' }}>
              <div
                className="md:absolute flex items-center justify-center"
                style={{ right: '0', left: '0' }}
              >
                <h2 className="ml-2 mb-2 " style={{ fontSize: '22px' }}>
                  {translate('gro_scale')}
                </h2>
              </div>
              <div
                className="mt-6 flex items-center justify-center mx-auto font-font-2 text-text-8"
                style={{ marginTop: '40px' }}
              >
                <button
                  className={`${
                    area === 'Front' && 'text-text-7'
                  } focus:outline-none mx-2`}
                  onClick={() => {
                    setArea('Front');
                  }}
                >
                  {translate('front')}
                </button>
                <button
                  className={`${
                    area === 'Crown' && 'text-text-7'
                  } focus:outline-none mx-2`}
                  onClick={() => {
                    setArea('Crown');
                  }}
                >
                  {translate('crown')}
                </button>
                <button
                  className={`${
                    area === 'Vertex' && 'text-text-7'
                  } focus:outline-none mx-2`}
                  onClick={() => {
                    setArea('Vertex');
                  }}
                >
                  {translate('vertex')}
                </button>
                <button
                  className={`${
                    area === 'Left' && 'text-text-7'
                  } focus:outline-none mx-2`}
                  onClick={() => {
                    setArea('Left');
                  }}
                >
                  {translate('left')}
                </button>
                <button
                  className={`${
                    area === 'Right' && 'text-text-7'
                  } focus:outline-none mx-2`}
                  onClick={() => {
                    setArea('Right');
                  }}
                >
                  {translate('right')}
                </button>
              </div>
            </div>
            {/* <div className="text-center mt-3">
              {hairAnalysis[currentId] && <>
                <button onClick={() => {downloadReport()}} target="_blank" rel="noreferrer" className={`text-right`}>
                  Download Report
                </button>
              </>}
            </div> */}

            {/* {currentAnalysis().length > 0 ? (
                            <HairDensityTypes patientId={id} />
                        ) : (
                            <h2 className="mt-4 text-center font-font-2">
                                No Analysis Present
                            </h2>
                        )} */}

            {hairAnalysis.length && (
              <HairDensityTypes
                cur={currentId}
                area={area}
                analysis={hairAnalysis}
                index={areas.indexOf(area)}
                refImg={refImg}
              />
            )}
          </div>

          <PresentationNavbar id={id} tab={4} />
        </section>
      </main>
      <Loading message={translate('loading_gro_tracker')} ready={ready} />
      {/* <Loading message={'Downloading Report'} ready={report_process} /> */}
    </>
  );
}
