import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useQueryParam = (queryString) => {
    const {search} = useLocation();

    return useMemo(() => {
        const searchParam = new URLSearchParams(search);
        return searchParam.get(queryString);
    }, [search]);
}

export default useQueryParam