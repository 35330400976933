import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../views/Loading';
import PatientNavbar from '../components/PatientNavbar';
import PatientSidebar from '../components/PatientSidebar';
import { useUserContext } from '../contexts/userContext';
import { useTranslation } from '../contexts/TranslationContext';

export default function PhysicalMedicalExam() {
  const [editMode, setEditMode] = useState(false);
  const [examDrop, setExamDrop] = useState(true);
  const [healthHistoryDrop, setHealthHistoryDrop] = useState(false);
  const [healthDropdown, setHealthDropdown] = useState(false);
  const [ready, setReady] = useState(false);
  const { id } = useParams();
  const [physicalInfo, setPhysicalInfo] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editPhysicalInfo, setEditPhysicalInfo] = useState(null);
  const [message, setMessage] = useState('');
  const [patientReferrals, setPatientReferrals] = useState('');
  const {isSalonApp} = useUserContext()
  const { translate } = useTranslation()
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/name`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          console.log('RES', response);
          setPatientReferrals(response.referral);
          setFirstName(response.firstName);
          setLastName(response.lastName);
        } else {
          setMessage(response.message);
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [id]);
  const isPhysicalInfoValid = () => {
    return true;
  };

  const isExamOpen = () => {
    return true;
  };
  const handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setEditPhysicalInfo({
      ...editPhysicalInfo,
      [name]: value,
    });
  };

  const savePhysicalInfo = () => {
    if (isPhysicalInfoValid()) {
      setReady(false);
      fetch(
        `${process.env.REACT_APP_API}/patient/${id}/physical_medical_exam`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          body: JSON.stringify(editPhysicalInfo),
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            setPhysicalInfo(response.data);
            setEditMode(false);
          } else {
            setMessage(response.message);
          }
          setReady(true);
        })
        .catch(error => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/patient/${id}/physical_medical_exam`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setEditPhysicalInfo({ ...response.data });
          setPhysicalInfo(response.data);
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch(error => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, [id]);
  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-4 ${
          ready ? '' : 'hidden'
        }`}
      >
        <PatientNavbar id={id} tab={1} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        <section className="flex">
          <PatientSidebar
            examDrop={examDrop}
            setExamDrop={setExamDrop}
            historyDrop={healthHistoryDrop}
            setHistoryDrop={setHealthHistoryDrop}
            healthDrop={healthDropdown}
            setHealthDrop={setHealthDropdown}
            isExamOpen={isExamOpen}
            id={id}
          />
          {physicalInfo && (
            <div className="mx-auto max-w-5xl">
              {patientReferrals === null ? (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${firstName} ${lastName}`}
                </h1>
              ) : (
                <h1 className="px-12 flex  justify-center mt-4 text-lg text-center text-text-4">
                  {`${firstName} ${lastName} ${translate('referred_by')} ${patientReferrals.referred_by}`}
                </h1>
              )}
              <form
                onSubmit={event => {
                  event.preventDefault();
                  savePhysicalInfo();
                }}
                className="lg:mx-16 flex flex-col"
              >
                <div className="mt-4 flex justify-between items-center">
                  <button
                    type="button"
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  >
                    {editMode ? 'Cancel' : 'Edit'}
                  </button>
                  <p className="mx-4">{translate('exam')}</p>
                  <button
                    className="px-8 py-1 bg-background-23 rounded-lg text-text-3
                    cursor-pointer focus:outline-none"
                    disabled={!editMode}
                  >
                    {translate('save')}
                  </button>
                </div>
                <section className="mt-8">
                  <p className="text-xl text-text-2">{translate('physical_and_medical_exam')}</p>
                  <div className="mt-6 border-t-2 border-background-2 pt-4">
                    <div className="grid mt-8 grid-cols-4 items-center gap-8">
                      <p>{translate('weight_height')}</p>
                      <input
                        type="text"
                        value={
                          editMode
                            ? editPhysicalInfo.weight
                            : physicalInfo.weight
                        }
                        disabled={!editMode}
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder="Weight"
                        name="weight"
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        value={
                          editMode
                            ? editPhysicalInfo.height
                            : physicalInfo.height
                        }
                        disabled={!editMode}
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder="Height"
                        name="height"
                        onChange={e => handleInputChange(e)}
                      />
                      <div className="flex justify-center items-center">
                        <select
                          disabled={!editMode}
                          value={
                            editMode
                              ? editPhysicalInfo.weight_height_baseline
                              : physicalInfo.weight_height_baseline
                          }
                          onChange={e => handleInputChange(e)}
                          name="weight_height_baseline"
                          className="w-1/2 cursor-pointer bg-background-1 text-center text-text-1 rounded-sm appearance-none focus:outline-none"
                        >
                          <option value="baseline">{translate('baseline')}</option>
                          <option value="3 Months">{translate('3_months')}</option>
                          <option value="6 Months">{translate('6_months')}</option>
                        </select>
                        <svg
                          className="mt-1 fill-current text-accent-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 30 30"
                        >
                          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </svg>
                      </div>
                      <p>{translate('bmi')}</p>
                      <input
                        type="text"
                        value={
                          editMode ? editPhysicalInfo.bmi : physicalInfo.bmi
                        }
                        disabled={!editMode}
                        className="w-full px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder="BMI"
                        name="bmi"
                        onChange={e => handleInputChange(e)}
                      />
                      <div className="flex justify-center items-center">
                        <select
                          disabled={!editMode}
                          name="bmi_baseline"
                          value={
                            editMode
                              ? editPhysicalInfo.bmi_baseline
                              : physicalInfo.bmi_baseline
                          }
                          onChange={e => handleInputChange(e)}
                          className="w-1/2 cursor-pointer bg-background-1 text-center text-text-1 rounded-sm appearance-none focus:outline-none"
                        >
                          <option value="baseline">{translate('baseline')}</option>
                          <option value="3 Months">{translate('3_months')}</option>
                          <option value="6 Months">{translate('6_months')}</option>
                        </select>
                        <svg
                          className="mt-1 fill-current text-accent-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 30 30"
                        >
                          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </svg>
                      </div>
                      <div></div>
                      <p className="col-span-1">{translate('blood_pressure_pulse')}</p>
                      <input
                        type="text"
                        value={
                          editMode
                            ? editPhysicalInfo.blood_pressure
                            : physicalInfo.blood_pressure
                        }
                        disabled={!editMode}
                        className="w-full col-span-2 px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate('blood_pressure')}
                        name="blood_pressure"
                        onChange={e => handleInputChange(e)}
                      />
                      <input
                        type="text"
                        value={
                          editMode ? editPhysicalInfo.pulse : physicalInfo.pulse
                        }
                        disabled={!editMode}
                        className="w-full col-span-1 px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder="Pulse"
                        name="pulse"
                        onChange={e => handleInputChange(e)}
                      />
                    </div>
                    <div className="pt-4 mt-3 grid sm:grid-cols-4 gap-8">
                      <p>{translate('ear_nose_and_throat')}</p>
                      <input
                        type="text"
                        value={
                          editMode
                            ? editPhysicalInfo.ear_nose_throat
                            : physicalInfo.ear_nose_throat
                        }
                        disabled={!editMode}
                        className="w-full col-span-3 px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate('ear_nose_and_throat')}
                        name="ear_nose_throat"
                        onChange={e => handleInputChange(e)}
                      />
                      <p>{translate('skin_lymph_notes')}</p>
                      <input
                        type="text"
                        value={
                          editMode
                            ? editPhysicalInfo.skin_lymph_notes
                            : physicalInfo.skin_lymph_notes
                        }
                        disabled={!editMode}
                        className="w-full col-span-3 px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate('skin_lymph_notes')}
                        name="skin_lymph_notes"
                        onChange={e => handleInputChange(e)}
                      />
                      <p>{translate('heart_lung')}</p>
                      <input
                        type="text"
                        value={
                          editMode
                            ? editPhysicalInfo.heart_lung
                            : physicalInfo.heart_lung
                        }
                        disabled={!editMode}
                        className="w-full col-span-3 px-2 py-1 bg-background-2 text-center text-text-2 rounded-sm focus:outline-none"
                        placeholder={translate('heart_lung')}
                        name="heart_lung"
                        onChange={e => handleInputChange(e)}
                      />
                    </div>
                  </div>
                </section>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading
        message={`${translate('loading')} ${isSalonApp ? translate("client"):  translate('patient')} ${translate('physical_medical_exams')}`}
        ready={ready}
      />
    </>
  );
}
