import { CardElement } from '@stripe/react-stripe-js';
import React from 'react'
import DotGrey from "../../assets/images/DotGrey.svg";
import DotTurquoise from "../../assets/images/DotTurquoise.svg";
import { useTranslation } from '../../contexts/TranslationContext';

const CardForm = ({saveCard, editCardMode, editCard, setEditCard, card, setEditCardMode}) => {
    const { translate } = useTranslation();
    return (
        <form
            className="mt-14 flex flex-col"
            onSubmit={(event) => {
                event.preventDefault();
                saveCard();
            }}
        >
            <div className=" pl-10 flex justify-center items-center">
                <p className="text-lg text-text-2 uppercase tracking-widest">{translate('payment_method')}</p>
            </div>
            <div className="mx-auto mt-5 flex flex-col items-end text-text-2 text-sm">
                <div
                    className={`w-full md:w-96 px-3 ${editCardMode ? "" : "hidden"
                        }`}
                >
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: "20px",
                                    color: "#bfc5ca",
                                    "::placeholder": {
                                        color: "#aab7c4",
                                    },
                                },
                                invalid: {
                                    color: "#9e2146",
                                },
                            },
                        }}
                    />
                </div>
                {!editCardMode && (
                    <>
                        <label className="mt-5 flex items-center">
                            <p className="ml-2 mr-3">{translate('card_number')}</p>
                            <input
                                type="text"
                                className="ml-6 w-40 md:w-96 px-3 placeholder-text-2 py-1 bg-background-22 font-bold text-text-2 rounded-xs focus:outline-none"
                                value={
                                    editCardMode ? editCard.cardNumber : card.cardNumber
                                }
                                disabled={!editCardMode}
                                onChange={(event) => {
                                    setEditCard({
                                        ...editCard,
                                        cardNumber: event.target.value,
                                    });
                                }}
                            />
                        </label>
                        <div className="mt-5 flex flex-col items-end md:flex-row">
                            <label className="flex items-center">
                                <p className="ml-2 mr-3">{translate('expires')}</p>
                                <input
                                    type="text"
                                    className="ml-6 md:w-46 font-bold w-40 placeholder-text-2  px-3 py-1 bg-background-22 text-text-2 rounded-xs focus:outline-none"
                                    value={editCardMode ? editCard.expires : card.expires}
                                    disabled
                                />
                            </label>
                            <label className="mt-5 md:mt-0 md:ml-5 flex items-center">
                                <p>CVV</p>
                                <input
                                    type="text"
                                    className="ml-5 w-40 md:w-32 font-bold px-3 py-1 placeholder-text-2  bg-background-22 text-text-2 rounded-xs focus:outline-none"
                                    value={editCardMode ? editCard.cvv : card.cvv}
                                    disabled
                                />
                            </label>
                        </div>
                    </>
                )}
                <div className="mt-5 flex">
                    <p className="ml-2 mr-3 mt-1">{translate('card_holder_name')}</p>
                    <div className="ml-6 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                        <label className="flex items-center">
                            <input
                                type="text"
                                className="w-full px-3 py-1 bg-background-22  placeholder-text-2 text-text-2 font-bold rounded-xs focus:outline-none"
                                value={
                                    editCardMode
                                        ? editCard.cardHolderFirstName
                                        : card.cardHolderFirstName
                                }
                                disabled={!editCardMode}
                                placeholder={translate('first_name')}
                                required
                                onChange={(event) => {
                                    setEditCard({
                                        ...editCard,
                                        cardHolderFirstName: event.target.value,
                                    });
                                }}
                            />
                        </label>
                        <label className="flex items-center">
                            <input
                                type="text"
                                className="w-full px-3 py-1 bg-background-22 placeholder-text-2  text-text-2 font-bold rounded-xs focus:outline-none"
                                value={
                                    editCardMode
                                        ? editCard.cardHolderLastName
                                        : card.cardHolderLastName
                                }
                                disabled={!editCardMode}
                                placeholder={translate('last_name')}
                                required
                                onChange={(event) => {
                                    setEditCard({
                                        ...editCard,
                                        cardHolderLastName: event.target.value,
                                    });
                                }}
                            />
                        </label>
                    </div>
                </div>
                <div className="flex mt-5 justify-center w-full items-center">
                    <img src={DotGrey} className="h-3 w-3 mr-3" />
                    <p className="text-text-2">{translate('same_as_practice_address')}</p>
                </div>
                <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('billing_address')}</p>
                    <input
                        type="text"
                        className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                        value={
                            editCardMode
                                ? editCard.billingAddress.street
                                : card.billingAddress.street
                        }
                        disabled={!editCardMode}
                        placeholder={translate('street')}
                        required
                        onChange={(event) => {
                            setEditCard({
                                ...editCard,
                                billingAddress: {
                                    ...editCard.billingAddress,
                                    street: event.target.value,
                                },
                            });
                        }}
                    />
                </label>
                <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                    value={
                        editCardMode
                            ? editCard.billingAddress.city
                            : card.billingAddress.city
                    }
                    disabled={!editCardMode}
                    placeholder={translate('city')}
                    required
                    onChange={(event) => {
                        setEditCard({
                            ...editCard,
                            billingAddress: {
                                ...editCard.billingAddress,
                                city: event.target.value,
                            },
                        });
                    }}
                />
                <div className="mt-5 grid gap-4 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                        <input
                            type="text"
                            className="w-full px-3 py-1 font-bold placeholder-text-2  bg-background-22 text-text-2 rounded-xs focus:outline-none"
                            value={
                                editCardMode
                                    ? editCard.billingAddress.state
                                    : card.billingAddress.state
                            }
                            disabled={!editCardMode}
                            placeholder={translate('state')}
                            required
                            onChange={(event) => {
                                setEditCard({
                                    ...editCard,
                                    billingAddress: {
                                        ...editCard.billingAddress,
                                        state: event.target.value,
                                    },
                                });
                            }}
                        />
                    </label>
                    <label className="flex items-center">
                        <input
                            type="text"
                            className="w-full px-3 py-1 bg-background-22 placeholder-text-2  text-text-2 font-bold rounded-xs focus:outline-none"
                            value={
                                editCardMode
                                    ? editCard.billingAddress.zip
                                    : card.billingAddress.zip
                            }
                            disabled={!editCardMode}
                            placeholder={translate('zip')}
                            required
                            onChange={(event) => {
                                setEditCard({
                                    ...editCard,
                                    billingAddress: {
                                        ...editCard.billingAddress,
                                        zip: event.target.value,
                                    },
                                });
                            }}
                        />
                    </label>
                </div>
                <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('phone')}</p>
                    <input
                        type="text"
                        className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 placeholder-text-2  text-text-2 font-bold rounded-xs focus:outline-none"
                        value={editCardMode ? editCard.phone : card.phone}
                        disabled={!editCardMode}
                        required
                        onChange={(event) => {
                            setEditCard({
                                ...editCard,
                                phone: event.target.value,
                            });
                        }}
                    />
                </label>
                <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('email')}</p>
                    <input
                        type="email"
                        className="ml-6 w-40 md:w-96 px-3 py-1 placeholder-text-2  bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                        value={editCardMode ? editCard.email : card.email}
                        disabled={!editCardMode}
                        required
                        onChange={(event) => {
                            setEditCard({
                                ...editCard,
                                email: event.target.value,
                            });
                        }}
                    />
                </label>
                <div className="w-full flex justify-center mt-8">

                    {editCardMode && (<button
                        type="submit"
                        className="px-8 py-1 mx-2 bg-button-5 rounded-lg text-text-3 font-bold text-sm
              cursor-pointer focus:outline-none"
                    >
                        {translate('save')}
                    </button>)}
                    <button
                        type="button"
                        className="px-8 mx-2 py-1 bg-button-5 rounded-lg text-text-3 font-bold text-sm
              cursor-pointer focus:outline-none"
                        onClick={() => {
                            setEditCardMode(!editCardMode);
                        }}
                    >
                        {editCardMode ? translate('cancel') : translate('update')}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default CardForm