import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/GROTrackTurquoise.png";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";
import { useUserContext } from "../contexts/userContext";
import { getChatBotLogo, getLogo } from "../utlis/themeHelpers";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

export default function AdminMedication() {
  // const [message, setMessage] = useState("");
  // const [success, setSuccess] = useState("");
  const { translate } = useTranslation()

  const message = "";
  const success = "";
  const [ready, setReady] = useState(false);
  const { isSalonApp } = useUserContext();

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-5 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}
      >
        <AdminHeader physician={{}} />
        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex px-5">
          <AdminSidebar />
          <div className="mx-auto max-w-2xl mt-8">
            <div className="flex flex-col items-center justify-start my-20">
              <div className="w-full text-center pb-12 border-b-2 border-gray-700 text-text-2 tracking-widest">
                <div className="text-2xl text-text-3 tracking-widest">
                  {translate("technical_support")}
                </div>
                <div className="my-2 text-lg mb-8">
                  ({translate('wifi_camera_application_glitches')})
                </div>
                <div className="flex justify-center items-center my-4">
                  <div className="mx-2 p-2">
                    <img src={getChatBotLogo()} className="h-24 w-24" />
                  </div>
                  <div className="mx-2">
                    <p>{translate('live_chat')},</p>
                    <p>{translate('instant_response')}</p>
                    <p>{translate('click_here_to_start')}</p>
                  </div>
                </div>
              </div>
              <div className="w-full text-center pt-16 text-text-2 tracking-widest">
                <div className="text-2xl tracking-widest text-text-3">
                  {translate('customer_support')}
                </div>
                <div className="my-2 text-lg mb-8">
                  ({translate('payment_return_products_training_schedule')})
                </div>
                <div className="flex justify-center items-center my-4">
                  <div className="mx-2 p-2">
                    <img src={getChatBotLogo()} className="h-24 w-24" />
                  </div>
                  <div className="mx-2">
                    <p>{translate('live_chat')},</p>
                    <p>{translate('instant_response')}</p>
                    <p>{translate('click_here_to_start')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Loading message={translate('loading_support')} ready={ready} />
    </>
  );
}
