import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import LearningIcon from '../assets/images/learning-icon.png';
import { useUserContext } from '../contexts/userContext';
import { getChatBotLogo, getLogo } from '../utlis/themeHelpers';
import Loading from './Loading';
import { FiUsers } from "react-icons/fi";
import LanguageSelector from '../components/LanguageSelector';
import { useTranslation } from '../contexts/TranslationContext';


export default function Dashboard() {
  const { isSalonApp, isAdmin, handleAdminLogin, user, staff } = useUserContext();
  const { translate } = useTranslation();
  const [showLearningLinks, setShowLearningLinks] = useState(false);
  const [showAdminLogin, setShowAdminLogin] = useState(false);
  const [ready, setReady] = useState(true);
  const [password, setPassword] = useState('');
  const history = useHistory();

  const LearningLinks = [
    { link: "/admin/training", label: translate('online_training_course') },
    { link: "/admin/support", label: translate('schedule_device_setup') },
    { link: "/admin/support", label: translate('schedule_1st_training') },
    { link: "/admin/support", label: translate('schedule_image_review') },
  ]

  const handleToggleLearningLinks = () => {
    setShowLearningLinks(prev => !prev)
  }

  const handleToggleAdminLoginPopup = () => {
    if (isAdmin) {
      history.push('/admin/profile');
      return;
    }
    setShowAdminLogin(prev => !prev);
    setPassword('');
  }

  const AdminLogin = () => {
    setReady(false);
    fetch(`${process.env.REACT_APP_API}/clinic/auth-secondary`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      },
      body: JSON.stringify({
        password
      })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          handleAdminLogin(true);
          history.push('/admin/profile');
        } else {
          alert(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        setReady(true);
      });
  }

  useEffect(() => {
    if (!user) return;
    if (!isSalonApp) {
      if (typeof user?.user_agreement_pdf === 'object') {
        return history.replace("/onboarding/user-agreement")
      }
      if (typeof user?.hipaa_agreement_pdf === 'object') {
        return history.replace("/onboarding/hipaa-agreement")
      }
      if (!user?.phone?.length) {
        return history.replace("/onboarding/md_details")
      }
    }
    if (staff && !staff?.length) {
      return history.replace(`/onboarding/staff-setup`)
    }
  }, [])

  return (
    <>
      <main className={`flex flex-col p-4 text-text-2 tracking-widest ${ready ? "" : "hidden"}`}>

        <section className="container m-auto flex flex-col">
          <NavLink to="/" className="mx-auto">
            <img className="h-20" src={getLogo(isSalonApp)} alt="GRO Track" />
          </NavLink>
          <nav className="mt-28 grid grid-cols-6 gap-2 capitalize">
            <figure className="flex flex-col items-center">
              <NavLink
                to="/patient/new"
                className="inline-block p-4 xl:p-5 bg-button-1 rounded-full"
              >
                <svg
                  className="h-7 w-7 xl:h-11 xl:w-11"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
                </svg>
              </NavLink>
              <figcaption className="mt-6 text-center font-bold opacity-70">{translate('new')} <br />{isSalonApp ? translate('client') : translate('patient')}</figcaption>
            </figure>
            <figure className="flex flex-col items-center">
              <NavLink
                to="/patient/existing"
                className="inline-block p-4 xl:p-5 bg-button-2 rounded-full"
              >
                <svg
                  className="h-7 w-7 xl:h-11 xl:w-11"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
                </svg>
              </NavLink>
              <figcaption className="mt-6 text-center font-bold opacity-70 ">
                {translate('existing')} <br />{isSalonApp ? translate('client') : translate('patient')}
              </figcaption>
            </figure>
            <figure className="flex flex-col items-center">
              <NavLink
                to="/patient/referrals"
                className="inline-block p-4 xl:p-5 bg-background-11 rounded-full"
              >
                <svg
                  className="h-7 w-7 xl:h-11 xl:w-11"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
                </svg>
              </NavLink>
              <figcaption className="mt-6 text-center font-bold opacity-70">
                {isSalonApp ? translate('client') : translate('patient')} <br />{translate('referrals')}
              </figcaption>
            </figure>
            <figure className='flex flex-col items-center'>
              <div className="inline-block p-1 xl:p-1 rounded-full cursor-pointer relative" style={{ backgroundColor: "#f7a79d" }}>
                <img src={LearningIcon} className="h-14 w-14 xl:h-20 xl:w-20" onClick={handleToggleLearningLinks} />
                {showLearningLinks && (
                  <div className='absolute z-30 bg-white rounded-lg px-4 py-2 flex flex-col left-1/2' style={{ transform: "translate(-50%, 4px)" }}>
                    {LearningLinks.map((link) => {
                      return (
                        <NavLink to={link.link} className='flex gap-2 items-center group'>
                          <div style={{ height: "10px", width: "10px" }} className='rounded-full bg-white group-hover:bg-accent-3 border border-gray-500'></div>
                          <span className="whitespace-nowrap text-black opacity-80 font-normal" style={{ wordWrap: "no-wrap", fontSize: "12px", lineHeight: "22px", letterSpacing: '0.6px', fontFamily: "Roboto" }}>{link.label}</span>
                        </NavLink>
                      )
                    })}
                  </div>
                )}
              </div>
              <figcaption className="mt-6 text-center font-bold opacity-70">{translate('learning')}<br /> {translate('center')}</figcaption>
            </figure>
            <figure className="flex flex-col items-center relative">
              <NavLink
                to="/staff-login"
                className="inline-block p-4 xl:p-5 bg-background-11 rounded-full"
              >
                <FiUsers className="h-7 w-7 xl:h-11 xl:w-11 text-black" />
              </NavLink>
              <figcaption className="mt-6 text-center font-bold opacity-70 capitalize">
                {translate('select')} <br /> {isSalonApp ? translate('stylist') : translate('staff')}
              </figcaption>
            </figure>
            <figure className="flex flex-col items-center relative">
              <div
                onClick={handleToggleAdminLoginPopup}
                className="inline-block cursor-pointer p-4 xl:p-5 bg-button-3 rounded-full"
              >
                <svg
                  className="h-7 w-7 xl:h-11 xl:w-11"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" />
                </svg>
              </div>
              {showAdminLogin && (
                <div className='w-52 absolute input-shadow top-32 tracking-wide z-30 bg-background-25 text-sm rounded-lg px-4 py-2 flex flex-col items-center left-1/2' style={{ transform: "translate(-50%, 4px)" }}>
                  <div className='text-black my-2'>{`${translate('admin')} ${translate('login')}`}</div>
                  <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)}
                    className="p-2 pb-1 my-1 w-full border border-accent-7 text-text-1 font-bold text-center focus:outline-none" />
                  <div onClick={AdminLogin}
                    className={`text-text-3 text-xs px-6 py-2 my-2 text-center rounded-md cursor-pointer tracking-widest bg-background-23 uppercase`}>
                    {translate('confirm')}
                  </div>
                  <NavLink to="/admin/create-password"
                    className="mt-1 text-xs text-button-5 hover:underline focus:outline-none focus:underline font-bold">
                    {translate('create_password')}
                  </NavLink>
                </div>
              )}
              <figcaption className="mt-6 text-center font-bold opacity-70">{translate('admin')}</figcaption>
            </figure>
          </nav>
        </section>
        <footer className='flex flex-col items-center w-full'>
        <div className="mb-4 text-center">
          <LanguageSelector />
        </div>
          {/* <img src={getChatBotLogo()} className='h-16 w-16 mb-3' alt='chat-bot' /> */}
          <div className="pb-8 text-center">
            <button
              className="bg-transparent text-gray-500 font-semibold py-1 px-5 border border-gray-500 rounded"
              onClick={() => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('expires_at');
                localStorage.removeItem('staffId');
                window.location.href = '/';
              }}
            >
              {translate('log_out')}
            </button>
          </div>
          <div className="py-4 border-t-2 border-gray-700 text-center w-11/12">
            {translate('gro_technologies')}
          </div>
        </footer>
      </main>
      <Loading message={translate('loading_details')} ready={ready} />
    </>
  );
}
