import translations from '../data/translations.json';

let currentLanguage = localStorage.getItem('language') || 'en';

export const setLCLanguage = (language) => {
  currentLanguage = language;
};

export const getTranslation = (key) => {
  return translations[currentLanguage]?.[key] || key;
};