export const DID_API_URL = "https://api.d-id.com";
export const DID_TALK_SERVICE = "talks";
export const AVATAR_IMAGE_URL =
  "https://static1.srcdn.com/wordpress/wp-content/uploads/2021/12/Synthesia-Avatar.jpg";

export const DID_API_KEY = process.env.REACT_APP_DID_API_KEY;

export const DID_AGENT_ID = "agt_RPw4rZy_";

export const OPENAI_SECRET_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const OPENAI_ASSITANT_ID = process.env.REACT_APP_OPENAI_ASSISTANT_ID;
