import React, { useState } from 'react'
import { getLogo } from '../utlis/themeHelpers';
import { useUserContext } from '../contexts/userContext';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AdminHeader from '../components/AdminHeader';
import Swal from 'sweetalert2';
import Loading from './Loading';
import { useTranslation } from "../contexts/TranslationContext";

const ResetPassword = () => {
    const location = useLocation()
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')
    const [ready, setReady] = useState(true)

    const token = new URLSearchParams(location.search).get("token");
    const { translate } = useTranslation();

    const checkIsInputsValid = () => {
        if (password === confirmPassword) return true;
        return false;
    }


    const handleSubmit = async () => {
        const isInputsValid = checkIsInputsValid()
        if (!isInputsValid) {
            setMessage("Passwords do not match!");
            return;
        }
        setReady(false);
        fetch(`${process.env.REACT_APP_API}/clinic/password/reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.trim().toLowerCase(),
                password,
                password_confirmation: confirmPassword,
                token
            }),
        })
            .then(response => response.json())
            .then(response => {
                setReady(true)
                console.log(response, "response")
                if (response.success) {
                    Swal.fire(
                        "Password has been reset successfully!",
                        "Please use your new password to login to your account",
                        "success"
                    ).then(() => {
                        history.push("/")
                    })
                } else {
                    console.log(response, "error")
                    setMessage(response.message)
                }
            })
            .catch(error => {
                console.error(error);
                setMessage(translate('error_occurred'));
                setReady(true);
            });
    }


    return (
        <>
        <main className={`flex flex-col p-4 font-font-2 ${ready ? '' : 'hidden'} min-h-screen`}>
            <AdminHeader />
            <div className="my-auto flex flex-col">
                <h1 className='text-2xl xl:text-3xl mb-4 text-text-2 mx-auto font-bold tracking-widest'>{translate('reset_your_password')}</h1>
                {message && <p className="text-center text-red-600">{message}</p>}
                <form
                    className="mt-20 flex flex-col items-center"
                    onSubmit={event => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                >
                    <input
                        type="email"
                        className="px-8 py-2 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
                        placeholder={translate('email')}
                        value={email}
                        required
                        onChange={event => {
                            setMessage("")
                            setEmail(event.target.value);
                        }}
                    />
                    <input
                        type="password"
                        className="mt-8 px-8 py-2 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
                        placeholder="NEW PASSWORD"
                        value={password}
                        required
                        onChange={event => {
                            setMessage("")
                            setPassword(event.target.value);
                        }}
                    />
                    <input
                        type="password"
                        className="mt-8 px-8 py-2 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
                        placeholder={translate('confirm_password')}
                        value={confirmPassword}
                        required
                        onChange={event => {
                            setMessage("")
                            setConfirmPassword(event.target.value);
                        }}
                    />
                    <button
                        type="submit"
                        className="mt-28 px-12 py-2 rounded-md font-bold text-lg tracking-widest bg-button-5 text-white cursor-pointer focus:outline-none"
                    >
                        {translate('confirm')}
                    </button>
                </form>

            </div>
        </main>
        <Loading 
           message={translate('resetting_password')}
           ready={ready}
        />
        </>
    )
}

export default ResetPassword