import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./tailwind.output.css";
import "./index.css";
import { TranslationProvider } from "./contexts/TranslationContext";
import { BrowserRouter as Router } from "react-router-dom/cjs/react-router-dom.min";
import { UserContextProvider } from "./contexts/userContext";
import { AuthContextProvider } from "./contexts/authContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/service-worker.js").then(
      function (registration) {
        // Registration was successful
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      function (err) {
        // registration failed :(
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });
}

const provider = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
      <Router>
    <TranslationProvider>
      <QueryClientProvider client={provider}>
        <UserContextProvider>
          <AuthContextProvider>
            <App />
            <ToastContainer />
          </AuthContextProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </TranslationProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
