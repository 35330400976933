import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/GROTrackTurquoise.png";
import Loading from "../views/Loading";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../contexts/userContext";
import { getLogo } from "../utlis/themeHelpers";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

export default function OnboardingAdminProfile() {
  const history = useHistory();
  const { isSalonApp, user, handletSetUserOnly } = useUserContext();
  const { translate } = useTranslation();


  const [physician, setPhysician] = useState(null);
  const [editPhysician, setEditPhysician] = useState(null);
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [ready, setReady] = useState(false);

  const isPhysicianValid = () => {
    return true;
  };

  const savePhysician = () => {
    if (isPhysicianValid()) {
      setErrors([]);
      setMessage("");
      setReady(false);
      fetch(`${process.env.REACT_APP_API}/admin/profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(editPhysician),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            setPhysician(response.adminProfile);
            setEditPhysician(response.adminProfile);
            setSuccessMessage(translate('profile_updated_successfully'));
            setTimeout(() => {
              handletSetUserOnly({...user, phone : editPhysician.phone})
              history.push("/onboarding/staff-setup");
            }, 1000);
          } else {
            setMessage(response.message);
            setErrors(response.error);
          }
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/admin/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPhysician(response.adminProfile);
          setEditPhysician({
            ...response.adminProfile,
            address: { ...response.adminProfile.address },
          });
        } else {
          setMessage(response.message);
        }
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, []);

  return (
    <>
      <main
        className={`mx-auto w-full flex flex-col p-5 font-font-2 tracking-wide font-normal ${
          ready ? "" : "hidden"
        }`}
      >
        <AdminHeader hideChatGPT hideHomeLink/>

        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {successMessage && (
          <p className="mt-8 text-center text-green-600">{successMessage}</p>
        )}
        <section className="flex items-center container mx-auto flex-col" style={{ flexGrow: 1 }}>
          <h1 className="text-3xl tracking-widest text-center text-text-2 font-bold mt-14">{translate('practice_information')}</h1>
          {editPhysician && (
            <div className="mx-auto mt-6">
              <form className="flex flex-col" 
              onSubmit={(event) => {
                event.preventDefault();
                savePhysician();
              }}
              >
                {/* <div className="mt-4 flex justify-between items-center">
                  <p className="mx-auto text-text-2 font-bold">
                    PRACTICE INFORMATION
                  </p>
                </div> */}
                <div className="mx-auto mt-10 flex flex-col items-end">
                  <label className="flex items-center">
                    <p className="ml-2 mr-3">
                      {isSalonApp ? translate('salon') : translate('practice')} {translate('name')}
                    </p>
                    <input
                      type="text"
                      required
                      className="px-3 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editPhysician.practiceName}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          practiceName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.practiceName && (
                    <p className="text-center text-red-600">
                      {errors.practiceName}
                    </p>
                  )}
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">
                      {isSalonApp ? translate('owner') : translate('physician')} {translate('name')}
                    </p>
                    <input
                      type="text"
                      required
                      className="px-3 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editPhysician.physicianName}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          physicianName: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.physicianName && (
                    <p className="text-center text-red-600">
                      {errors.physicianName}
                    </p>
                  )}
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('address')}</p>
                    <input
                      type="text"
                      required
                      className="px-3 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editPhysician.address.street}
                      placeholder={translate('street')}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          address: {
                            ...editPhysician.address,
                            street: event.target.value,
                          },
                        });
                      }}
                    />
                  </label>
                  <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                    value={editPhysician.address.suite}
                    placeholder={translate('suite')}
                    required
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          suite: event.target.value,
                        },
                      });
                    }}
                  />
                  <input
                    type="text"
                    className="mt-5 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                    value={editPhysician.address.city}
                    placeholder={translate('city')}
                    required
                    onChange={(event) => {
                      setEditPhysician({
                        ...editPhysician,
                        address: {
                          ...editPhysician.address,
                          city: event.target.value,
                        },
                      });
                    }}
                  />
                  <div className="mt-5 grid gap-6 w-40 md:w-96 md:grid-cols-2">
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                        value={editPhysician.address.state}
                        placeholder={translate('state')}
                        required
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              state: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                    <label className="flex items-center">
                      <input
                        type="text"
                        className="w-full px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                        value={editPhysician.address.zip}
                        placeholder={translate('zip')}
                        required  
                        onChange={(event) => {
                          setEditPhysician({
                            ...editPhysician,
                            address: {
                              ...editPhysician.address,
                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </label>
                  </div>
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('phone')}</p>
                    <input
                      type="text"
                      required
                      className="px-3 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editPhysician.phone}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          phone: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.phone && (
                    <p className="text-center text-red-600">{errors.phone}</p>
                  )}
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('email')}</p>
                    <input
                      type="email"
                      required
                      className="px-3 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editPhysician.email}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          email: event.target.value,
                        });
                      }}
                    />
                  </label>
                  {errors.email && (
                    <p className="text-center text-red-600">{errors.email}</p>
                  )}
                  <label className="mt-5 flex items-center">
                    <p className="ml-2 mr-3">{translate('website')}</p>
                    <input
                      type="text"
                      required
                      className="px-3 ml-6 w-40 md:w-96 px-2 py-1 bg-background-22 text-text-2 font-bold  focus:outline-none"
                      value={editPhysician.website}
                      onChange={(event) => {
                        setEditPhysician({
                          ...editPhysician,
                          website: event.target.value,
                        });
                      }}
                    />
                  </label>
                </div>

                <div className="mx-auto mt-28 flex flex-col items-center">
                  <button
                    className="px-8 py-1 bg-button-5 rounded-lg text-text-3 font-bold cursor-pointer focus:outline-none"
                    type="submit"
                  >
                    {translate('website')}
                  </button>
                </div>
              </form>
            </div>
          )}
        </section>
      </main>
      <Loading
        message={`Loading ${isSalonApp ? translate('salon') : translate('physician')} ${translate('details')}`}
        ready={ready}
      />
    </>
  );
}
