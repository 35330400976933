import React, { createContext, useContext, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const UserContext = createContext({})

export const useUserContext = () => {
    return useContext(UserContext)
}

const storedStaffId = JSON.parse(localStorage.getItem("staffId"))

export const UserContextProvider = ({ children }) => {

    // Contexts and states ---------------------------------------------------------------------------

    const isSalonAppDomain = window.location.hostname === 'salon.grotrack.online' || window.location.hostname === 'salon.grotrack.io'
    const [isSalonApp, setIsSalonApp] = useState(isSalonAppDomain)
    const [user, setUser] = useState(null)
    const [staff, setStaff] = useState(null)
    const [trainingDone, setTrainingDone] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [staffId, setStaffId] = useState(storedStaffId)

    // Fetch functions -------------------------------------------------------------------------------


    const getStaffs = () => {
        fetch(`${process.env.REACT_APP_API}/clinic/staffs`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                setStaff(response.data)
                const staffs = response.data;
                if (!staffs?.length) return setTrainingDone(false);
                const istraniningPending = staffs?.some((staff) => !staff?.training_certification_date)
                if(istraniningPending) return setTrainingDone(false);
                setTrainingDone(true)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const getUserProfile = () => {
        fetch(`${process.env.REACT_APP_API}/clinic`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    setUser(response.data)
                    if (response?.data?.type === "salon") {
                        setIsSalonApp(true)
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    // Use effects ---------------------------------------------------------------------------


    useEffect(() => {
        getUserProfile();
        getStaffs()
    }, []);


    // Utility functions ---------------------------------------------------------------------------



    const handleSetUser = (data) => {
        setUser(data);
        getStaffs();
    };

    const handletSetUserOnly = (data) => {
        setUser(data);
    }

    const handletSetStaff = (data) => {
        setStaff(data);
    }

    const handleSetSalonTheme = () => {
        setIsSalonApp(true)
    }

    const handleSetTrainingDone = (flag) => {
        setTrainingDone(flag);
    }

    const fetchUserData = () => {
        getUserProfile();
    }

    const handleAdminLogin = (flag) => {
        if(flag) {
            setStaffId('')
            localStorage.setItem('staffId', 0)
        }
        setIsAdmin(flag);
    }

    const handleSetStaffId = (id) => {
        if(id) setIsAdmin(false);
        setStaffId(id);
        localStorage.setItem("staffId", id ?? 0)
    }



    // JSX ---------------------------------------------------------------------------

    return (
        <UserContext.Provider value={{
            user, handleSetUser, isSalonApp, handleSetSalonTheme,
            trainingDone, handleSetTrainingDone, isAdmin, handleAdminLogin, fetchUserData, staff, handletSetUserOnly, handletSetStaff, staffId, handleSetStaffId
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext