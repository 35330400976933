import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import GroRating from "../../assets/images/GRORatingsLogo.svg";
import { MarketPlaceDetails } from "./MarketPlaceDetails";
import CategoryBar from "./CategoryBar";
import Products from "./Products";
import { useCartContext } from "../../views/AdminMarkerPlaceWrapper";
import { useUserContext } from "../../contexts/userContext";


export const AdminMarketPlace = () => {
  const { isSalonApp } = useUserContext();
  const { cartItems, handleAddItemToCart, handleRemoveItemFromCart, templates } = useCartContext();

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);
  const [showSection, setShowSection] = useState(isSalonApp ? "PRODUCTS" : "TREATMENTS");
  const [productsList, setProductsList] = useState([]);
  const [productStartIndex, setProductStartIndex] = useState(0);
  const [productEndIndex, setProductEndIndex] = useState(2);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    console.log(templates, showSection, "templates")
    if (showSection === "TREATMENTS") {
      setProductsList(templates?.treatment_procedures);
    } else if (showSection === "PRODUCTS") {
      setProductsList(templates?.treatment_product);
    } else {
      setProductsList([]);
    }
  }, [showSection, templates]);

  const handleSlide = (rightSlide) => {
    if (rightSlide) {
      if (productEndIndex < productsList.length - 1) {
        setProductEndIndex(productEndIndex + 3);
        setProductStartIndex(productStartIndex + 3);
      }
    } else {
      if (productStartIndex > 0) {
        setProductEndIndex(productEndIndex - 3);
        setProductStartIndex(productStartIndex - 3);
      }
    }
  };

  const handleChangeSection = (value) => {
    setShowSection(value)
  }

  const resetIndex = () => {
    setProductEndIndex(2);
    setProductStartIndex(0);
  };

  const showDetails = (item) => {
    setSelectedItem(item);
  };

  return (

    !selectedItem ? (
      <>
        <CategoryBar showSection={showSection} handleChangeSection={handleChangeSection} resetIndex={resetIndex} />
        <Products handleSlide={handleSlide} showSection={showSection} productsList={productsList} showDetails={showDetails} productStartIndex={productStartIndex} productEndIndex={productEndIndex} GroRating={GroRating} />
      </>
    ) : (
      <MarketPlaceDetails
        item={selectedItem}
        setSelectedItem={setSelectedItem}
        parent={showSection}
      />
    )
  );
};
