import { React } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../contexts/TranslationContext';

export default function PatientSidebar(props) {
  const { translate } = useTranslation();
  return (
    <aside className="w-52 px-5 hidden lg:flex flex-col border-r-2 border-background-7 text-lg font-font-2 font-medium tracking-widest">
      <ul className="mt-16 grid gap-3 text-text-2">
        <li>
          <NavLink
            exact
            to={`/patient/account/profile/${props.id}`}
            activeClassName="text-text-3 font-bold"
          >
            {translate('profile')}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/patient/account/health-info/${props.id}`}
            activeClassName="text-text-3 font-bold"
          >
            {translate('hair_health')}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/patient/account/payment/${props.id}`}
            activeClassName="text-text-3 font-bold"
          >
            {translate('exam')}
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            to={`/patient/account/consent/${props.id}`}
            activeClassName="text-text-3 font-bold"
          >
            {translate('consent')}
          </NavLink>
        </li>
      </ul>
    </aside>
  );
}
