import React, { useEffect, useRef, useState } from "react";
import Message from "./Message";
import useChatBot, { CHATBOT_STATE } from "../hook/useChatBot";
import { IoSend } from "react-icons/io5";
import { IoIosClose, IoIosMic } from "react-icons/io";
import { ReactComponent as RobotFilled } from "../../../assets/icons/robot-filled.svg";

import { AVATAR_IMAGE_URL } from "../constants";
import Loading from "../../../views/Loading";

import styles from "./Chatbox.module.css";
import { useSpeechRecognition } from "../hook/useSpeechRecognition";
import MicrophonePulsator from "./MicrophonePulsator";
import { ExpandedVideo } from "./ExpandedVideo";
import { useCreateTicketOnIntercom } from "../../../hooks/entities/intercom";
import { toast } from "react-toastify";
import { useTranslation } from "../../../contexts/TranslationContext";

const messageSuggestions = [
  "Hi, I want to know how Gro Track App works?",
  "Please tell me the benefits of using this app.",
  "Where can i see my profile?",
];

const AI_DEFAULT_MSG = {
  id: new Date().getTime(),
  sender: "AI",
  text: "Hii, how can I help you?",
};

const ChatBox = ({ closeChat }) => {
  const {
    stream,
    chatbotState,
    connectChatbot,
    talkToChatbot,
    destroyChatbot,
    messages,
    activeMessage,
    customerSatisfaction,
    resetCustomerSatisfaction,
  } = useChatBot();

  const { startListening, reset, transcript, isListening } =
    useSpeechRecognition();
const { translate} = useTranslation();
  console.log("transcript", transcript);

  // const [messages, setMessages] = useState([AI_DEFAULT_MSG]);
  const [isMsgSuggestionsHidden, setIsMsgSuggestionsHidden] = useState(false);
  const [isGeneratingAIResponse, setIsGeneratingAIResponse] = useState(true);
  const [lastMessageSource, setLastMessageSource] = useState(null);
  const [messageText, setMessageText] = useState("");
  const chatContainerRef = useRef(null);
  const { mutateAsync: createTicket } = useCreateTicketOnIntercom();

  useEffect(() => {
    if (activeMessage != null) return;
    setTimeout(() => {
      if (chatContainerRef.current) {
        const lastDiv =
          chatContainerRef.current.querySelector("div:last-child");
        if (lastDiv) {
          lastDiv.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 100);
  }, [activeMessage, messages]);

  const postMessage = async (message, source) => {
    if (message.split(" ").length < 3) {
      alert("Please enter a valid message, minimum 3 words.");
      return;
    }
    setLastMessageSource(source);
    if (messages.length === 1) {
      setIsMsgSuggestionsHidden(true);
    }
    try {
      setIsGeneratingAIResponse(true);

      setMessageText("");

      await talkToChatbot(message);
      setIsGeneratingAIResponse(false);
    } catch (error) {
      console.error(error);
      setIsGeneratingAIResponse(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    postMessage(messageText, "text");
  };

  const toggleListening = () => {
    if (isListening) {
      reset();
    } else {
      startListening()
        .then((text) => {
          if (text === "") return;
          setMessageText(text);
          postMessage(text, "voice");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  };

  const handleCustomerSatisfaction = (satisfaction) => {
    toast.promise(
      createTicket({
        title: "Query From Chatbot",
        description:
          "User is not satisfied with the response from chatbot. Please contact the user. ",
        messages: messages,
      }),
      {
        pending: "Creating Ticket",
        success: "Ticket Created Successfully. We will contact you soon.",
        error: "Error in creating ticket. Please try again later",
      }
    );

    resetCustomerSatisfaction();
  };
  return (
    <div
      className="absolute bottom-full -right-10 sm:right-0 mb-2 rounded bg-white w-screen sm:w-96 overflow-hidden"
      style={{
        height: "600px",
      }}
    >
      {chatbotState === CHATBOT_STATE.IS_INITIATING ? (
        <div
          style={{
            transform: "translateY(50%)",
          }}
        >
          <Loading
            message={translate('loading')}
            ready={
              chatbotState === CHATBOT_STATE.READY ||
              chatbotState === CHATBOT_STATE.PENDING
            }
          />
        </div>
      ) : chatbotState === CHATBOT_STATE.READY ||
        chatbotState === CHATBOT_STATE.PENDING ? (
        <div className="relative" style={{ height: "100%" }}>
          <div className="flex absolute ">
            <p className="ml-4">Ask Me Anything</p>
            <IoIosClose
              className="cursor-pointer text-black"
              onClick={closeChat}
            />
          </div>
          <ExpandedVideo
            stream={stream}
            message={activeMessage}
            onFinish={() => {
              if (lastMessageSource === "voice") {
                toggleListening();
              }
            }}
          />

          <div className="px-4 py-4 absolute bottom-0 right-0 left-0 ">
            {isMsgSuggestionsHidden || isListening ? null : (
              <div className="text-right mb-2">
                {messageSuggestions.map((msgSuggestion, index) => (
                  <span
                    className="inline-flex items-center rounded-full cursor-pointer bg-background-26 px-2 py-1 text-xs font-medium text-accent-8 ring-1 ring-inset ring-accent-8 mt-2"
                    key={index}
                    onClick={() => postMessage(msgSuggestion, "text")}
                  >
                    {msgSuggestion}
                  </span>
                ))}
              </div>
            )}

            {!customerSatisfaction && (
              <div className="mt-2 mb-2">
                <div
                  className="flex items-center p-2 pl-4 rounded-full bg-background-26   ring-1 ring-inset ring-accent-8
                "
                >
                  <div className=" text-xs font-medium text-accent-8 ">
                    <p className="mr-1">
                      It seems like you are not satisfied with the response.
                    </p>
                    <span
                      className="cursor-pointer"
                      onClick={handleCustomerSatisfaction}
                    >
                      Kindly click here to raise a ticket. Our support team will
                      contact you.
                    </span>
                  </div>

                  <IoIosClose
                    className="cursor-pointer"
                    onClick={resetCustomerSatisfaction}
                  />
                </div>
              </div>
            )}

            <form
              className="flex items-center rounded px-1"
              onSubmit={handleFormSubmit}
            >
              {isListening ? (
                <div className="flex flex-col items-center w-full">
                  <p className="text-xs text-accent-8 mb-2">{transcript}</p>
                  <MicrophonePulsator />
                </div>
              ) : (
                <>
                  <textarea
                    className="flex-1 py-2 px-1 w-100 bg-transparent focus:outline-none"
                    value={isListening ? transcript : messageText}
                    style={{ backgroundColor: "#6473812e" }}
                    onChange={(e) => setMessageText(e.target.value)}
                    placeholder="Type your query"
                    rows={1}
                  />

                  <button
                    type="submit"
                    className="p-2 bg-background-23 rounded text-text-3
                    cursor-pointer focus:outline-none"
                    disabled={CHATBOT_STATE.PENDING === chatbotState}
                  >
                    <IoSend />
                  </button>

                  <button
                    type="button"
                    className="p-2 bg-background-23 rounded text-text-3
                    cursor-pointer focus:outline-none"
                    disabled={CHATBOT_STATE.PENDING === chatbotState}
                    onClick={toggleListening}
                  >
                    {isListening ? <IoIosClose /> : <IoIosMic />}
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChatBox;
