import React, { useState, useEffect } from "react";
import "./Dropdown.css";

export default function Dropdown(props) {
  const [open, setOpen] = useState(false);
  const [val, setValue] = useState(props.value);

  const option1 = (args) => {
    props.addValue(args);

    setValue((val) => args);
    setOpen((open) => !open);
  };
  const option2 = (arg) => {
    props.addValue(arg);
    setValue((val) => arg);
    setOpen((open) => !open);
  };
  const option3 = (ar) => {
    props.addValue(ar);
    setValue((val) => ar);
    setOpen((open) => !open);
  };

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <div className="custom-select-wrapper">
      <div className="custom-select">
        <div
          className="custom-select__trigger bg-background-7 "
          style={{
            color: "rgb(190, 196, 202)",
            overflow: "hidden",
            fontSize: "2.5vh",
            padding: "7px",
          }}
          onClick={() => setOpen((open) => !open)}
        >
          <span>{val}</span>
          <div className="arrow"></div>
        </div>
        {open && !props.disabled && (
          <div
            className="custom-options bg-background-10"
            style={{ height: "auto" }}
          >
            <span
              style={{
                fontSize: window.innerWidth < 540 ? "xx-small" : "0.75rem",
                height: "1.8rem",
              }}
              onClick={() => option1(props.option1)}
              className="custom-option font-black p-2  overflow-hidden"
              data-value={props.option1}
            >
              {props.option1}
            </span>
            {props.option2 ? (
              <span
                style={{
                  fontSize: window.innerWidth < 540 ? "xx-small" : "0.75rem",
                  height: "1.8rem",
                }}
                onClick={() => option2(props.option2)}
                className="custom-option font-black p-2  overflow-hidden"
                data-value={props.option2}
              >
                {props.option2}
              </span>
            ) : null}
            {props.option3 ? (
              <span
                style={{
                  fontSize: window.innerWidth < 540 ? "xx-small" : "0.75rem",
                  height: "1.8rem",
                }}
                onClick={() => option3(props.option3)}
                className="custom-option font-black  p-2  overflow-hidden"
                data-value={props.option3}
              >
                {props.option3}
              </span>
            ) : null}
            <input
              type="text"
              onChange={(event) => setValue((val) => event.target.value)}
              className="px-2 py-1 w-full border outline-black border-background-10 bg-white text-text-3 focus:outline-none"
              style={{
                color: "black",
                height: "21px",
                width: "69%",
                position: "relative",
                marginBottom: "14px",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
