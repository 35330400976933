import React, { createContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import { getLogo } from "../utlis/themeHelpers";
import { hairThinningUiData } from "../data/barGraphData";
import ClientResultsAssessment from "../components/ClientResultsComponents/ClientResultsAssessment";
import {
  Link,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ProductRecommendations from "../components/ClientResultsComponents/ProductRecommendations";
import ProductResults from "../components/ClientResultsComponents/ProductResults";
import { useUserContext } from "../contexts/userContext";
import { useTranslation } from "../contexts/TranslationContext";

export const ClientContext = createContext({
  isClient: false,
});

const ClientResults = () => {
  const location = useLocation();
  const { patient_id, analysis_id, mainType, subType } = useParams();
  const { isSalonApp } = useUserContext()
  const { translate } = useTranslation();

  const [message, setMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [hairAnalysis, setHairAnalysis] = useState({});
  const [gender, setGender] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsToDisplay, setProductsToDisplay] = useState([])
  const [globalHairThinningLevel, setGlobalHairThinningLevel] = useState([]);

  const produtsRef = useRef()

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/patient/${patient_id}/hair_analysis/${analysis_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setFirstName(response.patient?.firstName);
          setLastName(response.patient?.lastName);
          setHairAnalysis(response.hair_analysis);
          setGender(response.patient?.gender);
          const globalThinningItem = Object.values(hairThinningUiData).findIndex((level) =>
            level.markerKey === response.hair_analysis?.images?.global.hair_thinning?.label
          );
          setGlobalHairThinningLevel(globalThinningItem ?? 0);
        } else {
          setMessage(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => {
        setReady(true);
      });
  }, [patient_id]);

  const handleRoute = () => { };

  useEffect(() => {
    if (products?.length) return;
    fetch(
      `${process.env.REACT_APP_API}/patient/${patient_id}/treatment-suggestion`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setProducts(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
      })
      .finally(() => { });
  }, [location]);

  useEffect(() => {
    if (products.length && globalHairThinningLevel) {
      setProductsToDisplay(products?.filter(product => product?.details?.hair_loss_class <= globalHairThinningLevel))
    }
  }, [products, globalHairThinningLevel])

  return (
    <>
      <main className={`mx-auto w-full flex flex-col ${ready ? "" : "hidden"}`}>
        <div className="flex justify-center py-8 items-center relative bg-background-1 z-50">
          <Link
            to={`/results/${patient_id}/${analysis_id}/${mainType}/${subType}`}
          >
            <img src={getLogo(isSalonApp)} className="h-10 xs:h-16" />
          </Link>
        </div>
        {message && <p className="my-8 text-center text-red-600">{message}</p>}
        <ClientContext.Provider value={{ isClient: true }}>
          <ClientResultsAssessment
            handleTriggerRelaod={() => { }}
            analysis={hairAnalysis}
            base_analysis={hairAnalysis}
            firstName={firstName}
            lastName={lastName}
            clientGender={gender}
            produtsRef={produtsRef}

          />
          {productsToDisplay?.length && gender ? (
            <>
              <ProductRecommendations
                analysis={hairAnalysis}
                products={productsToDisplay}
                ref={produtsRef}
                clientGender={gender === "F" || gender === "O" ? "women" : gender === "M" ? "men" : null}
              />
              <ProductResults 
              products={productsToDisplay} 
              clientGender={gender}
              />
            </>
          ) : ("")}
        </ClientContext.Provider>
      </main>
      <Loading message={translate('loading_your_results')} ready={ready} />
    </>
  );
};

export default ClientResults;
