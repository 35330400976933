import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { calculateGroIndexScore } from '../../utlis/calculations';
import lessThanIcon from "../../assets/images/lessThanIcon.svg";
import { hairGrowthUiData, hairThinningUiData } from '../../data/barGraphData';
import { Link, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from '../../contexts/TranslationContext';



const GroIndexGraph = ({ analysis, base_analysis, mainType, hideGrowthIndex, graphType, forType }) => {

	const { patient_id, analysis_id } = useParams();
	const { translate } = useTranslation();
	const [hairGrowth, setHairGrowth] = useState(null);
	const [baselineHairGrowth, setBaselineHairGrowth] = useState(null);
	const [hairThinningLevel, setHairThinningLevel] = useState(null)
	const [showTooltip, setShowTooltip] = useState(false);


	const clientResultsMatch = useRouteMatch("/results/:patient_id/:analysis_id/:mainType/:subType");



	const toggleTooltipVisibility = () => {
		// if(clientResultsMatch) return;
		if (!hairGrowth && graphType === 'hair_growth') return;
		setShowTooltip(prev => !prev)
	}

	useEffect(() => {
		if (mainType === 'closeup') {
			setHairGrowth(analysis?.images?.closeup?.hair_growth?.[forType] || null);
			setHairThinningLevel(analysis?.images?.closeup?.hair_thinning?.[forType] || null)
		} else if (mainType === 'global') {
			setHairGrowth(analysis?.images?.global?.hair_growth || null);
			setHairThinningLevel(analysis?.images?.global?.hair_thinning || null)

		} else {
			setHairGrowth(null);
			setHairThinningLevel(null)
		}
	}, [analysis, mainType, forType]);

	useEffect(() => {
		if (mainType === 'closeup') {
			setBaselineHairGrowth(base_analysis?.images?.closeup?.hair_growth?.[forType] || null);
		} else if (mainType === 'global') {
			setBaselineHairGrowth(base_analysis?.images?.global?.hair_growth || null);
		} else {
			setBaselineHairGrowth(null);
		}
	}, [base_analysis, mainType, forType]);

	const getBackgroundColor = (thinning) => {
		if (['Severe Thinning', 'Advanced Thinning', 'Visible Thinning'].includes(thinning)) {
			return '#EF5656';
		}

		if (['Early Thinning', 'Moderate Thinning'].includes(thinning)) {
			return '#EF8679';
		}

		if (['Healthy'].includes(thinning)) {
			return '#1B9DBE';
		}
		return '#1B9DBE';
	}

	return (
		<div className='flex flex-col items-center h-full md:pr-0 xl:pr-12 justify-between sm:justify-start pb-1'>
			{(hairThinningLevel || hairGrowth) && (analysis?.images?.[mainType]?.raw?.[forType]) ? (
				<>
					<div className='relative flex justify-center'>
						{!hideGrowthIndex ? (
							<Link to={`/patient/${patient_id}/consult/${analysis_id}/closeup/${forType}/${graphType === 'hair_thinning' ? 'hair_growth' : 'hair_thinning'}`} className="absolute left-0" style={{transform: 'translate(-6px, 0)'}}>
								<button
									className="font-bold text-2xl focus:outline-none"
									onClick={function () {
										setShowTooltip(false)
									}}
								>
									<img src={lessThanIcon} className="h-8 w-8" />
								</button>
							</Link>
						) : ("")}


						<div className="text-center text-text-15  font-bold text-xxs xs:text-base xl:text-lg leading-4 xs:leading-5 tracking-wider opacity-80 w-2/3" style={{ fontFamily: 'Arial', letterSpacing: '1px', ...(window.innerWidth < 400 && {fontSize : '0.6rem'}) }}>
							{graphType === 'hair_thinning' ? translate('thinning_hair_condition') : translate('hair_growth_tracking')}
						</div>
					</div>

					<BarGraph hairThinningLevel={hairThinningLevel?.label} hairGrowthLevel={hairGrowth} graphType={graphType} showTooltip={showTooltip} toggleTooltipVisibility={toggleTooltipVisibility} />
				</>

			) : (
				""
			)}
		</div>
	)
}

const ShowScore = ({ hairGrowth, mainType }) => {
	const [show, setShow] = useState(false);

	return (
		<div onClick={() => { setShow(prev => !prev) }}>
			<p className="text-center font-bold text-black text-3xl leading-10 tracking-tighter" style={{ fontFamily: 'Arial', fontSize: '30px', lineHeight: '10px', letterSpacing: '-0.6px', opacity: '1' }}>
				{!show && calculateGroIndexScore(hairGrowth?.percentage, mainType)}
				{show && <span>{hairGrowth?.label_desc}</span>}
			</p>
		</div>
	)
}

const BarGraph = ({ graphType, hairThinningLevel, hairGrowthLevel, showTooltip, toggleTooltipVisibility }) => {

	// Index of hair thinning level item in hairThinningUiData object
	// Reason for finding index - We need the marker color data specific to this thinninglevel
	const thinningItemIndex = Object.values(hairThinningUiData).findIndex(level => level.markerKey === hairThinningLevel)

	// Growth item in hairGrowthUiData
	// Reason for finding item - We need to find marker position like (top: X%)
	const growthItem = hairGrowthUiData.markerPositions[Math.ceil(hairGrowthLevel?.gro_score)] || hairGrowthUiData.markerPositions.defaultFallback;

	const { tooltipHeading, tooltipDescription } = getTooltipText({groIndex: hairGrowthLevel?.gro_score, labelDesc: hairGrowthLevel?.label_desc})

	return (
		<div className='w-full grow mt-5 xl:mt-12 flex justify-center items-center'>
			<div className='flex flex-col justify-center items-center relative'>
				{graphType === 'hair_thinning' || (graphType !== 'hair_thinning' && graphType !== 'hair_growth') ? (
					Object.values(hairThinningUiData).map((item, index) => {
						return (
							<React.Fragment key={index}>
								{index > 0 ? (
									<div className='h-7 w-14 xs:h-11 xs:w-20 sm:h-8 sm:w-16 lg:h-9 lg:w-16 xl:h-11 xl:w-20 border-b' style={{ borderColor: "#90676426", backgroundColor: item.levelBgColor}} ></div>
								) : ("")}
								{thinningItemIndex === index ? (
									<>
										<div onClick={toggleTooltipVisibility} className={`${item.romanValue ? `w-24 xs:py-1 xs:w-28 sm:py-0 lg:py-1 sm:w-24 xl:w-28` : `px-4 xs:px-8 sm:px-5 lg:px-8 py-2 sm:py-1 lg:py-2 w-auto`} ${item.tooltipHeading ? `cursor-pointer` : ``} relative border-2 border-slate-200 rounded-md flex justify-center items-center z-10`} style={{backgroundColor: item.markerBgColor, boxShadow: "1px 2px 3px black, -1px 0px 3px black" }}>
											<span className={`text-text-9 ${item.romanValue ? `mr-1 text-base` : `font-semibold`}`} style={{ fontSize: '0.95rem' }}>{item.markerText}</span>
											<h1 className='text-text-9' style={{ fontSize: '1.80rem', lineHeight: '2rem' }}>{item.romanValue}</h1>
											{showTooltip && item.tooltipHeading ? (
												<div className="absolute top-10 flex flex-col items-center mb-6 group-hover:flex">
													<div className="w-6 h-6 bg-white" style={{ transform: "rotate(45deg)" }}></div>
													<div className="relative z-10 p-2 w-max bg-white text-center" style={{ transform: 'translate(0, -12px)' }}>
														<p className='text-xs xs:text-sm font-bold leading-none text-black mb-1 whitespace-nowrap'>{hairThinningLevel}</p>
														<p className='font-medium text-xs text-black whitespace-nowrap'>{item.tolltipDescription}</p>
													</div>

												</div>
											) : ("")}

										</div>
									</>

								) : ("")}
							</React.Fragment>
						)
					})
				) : (
					Object.values(hairGrowthUiData.growthLevelData).map((item, index) => {
						return (
							<React.Fragment key={index}>
								<div className='h-7 w-14 xs:h-11 xs:w-20 sm:h-8 sm:w-16 lg:h-9 lg:w-16 xl:h-11 xl:w-20 border-b' style={{ borderColor: "#90676426", backgroundColor: item.levelBgColor }}></div>
								<div className={`h-9 xs:h-10 lg:h-11 border-2 ${hairGrowthLevel ? 'w-24 sm:w-28 lg:w-32' : 'w-auto px-1 lg:px-3'} border-white rounded-lg flex justify-center items-center absolute z-50 ${tooltipHeading ? 'cursor-pointer' : ''}`} onClick={toggleTooltipVisibility} style={{backgroundColor: growthItem?.markerBgColor, boxShadow: "0px 2px 3px black, 0px -1px 1px #bfc5ca", top: growthItem?.position }}>
									{hairGrowthLevel?.label_desc === '0%' ? (
										<div className='h-1 w-8 bg-white'></div>
									) : (
										<span className={`text-text-9 text-bold ${hairGrowthLevel?.gro_score ? 'text-lg xs:text-2xl lg:text-3xl' : 'text-lg lg:text-xl'}`}>{hairGrowthLevel?.gro_score || growthItem.text}</span>
									)}
									{showTooltip && tooltipHeading ? (
										<div className="absolute top-11 flex flex-col items-center mb-6 group-hover:flex">
											<div className="w-6 h-6 bg-white" style={{ transform: "rotate(45deg)" }}></div>
											<div className="relative z-15 p-2 px-3 sm:px-5 w-max bg-white text-center" style={{ transform: 'translate(0, -12px)' }}>
												<p className='text-xs xs:text-sm font-bold leading-md text-black whitespace-nowrap'>{tooltipHeading}</p>
												<p className='font-normal text-xs text-black whitespace-nowrap'>{tooltipDescription && `(${tooltipDescription})`}</p>
											</div>

										</div>
									) : ("")}
								</div>
							</React.Fragment>
						)
					})
				)}

			</div>
		</div>
	)
}

function getTooltipText({groIndex, labelDesc}) {
	if (groIndex === 0) {
		return {
			tooltipHeading: "No further thinning",
			tooltipDescription: ""
		}
	} else if (groIndex >= 0.5 && groIndex < 2) {
		return {
			tooltipHeading: "Moderate improvement",
			tooltipDescription: labelDesc
		}
	} else if (groIndex >= 2 && groIndex <= 2.5) {
		return {
			tooltipHeading: "Good improvement",
			tooltipDescription: labelDesc
		}
	} else if (groIndex >= 3 && groIndex <= 3.5) {
		return {
			tooltipHeading: "Excellent improvement",
			tooltipDescription: labelDesc
		}
	} else if (groIndex >= 4) {
		return {
			tooltipHeading: "Exceptional improvement",
			tooltipDescription: labelDesc
		}
	} else {
		return {
			tooltipHeading: null,
			tooltipDescription: null
		}
	}

}

export default GroIndexGraph