import React from 'react'
import AdminHeader from '../AdminHeader';
import { useTranslation } from '../../contexts/TranslationContext';

const PasswordSetup = ({setupPassword, message, error, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword }) => {
  const { translate } = useTranslation();
  return (
    <div className="flex flex-col" style={{flexGrow: "1"}}>
    <AdminHeader hideChatGPT userName={localStorage.getItem("name")} hideHomeLink/>
     <form
       className="flex flex-col items-center m-auto"
       onSubmit={event => {
         event.preventDefault();
         setupPassword();
       }}
     >
     <h1 className='mx-auto text-3xl font-bold text-text-2 mb-16 text-center'>
       {translate('setup_grotrack_login')}
     </h1>
     <div className='flex flex-col items-center mb-10'>
     {message && <p className="text-center text-red-600">{message}</p>}
     {error.password && <p className="text-center text-red-600">{error.password}</p>}
       {error.confirmPassword && <p className="text-center text-red-600 mt-4">{error.confirmPassword}</p>}
       <input
         type="email"
         className="mt-8 px-7 py-2 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
         placeholder={translate('email')}
         value={email}
         required
         onChange={event => {
           setEmail(event.target.value);
         }}
       />
       <input
         type="password"
         className="mt-8 px-7 py-2 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
         placeholder={translate('password')}
         value={password}
         minLength="8"
         required
         onChange={event => {
           setPassword(event.target.value);
         }}
       />

       <input
         type="password"
         className="mt-8 mb-28 px-7 py-2 bg-background-2 text-text-3 text-lg text-center rounded-lg focus:outline-none placeholder-text-2 placeholder-opacity-100 placeholder-font-lg"
         placeholder={translate('confirm_password')}
         value={confirmPassword}
         minLength="8"
         required
         onChange={event => {
           setConfirmPassword(event.target.value);
         }}
       />
       <button
       className="bg-button-5 tracking-widest font-bold text-white focus:outline-none px-12 py-2 rounded-md"
       type="submit"
       >
        {translate('confirm')}
       </button>
       </div>
     </form>

   </div>
  )
}

export default PasswordSetup