import { React, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import Logo from "../assets/images/GROTrackTurquoise.png";
import DotGrey from "../assets/images/DotGrey.svg";
import DotTurquoise from "../assets/images/DotTurquoise.svg";
import AdminSidebar from "../components/AdminSidebar";
import Loading from "../views/Loading";
import CardForm from "../components/AdminPaymentForms/CardForm";
import BankForm from "../components/AdminPaymentForms/BankForm";
import { useUserContext } from "../contexts/userContext";
import { getDot, getLogo } from "../utlis/themeHelpers";
import AdminHeader from "../components/AdminHeader";
import { useTranslation } from "../contexts/TranslationContext";

const getFirstName = (name) => {
  if (name.lastIndexOf(" ") === -1) {
    return name;
  }
  return name.substring(0, name.lastIndexOf(" ")).trim();
};

const getLastName = (name) => {
  if (name.lastIndexOf(" ") === -1) {
    return "";
  }
  return name.substring(name.lastIndexOf(" ")).trim();
};

export default function AdminPayment() {
  const stripe = useStripe();
  const elements = useElements();
  const { isSalonApp } = useUserContext();
  const { translate } = useTranslation()

  const [plans, setPlans] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [canPay, setCanPay] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [card, setCard] = useState(null);
  const [editCard, setEditCard] = useState(null);
  const [editCardMode, setEditCardMode] = useState(false);
  const [bank, setBank] = useState(null);
  const [editBank, setEditBank] = useState(null);
  const [editBankMode, setEditBankMode] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [ready, setReady] = useState(false);
  const [showCardForm, setShowCardForm] = useState(true);

  const getPlans = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/get_plans`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setPlans(response.plans);
        } else {
          setMessage(response.message);
        }
      });
  };

  const getSubscriptionStatus = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/subscription_status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setSubscriptionStatus({
            status: response.status,
            plan: response.plan,
            subscriptionEndsAt: response.subscription_ends_at,
            trialStatus: response.trial_status,
            trialEndsAt: response.trial_ends_at,
          });
        } else {
          setMessage(response.message);
        }
      });
  };

  const createSubscription = (id) => {
    setReady(false);
    setSuccess(false);
    fetch(`${process.env.REACT_APP_API}/clinic/create_subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        plan_name: id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (response.url) {
            setSuccess(response.message);
            const win = window.open(response.url, "_blank");
            if (win) {
              win.focus();
            }
            setReady(true);
          } else {
            getSubscriptionStatus().then(() => {
              setSuccess(response.message);
              setReady(true);
            });
          }
        } else {
          setMessage(response.message);
          setReady(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  };

  const getPaymentMethods = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/get_payment_methods`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (Object.keys(response.payment_method).length > 0) {
            setCanPay(true);
            const payment_method = response.payment_method;
            setCard({
              cardNumber: `···· ···· ···· ${payment_method.card.last4}`,
              expires: `${payment_method.card.exp_month}/${payment_method.card.exp_year}`,
              cvv: "···",
              cardHolderFirstName: getFirstName(
                payment_method.billing_details.name
              ),
              cardHolderLastName: getLastName(
                payment_method.billing_details.name
              ),
              billingAddress: {
                street: payment_method.billing_details.address.line1,
                city: payment_method.billing_details.address.city,
                state: payment_method.billing_details.address.state,
                zip: payment_method.billing_details.address.postal_code,
              },
              phone: payment_method.billing_details.phone,
              email: payment_method.billing_details.email,
            });
            setEditCard({
              cardHolderFirstName: getFirstName(
                payment_method.billing_details.name
              ),
              cardHolderLastName: getLastName(
                payment_method.billing_details.name
              ),
              billingAddress: {
                street: payment_method.billing_details.address.line1,
                city: payment_method.billing_details.address.city,
                state: payment_method.billing_details.address.state,
                zip: payment_method.billing_details.address.postal_code,
              },
              phone: payment_method.billing_details.phone,
              email: payment_method.billing_details.email,
            });
            setBank({
              cardNumber: ``,
              expires: ``,
              cvv: "",
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
            setEditBank({
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
          } else {
            setCanPay(false);
            setCard({
              cardNumber: "",
              expires: "",
              cvv: "",
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
            setEditCard({
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
            setBank({
              cardNumber: "",
              expires: "",
              cvv: "",
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
            setEditBank({
              cardHolderFirstName: "",
              cardHolderLastName: "",
              billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
              },
              phone: "",
              email: "",
            });
          }
        } else {
          setMessage(response.message);
        }
      });
  };

  const initAddPaymentMethod = async () => {
    await fetch(`${process.env.REACT_APP_API}/clinic/init_add_payment_method`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          setClientSecret(response.intent.client_secret);
        } else {
          setMessage(response.message);
        }
      });
  };

  const isCardValid = () => {
    return true;
  };

  const saveCard = () => {
    setSuccess("");
    if (isCardValid() && stripe && elements) {
      setReady(false);
      const cardElement = elements.getElement(CardElement);
      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              address: {
                city: editCard.billingAddress.city,
                line1: editCard.billingAddress.street,
                postal_code: editCard.billingAddress.zip,
                state: editCard.billingAddress.state,
              },
              email: editCard.email,
              name:
                editCard.cardHolderFirstName +
                " " +
                editCard.cardHolderLastName,
              phone: editCard.phone,
            },
          },
        })
        .then(function (result) {
          if (result.error) {
            setMessage(result.error.message);
            setReady(true);
          } else {
            fetch(`${process.env.REACT_APP_API}/clinic/add_payment_method`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
              body: JSON.stringify({
                payment_method: result.setupIntent.payment_method,
              }),
            })
              .then((response) => response.json())
              .then((response) => {
                if (response.success) {
                  Promise.all([
                    getPaymentMethods(),
                    initAddPaymentMethod(),
                  ]).then(() => {
                    setSuccess(response.message);
                    setEditCardMode(false);
                    setReady(true);
                  });
                } else {
                  setMessage(response.message);
                  setReady(true);
                }
              })
              .catch((error) => {
                console.error(error);
                setMessage(translate('error_occurred'));
                setReady(true);
              });
          }
        })
        .catch((error) => {
          console.error(error);
          setMessage(translate('error_occurred'));
          setReady(true);
        });
    }
  };

  useEffect(() => {
    Promise.all([
      getPlans(),
      getSubscriptionStatus(),
      getPaymentMethods(),
      initAddPaymentMethod(),
    ])
      .then(() => {
        setReady(true);
      })
      .catch((error) => {
        console.error(error);
        setMessage(translate('error_occurred'));
        setReady(true);
      });
  }, []);

  return (
    <>
      <main
        className={`mx-auto container flex flex-col p-5 pb-12 font-font-2 tracking-wider ${
          ready ? "" : "hidden"
        }`}
      >
        <AdminHeader physician={{}} />

        {message && <p className="mt-8 text-center text-red-600">{message}</p>}
        {success && (
          <p className="mt-8 text-center text-green-600">{success}</p>
        )}
        <section className="flex pl-5 pr-10">
          <AdminSidebar />
          <div className="mx-auto mt-10">
            {card && (
              <>
                <div className="flex justify-center items-center">
                  <div className="flex mr-8 items-center">
                    <img
                      src={showCardForm ? getDot(isSalonApp) : DotGrey}
                      onClick={() => {
                        setShowCardForm(true);
                      }}
                      className="h-3 w-3 mr-4 cursor-pointer"
                    />
                    <span className="text-lg text-text-2 uppercase tracking-widest">
                      {translate('card')}
                    </span>
                  </div>
                  <div className="flex ml-8 items-center">
                    <img
                      src={!showCardForm ? getDot(isSalonApp) : DotGrey}
                      onClick={() => {
                        setShowCardForm(false);
                      }}
                      className="h-3 w-3 mr-4 cursor-pointer"
                    />

                    <span className="text-lg text-text-2 uppercase tracking-widest">
                      {translate('bank_account')}
                    </span>
                  </div>
                </div>
                {showCardForm ? (
                  <CardForm
                    saveCard={saveCard}
                    editCardMode={editCardMode}
                    card={card}
                    editCard={editCard}
                    setEditCard={setEditCard}
                    setEditCardMode={setEditCardMode}
                  />
                ) : (
                  <BankForm
                    saveBank={() => {}}
                    editBankMode={editBankMode}
                    bank={bank}
                    editBank={editBank}
                    setEditBank={setEditBank}
                    setEditBankMode={setEditBankMode}
                  />
                )}
              </>
            )}

            {subscriptionStatus && (
              <div className="mt-16 flex flex-col items-center">
                <p className="text-text-2">{translate('subscription_status')}</p>
                {subscriptionStatus.status ? (
                  <p className="mt-5">
                    <strong>{translate('current_plan')} : </strong>
                    {subscriptionStatus.plan}
                    {subscriptionStatus.subscriptionEndsAt &&
                      `, ends on ${dayjs().format("MMMM D, YYYY")}`}
                  </p>
                ) : (
                  <p className="mt-5">
                    {subscriptionStatus.trialStatus ? (
                      <>
                        <strong>{translate('trial_period_ends_on')} : </strong>
                        {dayjs(subscriptionStatus.trialEndsAt).format(
                          "MMMM D, YYYY"
                        )}
                      </>
                    ) : (
                      <strong>{translate('trial_period_ended')}</strong>
                    )}
                  </p>
                )}
              </div>
            )}
            {plans.length > 0 && (
              <div className="mt-16 flex flex-col items-center">
                <p className="text-text-2">{translate('available_plans')}</p>
                <ul className="mt-5 grid gap-2">
                  {plans.map((plan) => {
                    return (
                      <li key={plan.id} className="flex items-center">
                        <p>
                          {plan.product_details.name.charAt(0).toUpperCase() +
                            plan.product_details.name.slice(1)}
                        </p>
                        <button
                          disabled={!canPay}
                          className="ml-4 px-8 py-1 bg-background-23 rounded-lg text-text-3 font-bold
            cursor-pointer focus:outline-none"
                          onClick={() => {
                            createSubscription(plan.id);
                          }}
                        >
                          {plan.currency.toUpperCase() + " " + plan.amount}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <form
              className="mt-16 flex flex-col"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <div className="flex items-center">
                <p className="mx-auto text-text-2">{translate('user_name_password')}</p>
                <button
                  className="px-8 py-1 bg-background-23 rounded-lg text-text-3 font-bold
            cursor-pointer focus:outline-none"
                >
                  {translate('reset')}
                </button>
              </div>
              <div className="mx-auto mt-6 flex flex-col items-end bg-background-5 px-4 lg:px-16 py-8">
                <label className="flex items-center">
                  <p className="ml-2 mr-3 font-normal">{translate('user_name')}</p>
                  <input
                    type="text"
                    className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                    value={username}
                    required
                    onChange={(event) => {
                      setUsername(event.target.value);
                    }}
                  />
                </label>
                <label className="mt-5 flex items-center">
                  <p className="ml-2 mr-3 font-normal">{translate('password')}</p>
                  <input
                    type="text"
                    className="ml-6 w-40 md:w-96 px-3 py-1 bg-background-22 text-text-2 font-bold rounded-xs focus:outline-none"
                    value={password}
                    required
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </label>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Loading message={translate('loading_payment_details')} ready={ready} />
    </>
  );
}
