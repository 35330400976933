import React from 'react'
import { useUserContext } from '../contexts/userContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from '../contexts/TranslationContext';

export const AdminLogout = () => {

    const { handleAdminLogin, isAdmin } = useUserContext();
    const { translate } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const isAdminroute = location.pathname.includes('admin') && !location.pathname.includes('create-password');

    const handleAdminLogout = () => {
        handleAdminLogin(false);
        history.push('/');
    }

    return (isAdminroute && isAdmin) ? (
            <div onClick={handleAdminLogout} className='z-50 fixed bottom-5 right-2 cursor-pointer bg-background-1 mr-6 text-gray-500 font-semibold py-1 px-5 border border-gray-500 rounded'>
                {translate('admin_logout')}
            </div>
    ) : (
        <>
        </>
    )
}