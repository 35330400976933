import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/GROTrackTurquoise.png';
import DotGrey from "../assets/images/DotGrey.svg";
import DotTurquoise from "../assets/images/DotTurquoise.svg";
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loading from './Loading';
import { getLogo } from '../utlis/themeHelpers';
import { useUserContext } from '../contexts/userContext';
import { useTranslation } from "../contexts/TranslationContext";


export default function StaffLogin() {

    const { isSalonApp, handleSetStaffId } = useUserContext();
    const history = useHistory()

    const [message, setMessage] = useState("");
    const [ready, setReady] = useState(false);
    const [staffList, setStaffList] = useState([]);
    const { translate } = useTranslation();


    // Fetch functions -------------------------------------------------------------

    const getStaffMembers = () => {
        setStaffList([])
        fetch(`${process.env.REACT_APP_API}/clinic/staffs`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    // if (!response.data?.length) {
                        // return history.replace("/dashboard")
                    // }
                    setStaffList(response.data?.reverse())
                } else {
                    setMessage(response.message);
                }
            })
            .catch((error) => {
                console.error(error);
                setMessage(translate('error_occurred'));
            });
    }

    // Use-effect -----------------------------------------------------------------------

    useEffect(() => {
        setReady(false)
        Promise.all([
            getStaffMembers()
        ]).then(() => {
        }).finally(() => {
            setTimeout(() => {
                setReady(true);
            }, 1000)
        });
    }, []);

    // Helper functions ----------------------------------------------------------------

    const handleClick = (id) => {
        handleSetStaffId(id);
        setTimeout(() => {
            history.push("/dashboard")
        }, 200)
    }

    return (
        ready ? (
            <main className="flex flex-col p-4 font-normal font-font-2 min-h-screen justify-between">
                <section className="w-full flex flex-col h-full pt-16 justify-center items-center">
                        <img className="h-20 mx-auto mb-20" src={getLogo(isSalonApp)} alt="GRO Track" />
                    <div className='flex flex-col items-center mb-6 grow'>
                        {staffList.map((item, index) => {
                            return <StaffLink key={index} item={item} handleClick={handleClick}/>
                        })}
                    </div>
                </section>
                <footer>
                    <div className="pb-8 text-center">
                        <button
                            className="bg-transparent text-gray-500 font-semibold py-2 px-8 border border-gray-500 rounded-lg"
                            onClick={() => {
                                localStorage.removeItem('access_token');
                                localStorage.removeItem('expires_at');
                                localStorage.removeItem('staffId');
                                window.location.href = '/';
                            }}
                        >
                            {translate('log_out')}
                        </button>
                    </div>
                    <div className="py-4 border-t-2 border-accent-2 text-center">
                        {translate('gro_technologies')}
                    </div>
                </footer>
            </main>
        ) : (
            <Loading message={translate('loading_details')} ready={ready} />
        )

    );
}

const StaffLink = ({ item, handleClick }) => {
    const [isSeleced, setIsSelected] = useState(false)

    return (
        <a onClick={() => handleClick(item?.id)} className='mb-6 cursor-pointer flex items-center py-0 px-14 border border-transparent w-full'>
            <img src={isSeleced ? DotTurquoise : DotGrey} className='h-4 w-4 cursor-pointer mr-8' />
            <span className="font-bold text-gray-400 text-lg leading-tight tracking-widest" >{item.name}</span>
        </a>
    )

}   
