// import React, { useState, useEffect, useRef } from "react";
import { useEffect, useRef } from "react";
import "./MicrophonePulsator.css";
// const MicrophonePulsator = () => {
//   const ref = useRef(null);
//   const streamRef = useRef(null);
//   const audioContext = useRef(null);
//   const analyser = useRef(null);
//   const microphone = useRef(null);
//   const unmount = useRef(false);
//   const prevArray = useRef([]);
//   useEffect(() => {
//     const getMedia = async () => {
//       try {
//         const stream = await navigator.mediaDevices.getUserMedia({
//           audio: true,
//         });
//         streamRef.current = stream;

//         return stream;
//       } catch (error) {
//         console.error(error);
//         return null;
//       }
//     };

//     let timeout = null;

//     function renderFrame() {
//       console.log("renderFrame");
//       if (unmount.current || !ref.current) return;
//       //   requestAnimationFrame(renderFrame);
//       clearTimeout(timeout);
//       timeout = setTimeout(() => {
//         requestAnimationFrame(renderFrame);
//       }, 1000 / 60);
//       const bufferLength = analyser.current.frequencyBinCount;
//       const dataArray = new Uint8Array(bufferLength);
//       if (prevArray.current.length === 0) {
//         prevArray.current = dataArray;
//         return;
//       }
//       const canvas = ref.current;
//       const canvasCtx = canvas.getContext("2d");
//       const WIDTH = canvas.width;
//       const HEIGHT = canvas.height;
//       const barWidth = (WIDTH / bufferLength) * 2.5;
//       let barHeight;
//       let x = 0;
//       analyser.current.getByteFrequencyData(dataArray);
//       //   canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
//       // this pulsating effect is not smooth, can fix
//         canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
//         for (let i = 0; i < bufferLength; i++) {
//             barHeight = dataArray[i];
//             const prevBarHeight = prevArray.current[i];
//             const diff = (barHeight - prevBarHeight) / prevBarHeight;
//             if (barHeight < prevBarHeight) {
//                 barHeight = prevBarHeight - 1;
//             }
//             canvasCtx.fillStyle = `rgba(34, 156, 186, ${diff})`;
//             canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);
//             x += barWidth + 1;

//         }
//         prevArray.current = dataArray;
//     }

//     getMedia().then((stream) => {
//       audioContext.current = new AudioContext();

//       analyser.current = audioContext.current.createAnalyser();

//       microphone.current = audioContext.current.createMediaStreamSource(stream);

//       // Connect the microphone to the analyser
//       microphone.current.connect(analyser.current);
//       analyser.current.fftSize = 64;

//       requestAnimationFrame(renderFrame);
//     });

//     return () => {
//       // stop the stream
//       unmount.current = true;
//       if (streamRef.current) {
//         streamRef.current.getTracks().forEach((track) => track.stop());
//       }
//       // cancel the animation frame
//       cancelAnimationFrame(renderFrame);
//       // close the audio context
//       if (microphone.current) {
//         microphone.current.disconnect();
//       }

//       // close the audio context
//       if (analyser.current) {
//         analyser.current.disconnect();
//       }
//       if (audioContext.current) {
//         audioContext.current.close();
//       }
//     };

//     // Create an oscillator node
//   }, []);

//   return <canvas ref={ref} width={"30px"} height={"20px"} />;
// };

// export default MicrophonePulsator;

const MicrophonePulsator = () => {
  const ref = useRef(null);
  const streamRef = useRef(null);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const microphone = useRef(null);
  const unmount = useRef(false);
  const prevArray = useRef([]);
  useEffect(() => {
    const getMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        streamRef.current = stream;

        return stream;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    let timeout = null;

    function renderFrame() {
      console.log("renderFrame");
      if (unmount.current || !ref.current) return;
        requestAnimationFrame(renderFrame);
    
      const bufferLength = analyser.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.current.getByteFrequencyData(dataArray);
      let avg = 0;
 
      for (let i = 0; i < bufferLength; i++) {
        avg += dataArray[i];
        // barHeight = dataArray[i];
        // const prevBarHeight = prevArray.current[i];
        // const diff = (barHeight - prevBarHeight) / prevBarHeight;
        // if (barHeight < prevBarHeight) {
        //   barHeight = prevBarHeight - 1;
        // }
        // canvasCtx.fillStyle = `rgba(34, 156, 186, ${diff})`;
        // canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);
        // x += barWidth + 1;
      }
      
      // console.log("avg", avg);
      avg = avg / bufferLength;
      console.log("avg", avg);
      ref.current.style.transform = `scale(${1+ avg / 100})`;
      prevArray.current = dataArray;
    }

    getMedia().then((stream) => {
      audioContext.current = new AudioContext();

      analyser.current = audioContext.current.createAnalyser();

      microphone.current = audioContext.current.createMediaStreamSource(stream);

      // Connect the microphone to the analyser
      microphone.current.connect(analyser.current);
      analyser.current.fftSize = 64;

      requestAnimationFrame(renderFrame);
    });

    return () => {
      // stop the stream
      unmount.current = true;
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      // cancel the animation frame
      cancelAnimationFrame(renderFrame);
      // close the audio context
      if (microphone.current) {
        microphone.current.disconnect();
      }

      // close the audio context
      if (analyser.current) {
        analyser.current.disconnect();
      }
      if (audioContext.current) {
        audioContext.current.close();
      }
    };

    // Create an oscillator node
  }, []);
  return (
      <div className="microphoneContainer">
        <div  id="circlein" >
          <div className="animation-pulse" ref={ref}></div>
          <svg
            class="mic-icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1000 1000"
            enable-background="new 0 0 1000 1000"
            xmlSpace="preserve"
            style={{
              fill: "var(--accent-8)",
            }}
          >
            <g>
              <path d="M500,683.8c84.6,0,153.1-68.6,153.1-153.1V163.1C653.1,78.6,584.6,10,500,10c-84.6,0-153.1,68.6-153.1,153.1v367.5C346.9,615.2,415.4,683.8,500,683.8z M714.4,438.8v91.9C714.4,649,618.4,745,500,745c-118.4,0-214.4-96-214.4-214.4v-91.9h-61.3v91.9c0,141.9,107.2,258.7,245,273.9v124.2H346.9V990h306.3v-61.3H530.6V804.5c137.8-15.2,245-132.1,245-273.9v-91.9H714.4z" />
            </g>
          </svg>
        </div>
      </div>
  );
};

export default MicrophonePulsator;
