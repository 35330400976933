import { React } from "react";
import { NavLink } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import { FaDotCircle } from "react-icons/fa";
import { useTranslation } from "../contexts/TranslationContext";

export default function GloPresentationNavbar(props) {
  const { translate } = useTranslation();
  return (
    <nav
      className="mt-8 grid grid-cols-3 gap-12 font-normal font-font-2 text-center text-text-10 text-lg"
      style={{ height: "62px" }}
    >
       <button
        onClick={() => {
          props.setSpotsColor("red_spots");
          props.setActive("close-up");
          props.setCloseupIndex(0);
          props.setGlobalIndex(0);
        }}
        disabled={
          props.faceAnalysis &&
          props.faceAnalysis[props.currentId] &&
          props.faceAnalysis[props.currentId]["red_spots"] &&
          Object.keys(
            props.faceAnalysis?.[props.currentId]["red_spots"]["closeup"]
          ).length !== 0
            ? false
            : true
        }
        className={
          props.faceAnalysis &&
          props.faceAnalysis[props.currentId] &&
          props.faceAnalysis[props.currentId]["red_spots"] &&
          Object.keys(
            props.faceAnalysis?.[props.currentId]["red_spots"]["closeup"]
          ).length !== 0
            ? "relative flex items-center bg-background-5 p-2 border-b-2 border-background-6"
            : "relative flex items-center bg-background-5 p-2 border-b-2 border-background-6 cursor-not-allowed"
        }
        //className="relative flex items-center bg-background-5 p-2 border-b-2 border-background-6"
      >
        <GoPrimitiveDot
          style={{ fontSize: "40px" }}
          className={
            props.faceAnalysis &&
            props.faceAnalysis[props.currentId] &&
            props.faceAnalysis[props.currentId]["red_spots"] &&
            Object.keys(
              props.faceAnalysis?.[props.currentId]["red_spots"]["closeup"]
            ).length !== 0
              ? "absolute text-accent-5"
              : "absolute white"
          }
        />
        <p
          className={`mx-auto ${props.tab === 1 && "text-text-9 font-bold"} ${
            props.spotsColor === "red_spots" && props.active === "close-up"
              ? "text-white"
              : ""
          }`}
        >
          {translate('red_spots')}
        </p>
      </button>
      <button
        onClick={() => {
          props.setSpotsColor("brown_spots");
          props.setActive("close-up");
          props.setCloseupIndex(0);
          props.setGlobalIndex(0);
        }}
        disabled={
          props.faceAnalysis &&
          props.faceAnalysis[props.currentId] &&
          props.faceAnalysis[props.currentId]["brown_spots"] &&
          Object.keys(
            props.faceAnalysis?.[props.currentId]["brown_spots"]["closeup"]
          ).length !== 0
            ? false
            : true
        }
        className={
          props.faceAnalysis &&
          props.faceAnalysis[props.currentId] &&
          props.faceAnalysis[props.currentId]["brown_spots"] &&
          Object.keys(
            props.faceAnalysis?.[props.currentId]["brown_spots"]["closeup"]
          ).length !== 0
            ? "relative flex items-center bg-background-5 p-2 border-b-2 border-background-6"
            : "relative flex items-center bg-background-5 p-2 border-b-2 border-background-6 cursor-not-allowed"
        }
        //className="relative flex items-center bg-background-5 p-2 border-b-2 border-background-6"
      >
        <GoPrimitiveDot
          style={{ fontSize: "40px" }}
          className={
            props.faceAnalysis &&
            props.faceAnalysis[props.currentId] &&
            props.faceAnalysis[props.currentId]["brown_spots"] &&
            Object.keys(
              props.faceAnalysis?.[props.currentId]["brown_spots"]["closeup"]
            ).length !== 0
              ? "absolute text-accent-5"
              : "absolute white"
          }
        />
        <p
          className={`mx-auto ${props.tab === 4 && "text-text-9 font-bold"} ${
            props.spotsColor === "brown_spots" && props.active === "close-up"
              ? "text-white"
              : ""
          }`}
        >
          {translate('brown_spots')}
        </p>
      </button>
      <button
        onClick={() => {
          props.setSpotsColor("pores");
          props.setActive("close-up");
          props.setCloseupIndex(0);
          props.setGlobalIndex(0);
        }}
        disabled={
          props.faceAnalysis &&
          props.faceAnalysis[props.currentId] &&
          props.faceAnalysis[props.currentId]["pores"] &&
          Object.keys(props.faceAnalysis?.[props.currentId]["pores"]["closeup"])
            .length !== 0
            ? false
            : true
        }
        className={
          props.faceAnalysis &&
          props.faceAnalysis[props.currentId] &&
          props.faceAnalysis[props.currentId]["pores"] &&
          Object.keys(props.faceAnalysis?.[props.currentId]["pores"]["closeup"])
            .length !== 0
            ? "relative flex items-center bg-background-5 p-2 border-b-2 border-background-6"
            : "relative flex items-center bg-background-5 p-2 border-b-2 border-background-6 cursor-not-allowed"
        }
        //className="relative flex items-center bg-background-5 py-2 px-5 border-b-2 border-background-6"
      >
        <GoPrimitiveDot
          style={{ fontSize: "40px" }}
          className={
            props.faceAnalysis &&
            props.faceAnalysis[props.currentId] &&
            props.faceAnalysis[props.currentId]["pores"] &&
            Object.keys(
              props.faceAnalysis?.[props.currentId]["pores"]["closeup"]
            ).length !== 0
              ? "absolute text-accent-5"
              : "absolute white"
          }
        />
        <p
          className={`mx-auto ${props.tab === 3 && "text-text-9 font-bold"} ${
            props.spotsColor === "pores" && props.active === "close-up"
              ? "text-white"
              : ""
          }`}
        >
          {translate('pores')}
        </p>
      </button>
    </nav>
  );
}
